<template>
  <iSubHeading> Shop </iSubHeading>
</template>

<script>

export default {
  name: "PublicationShop",
};
</script>

<style scoped></style>
