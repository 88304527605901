<template>
  <PageWrapper
    :active-section="SECTION_SETTINGS"
    :active-page="PAGE_COLLABORATOR_INVITATIONS_SETTINGS"
    title="Manage Collaborations"
    :breadcrumbs="settingsBreadcrumbs"
    page-name="Manage Collaborations"
    align="left"
    width="full"
  >
    <iColumn gap="extraLarge">
      <iRow gap="standard">
        <iSubHeading variant="subtle">
          Your applications
        </iSubHeading>
      </iRow>
      <iRow>
        <SmartFeed
          :pinia-store="useOrgApplicationFeedStore"
          default-layout="small-card"
        />
      </iRow>
    </iColumn>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import SmartFeed from "SmartFeed/components/SmartFeed";
import {
  PAGE_COLLABORATOR_INVITATIONS_SETTINGS,
  SECTION_SETTINGS,
} from "@/constants/nav-constants";
import { LAYOUT_CARD_SMALL } from "@/constants/smart-feed-constants";
import { useGeneralStore } from "%/stores/general-store";
import { useOrgApplicationFeedStore } from "SmartFeed/stores/feed/org-application-feed-store";
import { mapState } from "pinia";

export default {
  name: "CollaboratorInvitations",
  components: {
    SmartFeed,
    PageWrapper,
  },
  data() {
    return {
      SECTION_SETTINGS,
      PAGE_COLLABORATOR_INVITATIONS_SETTINGS,
      LAYOUT_CARD_SMALL,
    };
  },
  computed: {
    ...mapState(useGeneralStore, ["settingsBreadcrumbs"]),
  },
  methods: {
    useOrgApplicationFeedStore,
  },
};
</script>
