import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import { useAffiliateLinkStore } from "@/stores/affiliate-link-store";

export const useAffiliateLinkCollectionFeedStore = defineSmartFeedPiniaStore("affiliate-link-collection-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    feed: [],
    metadata: {
      hasMore: true,
      lastId: 0,
      affiliateLinkCollectionId: useAffiliateLinkStore().affiliateLinkCollectionId,
    },
    tableFields: [
      {
        key: "affiliateLink",
        label: "Affiliate Link",
        width: "320px",
      },
      {
        key: "originalLink",
        label: "Original Link",
        width: "320px",
      },
      {
        key: "advertiserName",
        label: "Advertiser",
        width: "200px",
      },
      {
        key: "myDescription",
        label: "My Description",
      },
      {
        key: "brandName",
        label: "Brand Name",
      },
      {
        key: "productName",
        label: "Product Name",
      },
      {
        key: "clicks",
        label: "Clicks",
      },
      {
        key: "sales",
        label: "Sales",
      },
      {
        key: "totalEarnings",
        label: "Total Earnings",
      },
      {
        key: "pendingEarnings",
        label: "Pending Earnings",
      },
      {
        key: "lockedEarnings",
        label: "Locked Earnings",

      },
      {
        key: "actions",
        label: "",
      },
    ],
    isLoading: false,
  }),
  getters: {
    hasMore() {
      return this.metadata.hasMore;
    },
  },
  actions: {
    getSmartFeed() {
      this.apiGet(
        `/affiliate-link-collection/${this.metadata.affiliateLinkCollectionId}/smartfeed`,
        {
          last_id: this.metadata.lastId,
        },
        data => this.handleSmartFeedResponse(data),
        () => {
          // prevents infinite error loop
          this.metadata.hasMore = false;
        },
      );
    },
  },
});
