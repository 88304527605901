import { $axios } from "@bloglovin/vue-framework";
import { defineStore } from "pinia";
import { useGeneralStore } from "%/stores/general-store";
import { links } from "%/enums/link-enums";

export const usePartnershipStore = defineStore("partnership-store", {
  state: () => ({
    inspirationNetworkPartnerships: [],
    directNetworkPartnerships: [],
    isLoading: false,
    loadingInspirationNetworkPartnerships: false,
    lastInspirationNetworkPartnershipId: 0,
    lastDirectNetworkPartnershipId: 0,
    advertiserDetails: {},
    advertiserDomains: [],
    loadingAdvertiserDetails: false,
    searchInspirationPartnerships: [],
    loadingSearchInspirationNetworkPartnerships: false,
  }),
  getters: {
    hasMoreInspirationNetworkPartnerships() {
      return this.lastInspirationNetworkPartnershipId !== -1;
    },
    hasMoreDirectNetworkPartnerships() {
      return this.lastDirectNetworkPartnershipId !== -1;
    },
    affiliateApiV1Url() {
      return useGeneralStore().getLink(links.affiliateApiV1);
    },
    orgId() {
      return useGeneralStore().orgId;
    },
  },
  actions: {
    fetchAdvertiserDetails(orgId) {
      this.loadingAdvertiserDetails = true;

      return $axios.get(`${this.affiliateApiV1Url}/advertiser/${orgId}`, {
        params: { last_partnership_id: this.lastInspirationNetworkPartnershipId },
      }).then(response => {
        this.advertiserDetails = response.data.advertiser;
      }).catch(() => {
        this.errorFetchAdvertiserDetails = "There was a problem fetching the advertiser details";
      }).finally(() => {
        return this.loadingAdvertiserDetails = false;
      });
    },
    searchInspirationNetworkPartnerships(searchString) {
      this.loadingSearchInspirationNetworkPartnerships = true;
      this.apiGet(
        `${this.affiliateApiV1Url}/partnership/inspiration`,
        {
          search_query: searchString,
          publisher_org_id: this.orgId,
          limit: 10,
        },
        data => {
          this.searchInspirationPartnerships = data.partnerships;
        },
        () => {
          this.searchInspirationPartnerships = [];
        },
        () => {
          this.loadingSearchInspirationNetworkPartnerships = false;
        },
      );
    },
    fetchDirectNetworkPartnerships() {
      if (this.lastDirectNetworkPartnershipId === -1 || this.isLoading) {
        return;
      }

      this.isLoading = true;
      $axios.get(`${this.affiliateApiV1Url}/partnership/direct`, {
        params: { last_partnership_id: this.lastDirectNetworkPartnershipId, publisher_org_id: this.orgId },
      }).then(response => {
        const newDirectPartnerships = response.data.partnerships;
        this.directNetworkPartnerships = [...this.directNetworkPartnerships, ...newDirectPartnerships];
        if (newDirectPartnerships.length === 0) {
          this.lastDirectNetworkPartnershipId = -1;
        } else {
          this.lastDirectNetworkPartnershipId = newDirectPartnerships.at(-1).partnership_id ?? -1;
        }
      }).finally(() => {
        this.isLoading = false;
      });
    },
  },
});
