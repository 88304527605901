<template>
  <iSubHeading> Social Media </iSubHeading>
</template>

<script>
export default {
  name: "PublicationSocialMedia",
};
</script>

<style scoped></style>
