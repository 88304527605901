<template>
  <PageWrapper
    :active-section="SECTION_COMMUNITY"
    :active-page="PAGE_DIRECTORY"
    title="Directory"
    :breadcrumbs="breadcrumbs"
    page-name="Directory"
    align="center"
    width="full"
  >
    <SmartFeed :pinia-store="useOrgFeedStore" default-smart-feed-layout="grid" default-entity-layout="cardSmall" />
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import SmartFeed from "SmartFeed/components/SmartFeed";
import { PAGE_DIRECTORY, SECTION_COMMUNITY } from "@/constants/nav-constants";
import { useOrgFeedStore } from "SmartFeed/stores/feed/org-feed-store";

export default {
  name: "Directory",
  components: {
    SmartFeed,
    PageWrapper,
  },
  data() {
    return {
      SECTION_COMMUNITY,
      PAGE_DIRECTORY,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: "/community", label: "Community" },
      ];
    },
  },
  methods: { useOrgFeedStore },
};
</script>
