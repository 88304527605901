<template>
  <PageWrapper
    :active-page="PAGE_CREATE_PUBLICATION"
    :active-section="SECTION_PUBLICATIONS"
    :breadcrumbs="publicationBreadCrumbs"
    align="left"
    page-name="Create Publication"
    title="Create Publication"
    width="full"
  >
    <iRow align="between" vertical-align="top" wrap="wrap">
      <iColumn width="hug">
        <iTextInput v-model="name" :is-loading="isLoadingCreatePublication" placeholder="Publication Name" />
        <iTextArea
          v-model="description"
          :is-loading="isLoadingCreatePublication"
          placeholder="Publication Description"
        />
      </iColumn>
      <iColumn width="hug">
        <iButton
          :disabled="!name || !Object.keys(selectedTemplate).length || isLoadingCreatePublication"
          :is-loading="isLoadingCreatePublication"
          loading-spinner-position="before"
          @click="submitPublication"
        >
          Add Publication
        </iButton>
      </iColumn>
    </iRow>
    <iRow>
      <iSubHeading>
        Select a starting template
      </iSubHeading>
    </iRow>
    <SmartFeed
      :pinia-store="usePublicationTemplateFeedStore"
      default-entity-layout="cardSmall"
      default-smart-feed-layout="list"
    />
  </PageWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import PageWrapper from "@/components/PageWrapper";
import { PAGE_CREATE_PUBLICATION, SECTION_PUBLICATIONS } from "@/constants/nav-constants";
import SmartFeed from "SmartFeed/components/SmartFeed.vue";
import { usePublicationTemplateFeedStore } from "SmartFeed/stores/feed/publication-template-feed-store";

export default {
  name: "CreatePublication",
  components: {
    SmartFeed,
    PageWrapper,
  },
  data() {
    return {
      PAGE_CREATE_PUBLICATION,
      SECTION_PUBLICATIONS,
      name: "",
      description: "",
      theme: "0",
    };
  },
  styleGuide: () => ({
    templateCardPadding: { "size.spacing": "none" },
    templateCardDescriptionPadding: { "size.spacing": "standard" },
    templateCardBorderColor: { "color.border": "dark" },
    templateCardBorderWidth: { "size.border": "thin" },
    templateCardSelectedBackground: { "color.background": "striking" },
    templateCardBorderRadius: { "size.borderRadius": "large" },
    templateCardOverlayTextColor: { "color.font": "light" },
  }),
  computed: {
    ...mapState(useCreatorPublicationStore, ["isLoadingCreatePublication", "orgId", "selectedTemplate"]),
    publicationBreadCrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
      ];
    },
  },
  methods: {
    usePublicationTemplateFeedStore,
    ...mapActions(useCreatorPublicationStore, { createPublication: "createPublication" }),
    submitPublication() {
      if (!Object.keys(this.selectedTemplate).length) {
        return;
      }
      this.createPublication(
        this.name,
        this.description,
        this.selectedTemplate.templateId,
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.template-card {
  padding: v-bind("$getStyles.templateCardPadding");
  border: v-bind("$getStyles.templateCardBorderWidth") solid v-bind("$getStyles.templateCardBorderColor");
  border-radius: v-bind("$getStyles.templateCardBorderRadius");
  overflow: hidden;
}

.template-card-description {
  padding: v-bind("$getStyles.templateCardDescriptionPadding");
}

.selected {
  background-color: v-bind("$getStyles.templateCardSelectedBackground");
}

.template-card-overlay {
  background-color: rgba(0, 0, 0, 25%);
  height: 100%;
  border-radius: v-bind("$getStyles.templateCardBorderRadius");
}

.overlay-text {
  color: v-bind("$getStyles.templateCardOverlayTextColor");
}
</style>
