// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.publication-header-wrapper[data-v-40dfe404] {
  position: relative;
}
.publication-header[data-v-40dfe404] {
  position: relative;
  bottom: 30px;
}
.publication-header-logo[data-v-40dfe404] {
  border: var(--40dfe404-\\\$getStyles\\.logoBorderWidth) solid var(--40dfe404-\\\$getStyles\\.logoBorderColor);
  background: var(--40dfe404-\\\$getStyles\\.logoBackground);
}
[data-v-40dfe404] .button-text-link {
  padding-left: var(--40dfe404-\\\$getStyles\\.menuLinkHorizontalPadding) !important;
  padding-right: var(--40dfe404-\\\$getStyles\\.menuLinkHorizontalPadding) !important;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/pages/publication/ViewPublicationDetails.vue","webpack://./ViewPublicationDetails.vue"],"names":[],"mappings":"AAEA;EACE,kBAAA;ACDF;ADIA;EACE,kBAAA;EACA,YAAA;ACDF;ADIA;EACE,uGAAA;EACA,uDAAA;ACDF;ADGA;EACE,+EAAA;EACA,gFAAA;ACAF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.publication-header-wrapper{\n  position: relative;\n}\n\n.publication-header {\n  position: relative;\n  bottom: 30px;\n}\n\n.publication-header-logo {\n  border: v-bind(\"$getStyles.logoBorderWidth\") solid v-bind(\"$getStyles.logoBorderColor\");\n  background: v-bind(\"$getStyles.logoBackground\")\n}\n:deep(.button-text-link){\n  padding-left: v-bind(\"$getStyles.menuLinkHorizontalPadding\") !important;\n  padding-right: v-bind(\"$getStyles.menuLinkHorizontalPadding\") !important;\n}\n",".publication-header-wrapper {\n  position: relative;\n}\n\n.publication-header {\n  position: relative;\n  bottom: 30px;\n}\n\n.publication-header-logo {\n  border: v-bind(\"$getStyles.logoBorderWidth\") solid v-bind(\"$getStyles.logoBorderColor\");\n  background: v-bind(\"$getStyles.logoBackground\");\n}\n\n:deep(.button-text-link) {\n  padding-left: v-bind(\"$getStyles.menuLinkHorizontalPadding\") !important;\n  padding-right: v-bind(\"$getStyles.menuLinkHorizontalPadding\") !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
