<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_FEED_GROUP_LIST"
    title="Feed Blogs"
    :breadcrumbs="breadcrumbs"
    page-name="Feed Blogs"
    align="left"
    width="full"
  >
    <ProfileWrapper
      :splash-image="feedGroup.image_url"
      :logo-image="publicationLogo"
      can-upload-splash
      can-edit-title
      can-edit-details
      has-menu
      :title="feedGroup.feed_name"
      :details="feedGroup.description || 'Click here to add a description for your publication'"
      @click:save-title="saveFeedGroupName"
      @click:save-details="saveFeedGroupDescription"
      @click:splash-image="triggerUploadFeedGroupImage"
    >
      <template #menu>
        <iRow width="fill" align="right">
          <iButton @click="openAddBlogModal">
            Add Blog
          </iButton>
        </iRow>
      </template>
      <iSpace :height="10" />
      <iColumn gap="large">
        <iRow>
          <iLink :href="fanWebFeedGroupUrl">
            View Feed Content
          </iLink>
        </iRow>
        <iRow>
          <SmartFeed :pinia-store="useFeedGroupBlogFeedStore" default-entity-layout="cardSmall" default-smart-feed-layout="list" />
        </iRow>
      </iColumn>
    </ProfileWrapper>
    <iModal
      :visible="isAddBlogModalOpen"
      title="Add Blog to Feed"
      :is-loading="isLoadingAddBlogToFeedGroup"
      :show-primary-action-button="!!selectedBlog.blog_id"
      @click:primary="handleAddBlog"
      @close="closeAddBlogModal"
    >
      <template #body>
        <iRow>
          <!-- <search bar> -->
          <iAsyncAutoComplete
            :items="queriedBlogList"
            item-value-key="blog_id"
            item-label-key="name"
            @search="search"
            @item-selected="selectSearchItem"
          />
        </iRow>
      </template>
    </iModal>
    <iUploader ref="feed-group-image-uploader" @upload-file="uploadFeedGroupImage" />
  </PageWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import PageWrapper from "@/components/PageWrapper";
import {
  PAGE_PUBLICATION_FEED_GROUP_LIST,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import { useFeedStore } from "@/stores/feed-store";
import { useFeedGroupBlogFeedStore } from "SmartFeed/stores/feed/feed-group-blog-feed-store";
import ProfileWrapper from "@/components/publication/ProfileWrapper.vue";
import SmartFeed from "SmartFeed/components/SmartFeed";
export default {
  name: "PublicationFeedGroup",
  components: {
    PageWrapper,
    ProfileWrapper,
    SmartFeed,
  },
  data() {
    return {
      PAGE_PUBLICATION_FEED_GROUP_LIST,
      SECTION_PUBLICATIONS,
      isAddBlogModalOpen: false,
      searchTerm: "",
      queriedBlogList: [],
      selectedBlog: {},
    };
  },
  computed: {
    ...mapState(useCreatorPublicationStore, ["publicationId", "publicationLogo", "orgId"]),
    ...mapState(useFeedStore, ["isLoadingAddBlogToFeedGroup", "feedGroup"]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
        { url: `/publication/${this.publicationId}/feed-groups`, label: "RSS Content" },
      ];
    },
    fanWebFeedGroupUrl() {
      return `${window.location.origin}/my-publication/${this.publicationId}/feed/${this.feedGroup.feed_group_id}`;
    },
  },
  methods: {
    useFeedGroupBlogFeedStore,
    ...mapActions(useFeedStore, [
      "addBlogToFeedGroup",
      "saveFeedGroupName",
      "saveFeedGroupDescription",
      "saveFeedGroupImage",
      "searchBlogs",
    ]),
    openAddBlogModal() {
      this.isAddBlogModalOpen = true;
    },
    closeAddBlogModal() {
      this.isAddBlogModalOpen = false;
    },
    handleAddBlog() {
      this.addBlogToFeedGroup(this.selectedBlog);
      this.closeAddBlogModal();
    },
    uploadFeedGroupImage(file) {
      this.saveFeedGroupImage(file);
    },
    triggerUploadFeedGroupImage() {
      this.$refs["feed-group-image-uploader"].$refs.file.click();
    },
    search(search) {
      this.searchBlogs(search, data => {
        this.queriedBlogList = data.blogs;
        this.nextOffset = data.nextOffset;
      });
    },
    selectSearchItem(item) {
      this.selectedBlog = item;
    },
  },
};
</script>

<style scoped>
</style>
