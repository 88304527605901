<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_POSTS"
    :breadcrumbs="breadcrumbs"
    page-name="Manage Stories"
    align="left"
    width="full"
  >
    <iRow wrap="nowrap" align="between">
      <iColumn width="hug">
        <iHeading>Manage Stories</iHeading>
      </iColumn>
      <iColumn width="hug">
        <iButton
          variant="secondary"
          :is-loading="isLoadingCreateStory"
          width="hug"
          @click="createStory({publishing_platform_id: publicationId, publishing_platform_type: 'publication'})"
        >
          Create Story
        </iButton>
      </iColumn>
    </iRow>
    <SmartFeed
      :pinia-store="useMyPublicationPostFeedStore"
      default-smart-feed-layout="table"
      default-entity-layout="tableRow"
      sticky-headers
      vertical-align="middle"
    />
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import {
  PAGE_PUBLICATION_POSTS,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import { mapActions, mapState, mapWritableState } from "pinia";
import SmartFeed from "SmartFeed/components/SmartFeed";
import { useMyPublicationPostFeedStore } from "SmartFeed/stores/feed/my-publication-post-feed-store";
import { useStoryStore } from "%/stores/story-store";

export default {
  name: "ViewPublicationPostList",
  components: { SmartFeed, PageWrapper },
  data() {
    return {
      PAGE_PUBLICATION_POSTS,
      SECTION_PUBLICATIONS,
      postExclusivityLoading: null,
      dropdownOpenFor: null,
    };
  },
  computed: {
    ...mapWritableState(useCreatorPublicationStore, [
      "orgId",
      "publication",
      "publicationId",
    ]),
    ...mapState(useStoryStore, ["isLoadingCreateStory"]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        {
          url: `/publication/${this.publicationId}`,
          label: this.publication.name,
        },
      ];
    },
  },
  methods: {
    useMyPublicationPostFeedStore,
    ...mapActions(useStoryStore, [
      "createStory",
    ]),
  },
};
</script>

<style scoped lang="scss">
</style>
