<template>
  <PublicationFeatureWrapper heading="Signup And Login">
    <iRow gap="large" wrap="wrap">
      <PublicationSettingsCard
        v-for="(settings, key) in signUpSettingsMap"
        :key="key"
        :description="settings.description"
        :title="settings.title"
        :features="settings.features"
        :focused="selectedSetting === settings"
        @select="selectSetting(key)"
      />
    </iRow>
  </PublicationFeatureWrapper>
</template>

<script>
import PublicationSettingsCard from "@/components/publication/PublicationLoginSettingsCard.vue";
import { useConfigurationStore } from "%/stores/configuration-store";
import PublicationFeatureWrapper from "@/components/publication/features/PublicationFeatureWrapper";

import { mapActions, mapState } from "pinia";

export default {
  name: "PublicationSignupAndLogin",
  components: {
    PublicationSettingsCard,
    PublicationFeatureWrapper,
  },
  computed: {
    ...mapState(useConfigurationStore, [
      "canSignUp",
      "isPrivatePublication",
      "requireAccountApproval",
    ]),
    selectedSetting() {
      return Object.values(this.signUpSettingsMap).find(setting => {
        let canSignUp = this.canSignUp === setting.features.canSignUp;
        let isPrivatePublication =
          this.isPrivatePublication === setting.features.isPrivatePublication;
        let requireAccountApproval =
          this.requireAccountApproval ===
          setting.features.requireAccountApproval;

        return canSignUp && isPrivatePublication && requireAccountApproval;
      });
    },
    signUpSettingsMap() {
      return {
        readOnlySite: {
          title: "Read Only Site",
          description: "No signup no users everything visible to the public",
          features: {
            isPrivatePublication: false,
            canSignUp: false,
            requireAccountApproval: false,
          },
        },
        signupOptional: {
          title: "Signup Optional",
          description:
            "All content visible to the public some features, like commenting, require signup",
          features: {
            isPrivatePublication: false,
            canSignUp: true,
            requireAccountApproval: false,
          },
        },
        signupRequired: {
          title: "Signup Required",
          description:
            "Anyone can signup but all content is hidden from the public",
          features: {
            isPrivatePublication: true,
            canSignUp: true,
            requireAccountApproval: false,
          },
        },
        inviteOnly: {
          title: "Invite Only",
          description:
            "Random signup not allowed, users must be invited then they signup and all content is hidden from the public",
          features: {
            isPrivatePublication: true,
            canSignUp: false,
            requireAccountApproval: true,
          },
        },
        applyToJoin: {
          title: "Apply to Join",
          description:
            "Strangers are allowed to signup, but admin approval required and all content is hidden from the public",
          features: {
            isPrivatePublication: true,
            canSignUp: true,
            requireAccountApproval: true,
          },
        },
      };
    },
  },
  methods: {
    ...mapActions(useConfigurationStore, ["updateConfiguration"]),
    selectSetting(settingKey) {
      let selectedSignupConfiguration = this.signUpSettingsMap[settingKey];
      this.updateConfiguration(selectedSignupConfiguration.features);
    },
  },
};
</script>
