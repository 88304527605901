import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { useFanPublicationStore } from "%/stores/fan-publication-store";

export const useMyPublicationCollaborationAdFeedStore =
  defineSmartFeedPiniaStore("my-publication-collaboration-ad-feed-store", {
    state: () => ({
      smartFeedEngine: new SmartFeedEngine(),
      feed: [],
      metadata: {
        hasMore: true,
        lastId: 0,
        publicationId: useFanPublicationStore().publicationId,
      },
      isLoading: false,
    }),
    getters: {
      hasMore() {
        return this.metadata.hasMore;
      },
    },
    actions: {
      reload() {
        this.$reset();
        this.getSmartFeed();
      },
      getSmartFeed() {
        this.apiGet(
          `/publication-ads/publication/${this.metadata.publicationId}/smart-feed`,
          {
            last_id: this.metadata.lastId,
          },
          data => this.handleSmartFeedResponse(data),
          () => {
            this.metadata.hasMore = false;
          },
        );
      },
    },
  });
