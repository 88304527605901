<template>
  <iColumn>
    <iSubHeading>
      Password complexity requirements
    </iSubHeading>
    <iRow wrap="nowrap">
      <iSpace />
      <iColumn>
        <iRow vertical-align="middle" wrap="nowrap">
          <iText v-if="hasValidPasswordLength" class="form-valid">
            √
          </iText>
          <iText v-else class="form-invalid">
            x
          </iText>
          <iText>
            between 6 and 128 characters.
          </iText>
        </iRow>
        <iRow vertical-align="middle" wrap="nowrap">
          <iText v-if="hasSpecialCharacter" class="form-valid">
            √
          </iText>
          <iText v-else class="form-invalid">
            x
          </iText>
          <iText>
            at least one special character.
          </iText>
        </iRow>
        <iRow vertical-align="middle" wrap="nowrap">
          <iText v-if="hasUpperAndLowerCase" class="form-valid">
            √
          </iText>
          <iText v-else class="form-invalid">
            x
          </iText>
          <iText>
            at least one uppercase & one lowercase letter.
          </iText>
        </iRow>
      </iColumn>
    </iRow>
  </iColumn>
</template>

<script>

export default {
  name: "PasswordComplexity",
  props: {
    password: {
      type: String,
      required: true,
    },
  },
  emits: ["update:password", "update:passwordValidity"],
  computed: {
    hasValidPasswordLength() {
      if (!this.password) {
        return false;
      }
      return !(this.password.length < 6 || this.password.length > 128);
    },
    hasSpecialCharacter() {
      if (!this.password) {
        return false;
      }
      return /[!@#$%^&*]/.test(this.password);
    },
    hasUpperAndLowerCase() {
      return !!(this.password && /[A-Z]/.test(this.password) && /[a-z]/.test(this.password));
    },
  },
  watch: {
    password() {
      this.updatePassword();
    },
  },
  methods: {
    updatePassword() {
      this.$emit("update:password", this.hasUpperAndLowerCase && this.hasValidPasswordLength && this.hasSpecialCharacter);
    },
  },
};
</script>

<style scoped lang="scss">
  .form-valid {
    color: green;
  }
  .form-invalid {
    color: red;
  }
</style>
