// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chatroom[data-v-ca884194] {
  width: 100%;
}
[data-v-ca884194] .infinite-scroll {
  display: flex;
  overflow: scroll;
  flex-direction: column;
  justify-content: flex-end;
}
[data-v-ca884194] .v3-infinite-loading {
  height: unset;
}
.chatroom-intro[data-v-ca884194] {
  border-bottom: var(--ca884194-\\\$getStyles\\.borderBottomWidth) solid var(--ca884194-\\\$getStyles\\.borderBottomColor);
}`, "",{"version":3,"sources":["webpack://./src/SharedWeb/scripts/components/community/chatrooms/slack-style/ChatroomThread.vue","webpack://./ChatroomThread.vue"],"names":[],"mappings":"AAEA;EACE,WAAA;ACDF;ADIA;EACE,aAAA;EACA,gBAAA;EACA,sBAAA;EACA,yBAAA;ACDF;ADIA;EACE,aAAA;ACDF;ADIA;EACE,kHAAA;ACDF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.chatroom {\n  width: 100%;\n}\n\n:deep(.infinite-scroll) {\n  display: flex;\n  overflow: scroll;\n  flex-direction: column;\n  justify-content: flex-end;\n}\n\n:deep(.v3-infinite-loading) {\n  height: unset;\n}\n\n.chatroom-intro {\n  border-bottom: v-bind('$getStyles.borderBottomWidth') solid v-bind('$getStyles.borderBottomColor');\n}\n",".chatroom {\n  width: 100%;\n}\n\n:deep(.infinite-scroll) {\n  display: flex;\n  overflow: scroll;\n  flex-direction: column;\n  justify-content: flex-end;\n}\n\n:deep(.v3-infinite-loading) {\n  height: unset;\n}\n\n.chatroom-intro {\n  border-bottom: v-bind(\"$getStyles.borderBottomWidth\") solid v-bind(\"$getStyles.borderBottomColor\");\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
