<template>
  <PageWrapper :active-section="SECTION_PUBLICATIONS" :active-page="PAGE_PUBLICATION_POSTS">
    <iColumn disabled>
      <iRow vertical-align="middle">
        <iCheckbox
          v-model="exclusive"

          label="Exclusive"
        />
        <iColumn v-tooltip="'Create a draft version of your post to enable this option'" width="hug">
          <iIcon icon="info-circle" />
        </iColumn>
      </iRow>
    </iColumn>
    <iRichTextEditor
      :disable-hashtags="true"
      submit-button-text="Create Draft"
      :upload-post-image="uploadFunction"
      :update-post-is-loading="updatePostIsLoading"
      enable-hashtags
      @submit:post="submitPost"
      @cancel:post="cancelPost"
    />
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper.vue";
import { PAGE_PUBLICATION_POSTS, SECTION_PUBLICATIONS } from "@/constants/nav-constants";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import { mapActions, mapState } from "pinia";

export default {
  name: "CreatePublicationPost",
  components: {
    PageWrapper,
  },
  data() {
    return {
      PAGE_PUBLICATION_POSTS,
      SECTION_PUBLICATIONS,
      exclusive: false,
    };
  },
  computed: {
    ...mapState(useCreatorPublicationStore, [
      "publicationId",
      "updatePostIsLoading",
    ]),
  },
  methods: {
    ...mapActions(useCreatorPublicationStore, [
      "uploadPostImage",
      "createPublicationPost",
    ]),
    async uploadFunction(file) {
      return await this.uploadPostImage(file);
    },
    cancelPost() {
      window.location.href = `/publication/${this.publicationId}/stories`;
    },
    submitPost(title, content, hashtags) {
      this.createPublicationPost(title, content, hashtags, this.exclusive);
    },
  },
};
</script>

<style scoped lang="scss">
.native-post {
  &-title {
    width: 100%;
    padding-block: 10px;
    border: none;

    font-size: 26px;
    font-weight: 700;

    &:focus {
      outline: none;
    }
  }

  &-content {
    &-container {
      width: 100%;
      position: relative;

      .editor-container {
        width: 100%;

        :deep(.ProseMirror) {
          padding-block: 10px;

          &:focus-visible {
            outline: none;
          }
        }
      }
    }

    width: 100%;
  }
}

:deep(.tiptap) {
  /* Placeholder (at the top) */
  p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
}
</style>
