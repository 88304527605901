<template>
  <PageWrapper :active-section="SECTION_COMMUNITY" :active-page="PAGE_COMMUNITY_SETTINGS">
    <iHeading> Community Settings </iHeading>
    <iColumn>
      <iToggleSwitch v-model="settings.allowModerators" label="Allow moderating" />
      <iToggleSwitch v-model="settings.allowsEditing" label="Allow editing" />
      <iToggleSwitch v-model="settings.allowsReply" label="Allow replying" />
      <iToggleSwitch v-model="settings.allowsLike" label="Allow liking" />
      <iToggleSwitch v-model="settings.allowsUpvoteAndDownvote" label="Allow voting" />
      <iToggleSwitch v-model="settings.allowsSharing" label="Allow sharing" />
    </iColumn>
    <iHeading> Community Moderation Settings </iHeading>
    <iToggleSwitch v-model="isModerating" label="Turn On Moderating" />
    <!-- <iColumn v-if="settings.allowModerators">
      <iHeading> Assigned Moderators: </iHeading>
      display list of users to set as a moderator
    </iColumn> -->
    <iButton @click="saveSettings">
      Save
    </iButton>
  </PageWrapper>
</template>

<script>
import { mapState, mapWritableState, mapActions } from "pinia";
import { useConversationStore } from "%/stores/conversation-store";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import PageWrapper from "@/components/PageWrapper";
import { SECTION_COMMUNITY, PAGE_COMMUNITY_SETTINGS } from "@/constants/nav-constants";

export default {
  name: "CommunitySettings",
  components: {
    PageWrapper,
  },
  data() {
    return {
      SECTION_COMMUNITY,
      PAGE_COMMUNITY_SETTINGS,
      settings: {
        allowsEditing: false,
        allowsLike: false,
        allowsUpvoteAndDownvote: false,
        allowsReply: false,
        allowsSharing: false,
      },
    };
  },
  computed: {
    ...mapState(useCreatorPublicationStore, ["configuration"]),
    ...mapState(useConversationStore, ["conversationSettings"]),
    ...mapWritableState(useConversationStore, ["isModerating"]),
  },
  methods: {
    ...mapActions(useConversationStore, {
      saveConversationSettings: "saveConversationSettings",
    }),
    saveSettings() {
      this.saveConversationSettings(this.settings);
    },
  },
};
</script>
