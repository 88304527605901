<template>
  <PageWrapper :active-section="SECTION_MONETIZATION" :active-page="PAGE_DIGITAL_PRODUCTS">
    <iDataTable
      :items="digitalProducts"
      :has-more="hasMoreDigitalProducts"
      :is-loading="isLoadingDigitalProducts"
      @load-more="loadMore"
    />
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { useDigitalProductStore } from "@/stores/digital-product-store";
import { mapActions, mapState } from "pinia";
import { SECTION_MONETIZATION, PAGE_DIGITAL_PRODUCTS } from "@/constants/nav-constants";

export default {
  name: "DigitalProducts",
  components: {
    PageWrapper,
  },
  props: {},
  data() {
    return {
      SECTION_MONETIZATION,
      PAGE_DIGITAL_PRODUCTS,
    };
  },
  computed: {
    ...mapState(useDigitalProductStore, [
      "digitalProducts",
      "isLoadingDigitalProducts",
      "hasMoreDigitalProducts",
    ]),
  },
  created() {
    this.fetchDigitalProducts();
  },
  methods: {
    ...mapActions(useDigitalProductStore, [
      "fetchDigitalProducts",
    ]),
    loadMore() {
      this.fetchDigitalProducts();
    },
  },
};
</script>
