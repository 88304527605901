import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";

export const usePublicationPageFeedStore = defineSmartFeedPiniaStore("publication-page-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    feed: [],
    metadata: {
      hasMore: true,
      lastId: 0,
      publicationId: useCreatorPublicationStore().publicationId,
    },
    tableFields: [
      {
        key: "name",
        label: "Page Name",
        width: "320px",
      },
    ],
    isLoading: false,
  }),
  getters: {
    hasMore() {
      return this.metadata.hasMore;
    },
  },
  actions: {
    getSmartFeed() {
      this.apiGet(
        `/pages/publication/${this.metadata.publicationId}/smart-feed`,
        {
          last_id: this.metadata.lastId,
        },
        data => this.handleSmartFeedResponse(data),
        () => {
          this.hasMore = false;
        },
      );
    },
  },
});
