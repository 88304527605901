// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar[data-v-6d04bd29] {
  border-radius: 100%;
  height: var(--6d04bd29-size);
  width: var(--6d04bd29-size);
}`, "",{"version":3,"sources":["webpack://./src/SharedWeb/scripts/components/AvatarImage.vue","webpack://./AvatarImage.vue"],"names":[],"mappings":"AAEA;EACE,mBAAA;EACA,4BAAA;EACA,2BAAA;ACDF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.avatar {\n  border-radius: 100%;\n  height: v-bind(size);\n  width: v-bind(size);\n}\n",".avatar {\n  border-radius: 100%;\n  height: v-bind(size);\n  width: v-bind(size);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
