import { useGeneralStore } from "%/stores/general-store";

function filterMenuItems(menuItems) {
  const filteredNavItems = [];
  menuItems.forEach(menuItem => {
    if (menuItem.requiredCapabilities && menuItem.requiredCapabilities.length > 0) {
      menuItem.requiredCapabilities.forEach(capability => {
        if (!useGeneralStore().orgCapabilities.includes(capability)) {
          return;
        }
        filteredNavItems.push(menuItem);
      });
    } else {
      filteredNavItems.push(menuItem);
    }
  });
  return filteredNavItems;
}

export {
  filterMenuItems,
};
