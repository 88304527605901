<template>
  <iStyledCard
    :author-name="`${collaborator.name}`"
    width="hug"
    :default-href="profileHref"
    :pointer-cursor-on-hover="!!profileHref"
    :author-description="`${formattedStatus} &bull; ${roleOrEmail}`"
    :author-avatar="avatar"
  >
    <template v-if="showActionButtons" #footer>
      <iRow wrap="nowrap" vertical-align="middle">
        <iColumn v-for="action of actionItems" :key="action.name">
          <iButton width="fill" variant="secondary" @click="handleAction(action.actionValue)">
            {{ action.label }}
          </iButton>
        </iColumn>
      </iRow>
    </template>
  </iStyledCard>
</template>

<script>
import { getActionItems, getFormattedStatus, shouldShowActionButton } from "@/utils/collaborator-card-utils";
import { DEFAULT_USER_AVATAR } from "%/constants/user-constants";
import { snakeToTitle } from "@bloglovin/vue-component-library";
export default {
  name: "PublicationCollaboratorCard",
  props: {
    collaborator: {
      type: Object,
      required: true,
    },
    showActions: {
      type: Boolean,
      default: false,
    },
    isInvitation: {
      type: Boolean,
      default: false,
    },
    isPublicationOwner: {
      type: Boolean,
      default: false,
    },
    profileHref: {
      type: String,
      default: "",
    },
  },
  emits: ["accept", "deny", "remove", "revoke"],
  computed: {
    formattedStatus() {
      return getFormattedStatus(this.collaborator.status);
    },
    showActionButtons() {
      return shouldShowActionButton(this.collaborator.status, this.showActions);
    },
    actionItems() {
      return getActionItems(this.isPublicationOwner, this.collaborator.status);
    },
    avatar() {
      return this.collaborator.avatar || DEFAULT_USER_AVATAR;
    },
    roleOrEmail() {
      if (this.collaborator.role) {
        return snakeToTitle(this.collaborator.role);
      }
      return this.collaborator.email;
    },
  },
  methods: {
    snakeToTitle,
    handleAction(actionValue) {
      this.$emit(actionValue, this.collaborator.id);
    },
  },
};
</script>

<style scoped lang="scss">
.i-card {
  overflow: visible;
}
</style>
