<template>
  <PageWrapper
    :active-section="SECTION_MONETIZATION"
    :active-page="PAGE_BROWSER_PLUGIN"
    :breadcrumbs="breadcrumbs"
    title="Browser Plugins"
    page-name="Browser Plugins"
    align="left"
    width="fixed"
  >
    <iRow>
      <iText>Download our browser extension to create monetizable links to your favorite products on websites in the inspiration network.</iText>
      <iText>Organize these links into collections and track the performance of your existing links, all without leaving the page.</iText>
    </iRow>
    <iRow v-if="isSupportedBrowser">
      <template v-if="isChromium">
        <iLink v-if="!isInstalled" href="https://google.co.za">
          <iButton>Download Now</iButton>
        </iLink>
        <iButton v-else disabled>
          Already Installed
        </iButton>
      </template>
    </iRow>
    <iRow v-else>
      <iAlert>
        This browser is currently not supported. In order to make use of our extension please download Google Chrome
      </iAlert>
    </iRow>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { SECTION_MONETIZATION, PAGE_BROWSER_PLUGIN } from "@/constants/nav-constants";
import ComingSoon from "@/components/ComingSoon";

export default {
  name: "BrowserPlugins",
  components: {
    ComingSoon,
    PageWrapper,
  },
  props: {},
  data() {
    return {
      SECTION_MONETIZATION,
      PAGE_BROWSER_PLUGIN,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/monetization/org/${this.orgId}`, label: "Monetization" },
      ];
    },
    isChromium() {
      return !!window.chrome;
    },
    isFirefox() {
      return window.navigator.userAgent.indexOf("Firefox") > -1;
    },
    isSupportedBrowser() {
      return this.isChromium;
    },
    isInstalled() {
      return false;
    },
  },
};
</script>
