<template>
  <iColumn class="digital-products">
    <iRow vertical-align="middle">
      <iSubHeading> Digital Products </iSubHeading>
      <iColumn width="hug">
        <iButton
          v-if="!isCreateDigitalProductModalOpen"
          variant="secondary"
          size="small"
          @click="openCreateDigitalProductModal"
        >
          Add Digital Product
        </iButton>
      </iColumn>
    </iRow>
    <iColumn :height="500">
      <SmartFeed
        :pinia-store="useMyPublicationDigitalProductFeedStore"
        default-smart-feed-layout="grid"
        default-entity-layout="cardSmall"
      />
    </iColumn>
    <CreateDigitalProductModal
      :visible="isCreateDigitalProductModalOpen"
      @close="closeCreateDigitalProductModal"
    />
  </iColumn>
</template>

<script>
import { mapActions, mapState } from "pinia";
import SmartFeed from "SmartFeed/components/SmartFeed";
import { useMyPublicationDigitalProductFeedStore } from "SmartFeed/stores/feed/my-publication-digital-product-feed-store";
import { usePublicationDigitalProductsStore } from "@/stores/publication-digital-products-store";

import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import CreateDigitalProductModal from "@/components/publication/CreateDigitalProductModal.vue";

export default {
  name: "MyPublicationDigitalProducts",
  components: {
    CreateDigitalProductModal,
    SmartFeed,
  },
  props: {},
  data() {
    return {
      subscriberAreaEnabled: false,
    };
  },
  computed: {
    ...mapState(usePublicationDigitalProductsStore, ["isCreateDigitalProductModalOpen"]),
    ...mapState(useCreatorPublicationStore, ["availableDigitalProducts"]),
  },
  methods: {
    ...mapActions(usePublicationDigitalProductsStore, [
      "openCreateDigitalProductModal",
      "closeCreateDigitalProductModal",
    ]),
    useMyPublicationDigitalProductFeedStore,
  },
};
</script>

<style scoped></style>
