import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";

export const useMyPublicationCollaboratorsFeedStore = defineSmartFeedPiniaStore(
  "my-publication-collaborators-feed-store",
  {
    state: () => ({
      smartFeedEngine: new SmartFeedEngine(),
      feed: [],
      metadata: {
        hasMore: true,
        lastId: 0,
        publicationId: useCreatorPublicationStore().publicationId,
      },
      tableFields: [
        { key: "name", label: "Name", width: "20%" },
        { key: "email", label: "Email", width: "30%" },
        { key: "role", label: "Role", width: "20%" },
        { key: "status", label: "Status", width: "20%" },
        { key: "actions", label: "", width: "10%" },
      ],
      isLoading: false,
    }),
    getters: {
      hasMore() {
        return this.metadata.hasMore;
      },
    },
    actions: {
      getSmartFeed() {
        this.apiGet(
          `/publication/${this.metadata.publicationId}/collaborators/smart-feed`,
          {
            last_id: this.metadata.lastId,
          },
          data => this.handleSmartFeedResponse(data),
          () => this.metadata.hasMore = false,
        );
      },
    },
  },
);
