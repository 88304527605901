// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card[data-v-fd330c5e] {
  overflow: visible !important;
}
.deleted[data-v-fd330c5e] {
  background-color: #bdbdbd;
}`, "",{"version":3,"sources":["webpack://./src/SharedWeb/scripts/components/community/chatrooms/comments/ReplyWidget.vue","webpack://./ReplyWidget.vue"],"names":[],"mappings":"AAEA;EACE,4BAAA;ACDF;ADGA;EACE,yBAAA;ACAF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.card {\n  overflow: visible!important;\n}\n.deleted {\n  background-color: #bdbdbd;\n}\n",".card {\n  overflow: visible !important;\n}\n\n.deleted {\n  background-color: #bdbdbd;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
