<template>
  <iStyledCard
    :badge="mappedPublicationStatus"
    :default-href="`/publication/${publication.publication_id}`"
    :metrics="publication.metrics"
    :splash-image="publicationSplashImage"
    :splash-image-max-height="170"
    :splash-image-min-height="170"
    :width="350"
    height="hug"
  >
    <template #title>
      <iRow vertical-align="middle" wrap="nowrap">
        <iImageContainer
          :height="35"
          :src="publicationLogo"
          :width="35"
          object-fit="cover"
          shape="circle"
        />
        <iColumn gap="none">
          <iSubHeading class="subheading" font-weight="dark">
            {{ publication.name }}
          </iSubHeading>
          <iLink v-if="usePublicDomain" :href="publicProfileUrl">
            <iText font-size="small">
              View public profile
            </iText>
          </iLink>
          <iLink v-else :href="subTitleHref">
            <iText font-size="small">
              View production site
            </iText>
          </iLink>
        </iColumn>
      </iRow>
    </template>
    <template v-if="description" #description>
      <iLink :href="descriptionHref" hover-underline target="_blank">
        {{ description }}
      </iLink>
      <iSpace :height="5" />
    </template>
  </iStyledCard>
</template>

<script>
import { DEFAULT_ORG_LOGO, DEFAULT_SPLASH_IMAGE } from "%/constants/publication-constants";
import { snakeToTitle } from "@bloglovin/vue-component-library";

export default {
  name: "PublicationCard",
  props: {
    publication: {
      type: Object,
      required: true,
    },
    usePublicDomain: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showViewDropdown: false,
      DEFAULT_ORG_LOGO,
      DEFAULT_SPLASH_IMAGE,
    };
  },
  computed: {
    mappedPublicationStatus() {
      switch (this.publication.status) {
        case "active":
          return "Live";
        case "draft":
          return "Draft";
        default:
          return "Offline";
      }
    },
    subTitleHref() {
      return `${window.location.origin}/my-publication/${this.publication.publication_id}`;
    },
    description() {
      if (this.publication.staged_changes) {
        return `${this.publication.staged_changes} unpublished changes on staging.`;
      }
      return "";
    },
    publicProfileUrl() {
      return `${window.location.origin}/community/publication/${this.publication.publication_id}/profile`;
    },
    descriptionHref() {
      return `${window.location.origin}/my-publication/staging/${this.publication.publication_id}`;
    },
    publicationLogo() {
      if (!this.publication.publication_logo) {
        return this.DEFAULT_ORG_LOGO;
      }
      return this.publication.publication_logo;
    },
    publicationSplashImage() {
      if (!this.publication.splash_image) {
        return this.DEFAULT_SPLASH_IMAGE;
      }
      return this.publication.splash_image;
    },
  },
  methods: {
    snakeToTitle,
  },
  styleGuide: () => ({
    borderColor: { "color.border": "dark" },
    borderSize: { "size.border": "thin" },
    borderRadius: { "size.borderRadius": "large" },
    paddingTop: { "size.spacing": "large" },
    paddingLeft: { "size.spacing": "large" },
    paddingRight: { "size.spacing": "large" },
    paddingBottom: { "size.spacing": "large" },
  }),
};

</script>
<style lang="scss" scoped>
.publication-card {
  border: v-bind("$getStyles.borderSize") solid v-bind("$getStyles.borderColor");
  border-radius: v-bind("$getStyles.borderRadius");
}

.publication-details {
  padding-top: v-bind("$getStyles.paddingTop");
  padding-left: v-bind("$getStyles.paddingLeft");
  padding-right: v-bind("$getStyles.paddingRight");
  padding-bottom: v-bind("$getStyles.paddingBottom");
}

.subheading {
  line-height: unset;
}
</style>
