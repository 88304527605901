<template>
  <iColumn
    width="hug"
    vertical-align="middle"
    align="right"
  >
    <iDropdown :show-dropdown="showMenuOptions" position="left" @close="hideMenu">
      <template #trigger>
        <iRow v-if="!showMenuOptions" vertical-align="middle" @click="showMenu">
          <iLoadingSpinner v-if="isLoading" />
          <iIcon v-else icon="ellipsis" />
        </iRow>
      </template>
      <template #overlay>
        <iColumn gap="small">
          <iButton v-if="!isAuthor" variant="tertiary" @click="showReportsMenu">
            Report
          </iButton>
          <iButton v-if="showBlockButton" variant="tertiary" @click="blockUser">
            Block
          </iButton>
        </iColumn>
        <iModal
          :visible="isShowingReportingOptions"
          title="Report This User"
          :show-footer="false"
          @close="closeReportsModal"
          @blur="closeReportsModal"
        >
          <template #body>
            <iRow>
              <iColumn
                v-for="(offense, index) in OFFENSE_OPTIONS"
                :key="index"
                width="hug"
              >
                <iButton
                  variant="secondary"
                  @click="report(offense.name)"
                >
                  {{ offense.name }}
                </iButton>
              </iColumn>
            </iRow>
          </template>
        </iModal>
      </template>
    </iDropdown>
  </iColumn>
</template>

<script>
import { OFFENSE_OPTIONS } from "%/constants/conversations-constants";

export default {
  name: "ConversationCollaboratorMenu",
  props: {
    moderationEnabled: {
      type: Boolean,
      required: true,
    },
    isModerator: {
      type: Boolean,
      required: true,
    },
    isAuthor: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: [
    "block",
    "approve",
    "report",
  ],
  data() {
    return {
      OFFENSE_OPTIONS,
      isSharing: false,
      showMenuOptions: false,
      isShowingReportingOptions: false,
    };
  },
  computed: {
    showBlockButton() {
      return !this.isAuthor && this.isModerator && this.moderationEnabled;
    },
  },
  methods: {
    showMenu() {
      this.showMenuOptions = true;
    },
    hideMenu() {
      this.showMenuOptions = false;
    },
    showReportsMenu() {
      this.isShowingReportingOptions = true;
    },
    report(reason) {
      this.hideMenu();
      this.isShowingReportingOptions = false;
      this.$emit("report", reason);
    },
    closeReportsModal() {
      this.isShowingReportingOptions = false;
    },
    blockUser() {
      if (!this.isModerator) {
        return;
      }
      this.$emit("block");
    },
    showSocialShareButtons() {
      this.isSharing = true;
      this.showMenuOptions = false;
    },
  },
};
</script>

<style scoped lang="scss"></style>
