export const DEFAULT_ORG_LOGO = "https://cdn-alpha.inspiration.co/images/generic_company_logo.webp";
export const DEFAULT_SPLASH_IMAGE = "https://inspirationprod.blob.core.windows.net/inspiration-cdn/images/publication-blog.jpg";
export const FANWEB_EMPTY_STATE_IMAGE = "https://cdn-alpha.inspiration.co/images/fanweb-empty-state.webp";
export const PUBLICATION_COMMENTS_DEFAULT_CONFIG = {
  enabled: false,
  allowsReply: false,
  allowsLikes: false,
  allowsComments: false,
  allowsUpvoteAndDownvote: false,
  allowsShare: false,
  allowsEditing: false,
  nestedRepliesLimit: 1,
  collapsedReplies: false,
  showReplyReference: false,
  defaultSortingOrder: "Upvotes Descending",
};
