import { usePublicationStore } from "@/stores/publication-store";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";

export const useMyPublicationDigitalProductFeedStore =
  defineSmartFeedPiniaStore("my-publication-digital-product-feed-store", {
    state: () => ({
      smartFeedEngine: new SmartFeedEngine(),
      feed: [],
      metadata: {
        hasMore: true,
        lastId: 0,
        publicationId: usePublicationStore().publicationId,
      },
      isLoading: false,
    }),
    getters: {
      hasMore() {
        return this.metadata.hasMore;
      },
    },
    actions: {
      resetFeed() {
        this.metadata.lastId = 0;
        this.metadata.hasMore = true;
        this.feed = [];
      },
      getSmartFeed() {
        this.isLoading = true;
        this.apiGet(
          `/publication-digital-products/publication/${this.metadata.publicationId}/smart-feed`,
          {
            last_id: this.metadata.lastId,
          },
          data => {
            this.handleSmartFeedResponse(data);
            this.isLoading = false;
          },
          () => {
            this.metadata.hasMore = false;
            this.isLoading = false;
          },
        );
      },
    },
  });
