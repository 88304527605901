import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { useGeneralStore } from "%/stores/general-store";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";

export const useOfflinePublicationFeedStore = defineSmartFeedPiniaStore(
  "offline-publication-feed-store",
  {
    state: () => ({
      smartFeedEngine: new SmartFeedEngine(),
      feed: [],
      metadata: {
        hasMore: true,
        lastId: 0,
        publicationOrgId: useGeneralStore().orgId,
      },
      isLoading: false,
    }),
    getters: {
      hasMore() {
        return this.metadata.hasMore;
      },
    },
    actions: {
      getSmartFeed() {
        const params = {
          last_id: this.metadata.lastId,
          status: "inactive",
        };
        this.apiGet(
          `/publication/org/${this.metadata.publicationOrgId}/smart-feed/status`,
          params,
          data => {
            this.handleSmartFeedResponse(data);
          },
          () => {
            this.metadata.hasMore = false;
          },
        );
      },
    },
  },
);
