// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.send-btn[data-v-c96724a6] {
  position: absolute;
  right: 12px;
  top: 0;
  bottom: 0;
  margin-block: auto;
}
.message-box-container[data-v-c96724a6] {
  position: relative;
}
.message-box-container[data-v-c96724a6] input {
  padding-right: 36px;
}`, "",{"version":3,"sources":["webpack://./src/SharedWeb/scripts/components/community/chatrooms/slack-style/NewChatroomMessage.vue","webpack://./NewChatroomMessage.vue"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,WAAA;EACA,MAAA;EACA,SAAA;EACA,kBAAA;ACDF;ADIA;EACE,kBAAA;ACDF;ADIA;EACE,mBAAA;ACDF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.send-btn{\n  position: absolute;\n  right: 12px;\n  top:0;\n  bottom: 0;\n  margin-block: auto;\n}\n\n.message-box-container {\n  position: relative;\n}\n\n.message-box-container :deep(input) {\n  padding-right: 36px;\n}\n",".send-btn {\n  position: absolute;\n  right: 12px;\n  top: 0;\n  bottom: 0;\n  margin-block: auto;\n}\n\n.message-box-container {\n  position: relative;\n}\n\n.message-box-container :deep(input) {\n  padding-right: 36px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
