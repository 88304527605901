<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_FEED_GROUP_LIST"
    title="RSS Content"
    :breadcrumbs="breadcrumbs"
    page-name="RSS Content"
    align="left"
    width="full"
  >
    <iRow width="fill" align="right">
      <iButton @click="openCreateFeedModal">
        Create New Feed
      </iButton>
    </iRow>
    <SmartFeed :pinia-store="useFeedGroupFeedStore" default-entity-layout="cardSmall" default-smart-feed-layout="grid" />
    <iModal
      :visible="isCreateFeedModalOpen"
      title="Create New Feed"
      :is-loading="isLoadingCreateFeedGroup"
      @close="closeCreateFeedModal"
      @click:primary="handleCreateFeedGroup"
    >
      <template #body>
        <iRow width="fill">
          <iTextInput
            v-model="feedGroupName"
            label="Feed Name"
            placeholder="eg. Spring Fashion"
          />
          <iTextArea
            v-model="feedGroupDescription"
            label="Feed Description"
            placeholder="This is a description"
          />
          <iImageUploader
            :image="feedGroupImageSrc"
            change-image-text="Update Feed Thumbnail"
            select-image-text="Add Feed Thumbnail"
            button-variant="secondary"
            @upload-image="uploadFeedGroupImage"
          />
        </iRow>
      </template>
    </iModal>
  </PageWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import PageWrapper from "@/components/PageWrapper";
import {
  PAGE_PUBLICATION_FEED_GROUP_LIST,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import { useFeedStore } from "@/stores/feed-store";
import { useFeedGroupFeedStore } from "SmartFeed/stores/feed/feed-group-feed-store";
import { DEFAULT_COLLECTION_COVER_IMAGE } from "@/constants/affiliate-constants";
import SmartFeed from "SmartFeed/components/SmartFeed";
export default {
  name: "PublicationFeedGroupList",
  components: {
    PageWrapper,
    SmartFeed,
  },
  data() {
    return {
      PAGE_PUBLICATION_FEED_GROUP_LIST,
      SECTION_PUBLICATIONS,
      isCreateFeedModalOpen: false,
      feedGroupName: "",
      feedGroupDescription: "",
      feedGroupImage: null,
    };
  },
  computed: {
    ...mapState(useCreatorPublicationStore, ["publicationId", "orgId"]),
    ...mapState(useFeedStore, ["isLoadingCreateFeedGroup"]),
    feedGroupImageSrc() {
      if (this.feedGroupImage) {
        return this.feedGroupImage;
      }
      return DEFAULT_COLLECTION_COVER_IMAGE;
    },
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
      ];
    },
  },
  methods: {
    useFeedGroupFeedStore,
    ...mapActions(useFeedStore, ["createFeedGroup"]),
    uploadFeedGroupImage(event) {
      this.feedGroupImage = event;
    },
    openCreateFeedModal() {
      this.isCreateFeedModalOpen = true;
    },
    closeCreateFeedModal() {
      this.isCreateFeedModalOpen = false;
    },
    handleCreateFeedGroup() {
      this.createFeedGroup(this.feedGroupName, this.feedGroupDescription, this.feedGroupImage, () => {
        this.closeCreateFeedModal();
      });
    },
  },
};
</script>

<style scoped>
</style>
