<template>
  <iColumn align="center" gap="large" class="subscription-success">
    <iHeading>Subscription Successful!</iHeading>
    <iText>
      Your payment is being processed. You will be redirected shortly.
    </iText>
    <iSpace :height="2" />
  </iColumn>
</template>

<script>
import { useGeneralStore } from "%/stores/general-store";

export default {
  name: "SubscriptionSuccess",
  props: {
    publicationId: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    setTimeout(() => {
      window.location = useGeneralStore().getRelativeHref(
        `/publication/${this.publicationId}/features/pro`,
      );
    }, 3000);
  },
};
</script>

<style scoped>
.subscription-success {
  text-align: center;
  padding: 2rem;
}
</style>
