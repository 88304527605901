<template>
  <iHeading> Navigation </iHeading>
  <iColumn :width="600" align="right">
    <iRow width="hug">
      <iButton variant="secondary" @click="openAddMenu">
        Add Nav Item
      </iButton>
    </iRow>
    <iRow>
      <iSortable :model-value="primaryNavigation" @update:model-value="updatePrimaryNavigationConfig">
        <template #draggable-item="{menuItem, index}">
          <iRow
            align="between"
            class="menu-item-config"
            :width="500"
            gap="large"
          >
            <iColumn width="hug">
              <iText>{{ menuItem.label }}</iText>
            </iColumn>
            <iColumn width="hug">
              <iText>{{ menuItemUrl(menuItem) }}</iText>
            </iColumn>
            <iColumn width="hug">
              <iDropdown :show-dropdown="isDropdownOpen(index)" position="left" @close="closeDropdownMenu()">
                <template #trigger>
                  <iIcon icon="dots-vertical" @click="dropdownMenuItemIndex = index" />
                </template>
                <template #overlay>
                  <iColumn>
                    <iButton variant="tertiary" @click="openEditMenuForItemIndex(index)">
                      Edit
                    </iButton>
                    <iButton variant="tertiary" @click="removeMenuItem(index)">
                      Remove
                    </iButton>
                  </iColumn>
                </template>
              </iDropdown>
            </iColumn>
          </iRow>
        </template>
      </iSortable>
    </iRow>
  </iColumn>
  <iModal
    :visible="isModalOpen"
    :title="`${isAddingMenuItem?'Add':'Edit'} Nav Item`"
    primary-action-button-label="Add Item"
    secondary-action-button-label="Cancel"
    :primary-action-button-disabled="!isValidNavItem"
    width="fill"
    @click:primary="saveMenuItem"
    @click:secondary="closeEditModal"
    @close="closeEditModal"
  >
    <template #body>
      <iColumn :max-width="400" width="fill">
        <iRow>
          <iTextInput v-model="updatingMenuItem.label" width="fill" placeholder="Display Text" />
        </iRow>
        <iSelect
          v-model="updatingMenuItem.type"
          return-value
          width="fill"
          :items="menuItemTypes"
          label="Menu Item Type"
          value-field="value"
        />
        <iRow v-if="updatingMenuItem.type==='hashtag'">
          <iTextInput v-model="updatingMenuItem.val" width="fill" :placeholder="placeholderText" />
          <iText variant="subtle">
            This landing page will show all posts tagged with the given hashtag
          </iText>
        </iRow>
        <!-- <iText v-if="updatingMenuItem.type==='url'" variant="subtle">
          Enter a url for this page, you will be able to edit that page later
        </iText> -->
        <iRow v-if="updatingMenuItem.type==='post'">
          <iAutoComplete
            v-model="updatingMenuItem.val"
            :items="availablePosts"
            :loading="isLoadingAvailablePosts"
            item-value-key="post_id"
            item-label-key="post_title"
          />
          <iText variant="subtle">
            This is a navigation link to a post within your publication
          </iText>
        </iRow>
        <iRow v-if="updatingMenuItem.type==='feed'">
          <iTextEdit
            value="My Feed"
            variant="locked"
            show-as-text
            @submit="createNewFeedGroup"
          >
            <template #display>
              <iButton>
                Add Feed Group
              </iButton>
            </template>
          </iTextEdit>
          <iAsyncAutoComplete
            v-model="updatingMenuItem.val"
            :items="availableFeedGroups"
            item-value-key="feed_group_id"
            item-label-key="feed_name"
            @search="fetchAvailableFeedGroups"
            @item-selected="selectFeedGroup"
          />
          <iText variant="subtle">
            This is a navigation link to an rss feed
          </iText>
        </iRow>
        <iRow v-if="updatingMenuItem.type==='page'">
          <iAsyncAutoComplete
            v-model="updatingMenuItem.val"
            :items="availablePages"
            item-value-key="page_id"
            item-label-key="name"
            @search="fetchAvailablePages"
            @item-selected="selectPage"
          />
          <iText variant="subtle">
            This is a page created dynamically by the user
          </iText>
        </iRow>
      </iColumn>
    </template>
  </iModal>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useConfigurationStore } from "%/stores/configuration-store";
import { useFeedStore } from "@/stores/feed-store";
import { useNavigationConfigStore } from "@/stores/navigation-config-store";
import { usePostStore } from "@/stores/post-store";

export default {
  name: "PublicationNavigationConfig",
  data() {
    return {
      dropdownMenuItemIndex: -1,
      editMenuItemIndex: -1,
      isEditModal: false,
      isAddingMenuItem: false,
      updatingMenuItem: {
        label: "",
        val: "",
        type: "post",
      },
    };
  },
  computed: {
    ...mapState(useConfigurationStore, ["primaryNavigation"]),
    ...mapState(usePostStore, ["availablePosts", "isLoadingAvailablePosts"]),
    ...mapState(useNavigationConfigStore, ["availablePages", "availableFeedGroups", "isLoadingAvailablePages", "isLoadingAvailableFeedGroups"]),
    isModalOpen() {
      return this.isAddingMenuItem || this.editMenuItemIndex !== -1 ;
    },
    menuItemTypes() {
      return [
        // { name: "static page", value: "url" },
        { name: "a post", value: "post" },
        { name: "a list of posts", value: "hashtag" },
        { name: "a feed", value: "feed" },
        { name: "a page", value: "page" },
      ];
    },
    placeholderText() {
      switch (this.updatingMenuItem.type) {
        // case "url":
        //   return "/enter-url-here";
        case "hashtag":
          return "#hashtag";
        default:
          return "/";
      }
    },
    isValidNavItem() {
      switch (this.updatingMenuItem.type) {
        case "url":
          return this.isValidUrl(this.updatingMenuItem.val);
        case "hashtag":
          return this.isValidHashtag(this.updatingMenuItem.val);
        default:
          return true;
      }
    },
  },
  styleGuide: () => ({
    menuItemPaddingTop: { "size.spacing": "small" },
    menuItemPaddingBottom: { "size.spacing": "small" },
    menuItemPaddingLeft: { "size.spacing": "extraLarge" },
    menuItemPaddingRight: { "size.spacing": "small" },
  }),
  created() {
    this.fetchAvailablePosts();
    this.fetchAvailablePages();
    this.fetchAvailableFeedGroups();
  },
  methods: {
    ...mapActions(useConfigurationStore, ["updateConfiguration"]),
    ...mapActions(usePostStore, ["fetchAvailablePosts"]),
    ...mapActions(useNavigationConfigStore, ["fetchAvailablePages", "fetchAvailableFeedGroups"]),
    ...mapActions(useFeedStore, ["createFeedGroup"]),
    isValidUrl(url) {
      const result = /^((\/)([a-zA-Z0-9]+(((\/|-)[a-zA-Z0-9]+)+)?)?)$/.test(url); // only relative urls
      if (!result) {
        return false;
      }
      return true;
    },
    menuItemUrl(menuItem) {
      switch (menuItem.type) {
        case "post":
          return `/posts/post/${menuItem.val}`;
        case "hashtag":
          return `/hashtag/${menuItem.val}`;
        case "feed":
          return `/feed/${menuItem.val}`;
        case "page":
          return `/page/${menuItem.val}`;
        default:
          return menuItem.val;
      }
    },
    isValidHashtag(hashtag) {
      const result = /^(#)?[a-zA-Z0-9_]+$/.test(hashtag);
      if (!result) {
        return false;
      }
      return true;
    },
    saveMenuItem() {
      let menuItems = [...this.primaryNavigation];
      if (this.isAddingMenuItem) {
        menuItems.push(this.updatingMenuItem);
      } else {
        menuItems[this.editMenuItemIndex] = this.updatingMenuItem;
      }
      this.updatePrimaryNavigationConfig(menuItems);
      this.closeEditModal();
    },
    updatePrimaryNavigationConfig(menuItems) {
      this.updateConfiguration({ primaryNav: menuItems });
    },
    isDropdownOpen(index) {
      return this.dropdownMenuItemIndex === index;
    },
    openEditMenuForItemIndex(menuItemIndex) {
      this.isAddingMenuItem = false;
      this.editMenuItemIndex = menuItemIndex;
      this.updatingMenuItem = { ...this.primaryNavigation[this.editMenuItemIndex] };
      this.closeDropdownMenu();
    },
    openAddMenu() {
      this.isAddingMenuItem = true;
      this.closeDropdownMenu();
    },
    closeEditModal() {
      this.editMenuItemIndex = -1;
      this.isAddingMenuItem = false;
      this.updatingMenuItem = {
        label: "",
        val: 0,
        type: "post",
      };
    },
    selectPage(page) {
      this.updatingMenuItem.val = page.page_id;
    },
    selectFeedGroup(feedGroup) {
      this.updatingMenuItem.val = feedGroup.feed_group_id;
    },
    createNewFeedGroup(name) {
      this.createFeedGroup(name, "", null, () => {
        this.fetchAvailableFeedGroups();
      });
    },
    removeMenuItem(menuItemIndex) {
      let menuItems = this.primaryNavigation.filter((menuItem, index) => index !== menuItemIndex);
      this.openDropdownIndex = -1;
      this.updatePrimaryNavigationConfig(menuItems);
      this.closeDropdownMenu();
    },
    closeDropdownMenu() {
      this.dropdownMenuItemIndex = -1;
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.button-container){
  width: fit-content!important;
}
</style>
