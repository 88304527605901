import { useConfigurationStore } from "%/stores/configuration-store";
import { useGeneralStore } from "%/stores/general-store";
import { usePublicationStore } from "@/stores/publication-store";
import { defineStore } from "pinia";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { useMySubscriptionPlanFeedStore } from "SmartFeed/stores/feed/my-subscription-plan-feed-store";

export const useSubscriptionStore = defineStore("subscription-store", {
  state: () => ({
    subscriptions: [],
    subscriptionBillingHistory: [],
    subscribers: [],
    isLoadingSubscriptions: false,
    isLoadingSubscribers: false,
    isLoadingSubscriptionBillingHistory: false,
    isLoadingCreateSubscriptionPlan: false,
    isUpdatingSubscriptionPlan: false,
    isAddSubscriptionPlanModalOpen: false,
    lastSubscriptionId: 0,
    lastSubscriberId: 0,
    lastSubscriptionBillingHistoryId: 0,
    pageSize: 20,
    errorMessage: "",
  }),
  getters: {
    hasMoreSubscriptions() {
      return this.lastSubscriptionId !== -1;
    },
    hasMoreSubscriptionBillingHistory() {
      return this.lastSubscriptionBillingHistoryId !== -1;
    },
    hasMoreSubscribers() {
      return this.lastSubscriberId !== -1;
    },
    orgId() {
      return useGeneralStore().orgId;
    },
  },
  actions: {
    fetchSubscriptions() {
      if (this.hasMoreSubscriptions && !this.isLoadingSubscriptions) {
        this.isLoadingSubscriptions = true;
        this.apiGet(
          `/subscription/org/${this.orgId}`,
          {
            last_id: this.lastSubscriptionId,
            limit: this.pageSize,
          },
          response => {
            this.subscriptions = this.subscriptions.concat(
              response.subscriptionsData,
            );
            if (response.subscriptionsData.length === 0) {
              this.lastSubscriptionId = -1;
              return;
            }
            this.lastSubscriptionId =
              response.subscriptionsData.at(-1).subscription_plan_id;
          },
          () => {
            this.isLoadingSubscriptions = false;
          },
        );
      }
    },

    fetchSubscriptionBillingHistory(subscriptionId) {
      if (
        this.hasMoreSubscriptionBillingHistory &&
        !this.isLoadingSubscriptionBillingHistory
      ) {
        this.isLoadingSubscriptionBillingHistory = true;
        this.apiGet(
          `/subscription/${subscriptionId}/billing-history`,
          {
            last_id: this.lastSubscriptionBillingHistoryId,
            limit: this.pageSize,
          },
          response => {
            this.subscriptionBillingHistory =
              this.subscriptionBillingHistory.concat(
                response.subscriptionBillingHistoryData,
              );
            if (response.subscriptionBillingHistoryData.length === 0) {
              this.lastSubscriptionBillingHistoryId = -1;
              return;
            }
            this.lastSubscriptionBillingHistoryId =
              response.subscriptionBillingHistoryData.at(-1);
          },
          () => {
            this.isLoadingSubscriptionBillingHistory = false;
          },
        );
      }
    },

    fetchSubscribers(subscriptionId) {
      if (this.hasMoreSubscribers && !this.isLoadingSubscribers) {
        this.isLoadingSubscribers = true;
        this.apiGet(
          `/subscription/${subscriptionId}/subscribers`,
          {
            last_id: this.lastSubscriberId,
            limit: this.pageSize,
          },
          response => {
            this.subscribers = this.subscribers.concat(
              response.subscribersData,
            );
            if (response.subscribersData.length === 0) {
              this.lastSubscriberId = -1;
              return;
            }
            this.lastSubscriberId =
              response.subscribersData.at(-1).subscriber_id;
          },
          () => {
            this.isLoadingSubscribers = false;
          },
        );
      }
    },

    createSubscriptionProduct(subscriptionData) {
      this.apiPost(
        `/subscriptions/org/${this.orgId}`,
        { subscriptionData },
        response => {
          this.subscriptions = this.subscriptions.concat(response);
        },
      );
    },

    updateSubscriptionPlanDescription(subscriptionPlanId, description) {
      this.isUpdatingSubscriptionPlan = true;

      return this.apiPost(
        `/subscription/${subscriptionPlanId}/update/description`,
        {
          description: description,
        },
        response => {
          this.isUpdatingSubscriptionPlan = false;
          new SmartFeedEngine().addEntities(response.entities);
        },
        () => {
          this.isUpdatingSubscriptionPlan = false;
          this.error = "Failed to update subscription plan description";
        },
      );
    },

    closeAddSubscriptionPlanModal() {
      return this.isAddSubscriptionPlanModalOpen = false;
    },

    openAddSubscriptionPlanModal() {
      return this.isAddSubscriptionPlanModalOpen = true;
    },

    createSubscriptionPlan(
      name,
      description,
      billingFrequency,
      price,
      currencyCode,
      accessRevocationGracePeriod,
      exclusivityWindow,
    ) {
      this.isLoadingCreateSubscriptionPlan = true;
      const publicationStore = usePublicationStore();
      const configurationStore = useConfigurationStore();

      return this.apiPost(
        `/subscription/publication/${publicationStore.publicationId}/version/${configurationStore.publicationStagingVersionId}`,
        {
          name,
          description,
          billing_frequency: billingFrequency,
          price,
          currency_code: currencyCode,
          access_revocation_grace_period_days: accessRevocationGracePeriod,
          exclusivity_window_days: exclusivityWindow,
        },
        () => {
          this.isLoadingCreateSubscriptionPlan = false;
          this.closeAddSubscriptionPlanModal();
          useMySubscriptionPlanFeedStore().reload();
        },
        () => {
          this.isLoadingCreateSubscriptionPlan = false;
        },
      );
    },
  },
});
