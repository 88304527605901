import { defineStore } from "pinia";
import { useGeneralStore } from "%/stores/general-store";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";

export const useInspirationProStore = defineStore("inspiration-pro-store", {
  state: () => ({
    isSubscriberActionLoading: false,
    subscriberActionError: "",
  }),
  getters: {
    getUserId() {
      return useGeneralStore().userId;
    },
    getOrgId() {
      return useGeneralStore().orgId;
    },
    getCurrentPublicationId() {
      return useCreatorPublicationStore().publicationId;
    },
  },
  actions: {
    async subscribeToPro() {
      this.isSubscriberActionLoading = true;
      this.subscriberActionError = "";

      await this.apiPost(
        "/subscriber/subscribe/pro",
        {
          success_url: useGeneralStore().getRelativeHref(
            "/success",
          ),
          cancel_url: useGeneralStore().getRelativeHref(
            "/cancel",
          ),
          "publication_id": this.getCurrentPublicationId,
        },
        response => {
          this.isSubscriberActionLoading = false;
          window.location = response.checkout_session.url;
        },
        () => {
          this.isSubscriberActionLoading = false;
          this.subscriberActionError = "Failed to subscribe to pro";
        },
      );
    },
    // we can add cancel pro subscription functionality etc in here
  },
});
