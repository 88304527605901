// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comment-container[data-v-1a618de8] {
  padding: 12px;
}
.comment-container[data-v-1a618de8]:hover {
  background-color: #f5f5f5;
  border-radius: 8px;
  transition: 0.3s;
}`, "",{"version":3,"sources":["webpack://./src/SharedWeb/scripts/components/community/chatrooms/comments/CommentThread.vue","webpack://./CommentThread.vue"],"names":[],"mappings":"AAEA;EACE,aAAA;ACDF;ADGE;EACE,yBAAA;EACA,kBAAA;EACA,gBAAA;ACDJ","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.comment-container {\n  padding: 12px;\n\n  &:hover {\n    background-color: #f5f5f5;\n    border-radius: 8px;\n    transition: 0.3s;\n  }\n}\n",".comment-container {\n  padding: 12px;\n}\n.comment-container:hover {\n  background-color: #f5f5f5;\n  border-radius: 8px;\n  transition: 0.3s;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
