<template>
  <SplashWrapper
    :hide-buttons="true"
    title="Join our waitlist"
    :narrow-page="true"
  >
    <iColumn gap="extraLarge">
      <iRow>
        <iText variant="subtle">
          Register now to join our waitlist
        </iText>
      </iRow>

      <iRow>
        <iColumn gap="large">
          <iTextInput
            v-model="userAuthEmail"
            name="email"
            placeholder="Email address*"
            type="email"
            disabled
            width="fill"
            right-icon="pencil"
            @click-icon-right="goBackToEmailStep"
          />
          <iTextInput
            v-model="firstName"
            :focus="true"
            placeholder="First name*"
            width="fill"
            name="lastname"
            type="text"
          />
          <iTextInput
            ref="lastname"
            v-model="lastName"
            placeholder="Last name*"
            width="fill"
            name="lastname"
            type="text"
          />
          <iTextInput
            v-model="orgName"
            placeholder="Company name (optional)"
            name="orgName"
            width="fill"
            type="text"
          />
          <!-- This is not our preferred way of doing things-->
          <!-- This is done specifically to prevent Chromium browsers from incorrectly adding an email to the last name input -->
          <form class="password-form-wrapper">
            <iTextInput
              v-model="password"
              placeholder="Password*"
              name="password"
              width="fill"
              type="password"
              autocomplete
            />
          </form>

          <PasswordComplexity
            v-if="password"
            :password="password"
            @update:password="validatePasswordComplexity"
          />

          <iCheckbox
            v-model="confirmAge"
            required
            label="I confirm that I am 18 years or older"
            name="confirm-age"
            :disabled="!validPassword"
          />
          <iCheckbox
            v-model="acceptedTerms"
            required
            label="I agree to the terms and conditions and privacy policy"
            name="terms"
            :disabled="!confirmAge"
          />

          <iText v-if="hasError" variant="error">
            {{ signUpError }}
          </iText>
          <iButton
            :is-loading="isLoading"
            :disabled="!isValidForm"
            loading-spinner-position="inside"
            width="fill"
            variant="primary"
            @click="submitSignUp"
          >
            Continue
          </iButton>
        </iColumn>
      </iRow>
    </iColumn>
  </SplashWrapper>
</template>

<script>
import { mapWritableState, mapActions, mapState } from "pinia";
import { useUserStore } from "@/stores/user-store";
import PasswordComplexity from "@/components/PasswordComplexity";
import SplashWrapper from "@/components/splash/SplashWrapper";

export default {
  name: "SignUpForm",
  components: {
    SplashWrapper,
    PasswordComplexity,
  },
  emits: ["update:isFormValid"],
  data() {
    return {
      acceptedTerms: false,
      confirmAge: false,
      firstName: "",
      lastName: "",
      orgName: "",
      password: "",
      confirmPassword: "",
      validPassword: false,
    };
  },
  computed: {
    ...mapWritableState(useUserStore, [
      "userFlowSteps",
    ]),
    ...mapState(useUserStore, [
      "userAuthEmail",
      "signupErrorMessage",
      "isLoading",
    ]),
    isValidForm() {
      return this.firstName && this.lastName && this.acceptedTerms && this.confirmAge && this.validPassword;
    },
    hasError() {
      return this.signupErrorMessage !== "";
    },
  },
  watch: {
    password(newValue) {
      this.validatePasswordComplexity(newValue);
    },
  },
  methods: {
    ...mapActions(useUserStore, [
      "signUpUser",
    ]),
    goBackToEmailStep() {
      this.userFlowSteps.pop();
    },
    submitSignUp() {
      if (!this.isValidForm) {
        return;
      }
      this.signUpUser(this.firstName, this.lastName, this.orgName, this.userAuthEmail, this.password);
    },
    validatePasswordComplexity(value) {
      this.validPassword = value;
    },
  },
};
</script>

<style scoped lang="scss">
.password-form-wrapper {
  width: 100%;
}
</style>
