<template>
  <PageWrapper :active-section="SECTION_DIGITAL_WALLET" :active-page="PAGE_DIGITAL_WALLET_DASHBOARD">
    <iColumn>
      <iText :style-overrides="{'fontSize':'small', 'fontColor':'subtle'}">
        Our payment provider will charge a small fee for each withdrawal, which varies depending upon your withdrawal
        method and country.
        You may want to withdraw larger amounts less frequently to avoid unnecessary fees.
      </iText>
      <iButton :disabled="!canWithdrawFromDigitalWallet" :is-loading="loadingWithdrawal" @click="handleWithdrawal">
        Withdraw
      </iButton>
    </iColumn>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { SECTION_DIGITAL_WALLET, PAGE_DIGITAL_WALLET_DASHBOARD } from "@/constants/nav-constants";

import { mapActions, mapState } from "pinia";
import { usePaymentStore } from "%/stores/payments-store";

export default {
  name: "DigitalWalletDashboard",
  components: {
    PageWrapper,
  },
  data() {
    return {
      SECTION_DIGITAL_WALLET,
      PAGE_DIGITAL_WALLET_DASHBOARD,
    };
  },
  computed: {
    ...mapState(usePaymentStore, [
      "canWithdrawFromDigitalWallet",
      "loadingWithdrawal",
    ]),
  },
  methods: {
    ...mapActions(usePaymentStore, [
      "createWithdrawal",
    ]),
    handleWithdrawal() {
      this.createWithdrawal();
    },

  },
};
</script>
