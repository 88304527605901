import { useGeneralStore } from "%/stores/general-store";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";

export const useInspirationNetworkFeedStore = defineSmartFeedPiniaStore("inspiration-network-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    feed: [],
    searchQuery: "",
    metadata: {
      hasMore: true,
      lastAdvertiserName: "",
      publisherOrgId: useGeneralStore().orgId,
    },
    tableFields: [
      {
        key: "advertiserName",
        label: "Name",
      },
      {
        key: "payout",
        label: "Payout",
      },
      {
        key: "domains",
        label: "Domains",
      },
    ],
    isLoading: false,
  }),
  getters: {
    hasMore() {
      return this.metadata.hasMore;
    },
  },
  actions: {
    reload(defaultValueOverrides) {
      this.$reset();
      Object.keys(defaultValueOverrides).forEach(valueKey => {
        this[valueKey] = defaultValueOverrides[valueKey];
      });
      this.getSmartFeed();
    },
    getSmartFeed() {
      this.apiGet(
        "/partnership/inspiration/smart-feed",
        {
          last_advertiser_name: this.metadata.lastAdvertiserName,
          search_query: this.searchQuery,
          publisher_org_id: this.metadata.publisherOrgId,
        },
        data => this.handleSmartFeedResponse(data),
        () => {
          this.metadata.hasMore = false;
        },
      );
    },
  },
});

