import { DEFAULT_SPLASH_IMAGE } from "%/constants/publication-constants";

export const STORY_VARIANT_TYPES = [
  {
    label: "X",
    value: "x_post",
    description: "Variant for X",
    splash: DEFAULT_SPLASH_IMAGE,
  },
  {
    label: "LinkedIn",
    value: "linkedin_post",
    description: "Variant for LinkedIn",
    splash: DEFAULT_SPLASH_IMAGE,
  },
  {
    label: "Instagram",
    value: "instagram_post",
    description: "Variant for Instagram",
    splash: DEFAULT_SPLASH_IMAGE,
  },
  {
    label: "Another Publication",
    value: "publication_post",
    description: "Variant for Another Publication",
    splash: DEFAULT_SPLASH_IMAGE,
  },
  {
    label: "Syndication Market",
    value: "syndication_post",
    description: "Variant for Syndication Market",
    splash: DEFAULT_SPLASH_IMAGE,
  },
];
