// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-3cf321fe] .button-container {
  width: fit-content !important;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/publication/features/PublicationNavigationConfig.vue","webpack://./PublicationNavigationConfig.vue"],"names":[],"mappings":"AAEA;EACE,6BAAA;ACDF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n:deep(.button-container){\n  width: fit-content!important;\n}\n",":deep(.button-container) {\n  width: fit-content !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
