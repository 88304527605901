export const BILLING_PERIODS_PER_ANNUM = {
  "weekly": 52,
  "monthly": 12,
  "annually": 1,
};

export const BILLING_PERIODS_NOUN_TEXT = {
  "weekly": "week",
  "monthly": "month",
  "annually": "year",
};

export const STATUS_ACTIVE = "active";
export const STATUS_CANCELLED = "cancelled";
export const STATUS_PENDING = "pending";
export const STATUS_INACTIVE = "inactive";
