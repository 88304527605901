import { defineStore } from "pinia";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";


export const useNavigationConfigStore = defineStore("navigation-config-store-store", {
  state: () => ({
    availablePages: [],
    availableFeedGroups: [],
    lastPageId: 0,
    lastFeedGroupId: Number.MAX_SAFE_INTEGER,
    hasMoreAvailablePages: false,
    hasMoreAvailableFeedGroups: false,
    isLoadingFetchAvailablePages: false,
    isLoadingFetchAvailableFeedGroups: false,
  }),
  getters: {
    publicationId() {
      return useCreatorPublicationStore().publicationId;
    },
  },
  actions: {
    fetchAvailablePages() {
      this.isLoadingFetchAvailablePages = true;
      this.apiGet(`/pages/publication/${this.publicationId}`, {
        lastId: this.lastPageId,
      }, data => {
        this.availablePages = [...this.availablePages, ...data.pages];
        this.lastId = data.lastId;
        this.hasMoreAvailablePages = data.hasMore;
      }, () => {
        this.hasMoreAvailablePages = false;
      }, () => {
        this.isLoadingFetchAvailablePages = false;
      });
    },
    fetchAvailableFeedGroups() {
      this.isLoadingFetchAvailablePages = true;
      this.apiGet(`/feeds/publication/${this.publicationId}/feed-groups`, {
        last_id: this.lastFeedGroupId,
      }, data => {
        this.availableFeedGroups = [...this.availableFeedGroups, ...data.feedGroups];
        this.lastId = data.lastId;
        this.hasMoreAvailable = data.hasMore;
      }, () => {
        this.hasMoreAvailableFeedGroups = false;
      }, () => {
        this.isLoadingFetchAvailableFeedGroups = false;
      });
    },
  },
});
