<template>
  <PageWrapper
    :active-section="SECTION_COMMUNITY"
    :active-page="PAGE_COMMUNITY_PUBLICATION_COLLABORATION_ADS"
    title="Publication Collaboration Ads"
    sub-title="Find collaboration opportunities with publications"
    :breadcrumbs="breadcrumbs"
    page-name="Publication Collaboration Ads"
    align="center"
    width="full"
  >
    <iColumn v-if="!hasAds">
      <iSpace :height="1" />
      <iText variant="subtle">
        No collaboration ads found.
      </iText>
    </iColumn>
    <SmartFeed
      :pinia-store="useCommunityPublicationCollaborationAdFeedStore"
      default-entity-layout="cardSmall"
      default-smart-feed-layout="grid"
    />
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import {
  PAGE_COMMUNITY_PUBLICATION_COLLABORATION_ADS,
  SECTION_COMMUNITY,
} from "@/constants/nav-constants";
import SmartFeed from "SmartFeed/components/SmartFeed";
import { useCommunityPublicationCollaborationAdFeedStore } from "SmartFeed/stores/feed/community-publication-collaboration-ad-feed-store";

export default {
  name: "CommunityPublicationCollaborationAds",
  components: {
    PageWrapper,
    SmartFeed,
  },
  data() {
    return {
      SECTION_COMMUNITY,
      PAGE_COMMUNITY_PUBLICATION_COLLABORATION_ADS,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: "/community", label: "Community" },
      ];
    },
    hasAds() {
      const store = useCommunityPublicationCollaborationAdFeedStore();
      return store.feed && store.feed.length > 0;
    },
  },
  methods: { useCommunityPublicationCollaborationAdFeedStore },
};
</script>
