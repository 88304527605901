<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_MY_ACTIVE_PUBLICATIONS"
    title="Active Publications"
    :breadcrumbs="breadcrumbs"
    page-name="Active Publications"
    align="left"
    width="full"
    action-button-text="Create New Publication"
    @click:action="navigateToCreatePublication"
  >
    <SmartFeed
      :pinia-store="usePublicationFeedStore"
      default-entity-layout="cardSmall"
      default-smart-feed-layout="grid"
    >
      <iSubHeading>
        Active Publications
      </iSubHeading>
    </SmartFeed>
    <SmartFeed
      :pinia-store="useCollaboratingPublicationFeedStore"
      default-entity-layout="cardSmall"
      default-smart-feed-layout="grid"
    >
      <iSubHeading>
        Collaborating Publications
      </iSubHeading>
    </SmartFeed>
  </PageWrapper>
</template>

<script>
import { mapState } from "pinia";
import PageWrapper from "@/components/PageWrapper.vue";
import SmartFeed from "SmartFeed/components/SmartFeed";
import { PAGE_MY_ACTIVE_PUBLICATIONS, SECTION_PUBLICATIONS } from "@/constants/nav-constants";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import { usePublicationFeedStore } from "SmartFeed/stores/feed/publication-feed-store";
import { useCollaboratingPublicationFeedStore } from "SmartFeed/stores/feed/collaborating-publication-feed-store";

export default {
  name: "MyActivePublications",
  components: {
    SmartFeed,
    PageWrapper,
  },
  data() {
    return {
      SECTION_PUBLICATIONS,
      PAGE_MY_ACTIVE_PUBLICATIONS,
    };
  },
  computed: {
    ...mapState(useCreatorPublicationStore, [
      "orgId",
    ]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
      ];
    },
  },
  methods: {
    usePublicationFeedStore,
    useCollaboratingPublicationFeedStore,
    navigateToCreatePublication() {
      window.location = `/publication/org/${this.orgId}/create`;
    },
  },
};
</script>
