// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-items[data-v-5b589968] {
  overflow: hidden;
}
.navbar[data-v-5b589968] {
  padding: var(--5b589968-\\\$getStyles\\.navPaddingBlock) var(--5b589968-\\\$getStyles\\.navPaddingInline);
}
[data-v-5b589968] .nav-link {
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/MainNav.vue","webpack://./MainNav.vue"],"names":[],"mappings":"AAEA;EACE,gBAAA;ACDF;ADGA;EACE,mGAAA;ACAF;ADIA;EACE,mBAAA;ACDF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.nav-items{\n  overflow: hidden;\n}\n.navbar {\n  padding: v-bind('$getStyles.navPaddingBlock') v-bind('$getStyles.navPaddingInline');\n  //box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);\n}\n\n:deep(.nav-link) {\n  white-space: nowrap;\n}\n",".nav-items {\n  overflow: hidden;\n}\n\n.navbar {\n  padding: v-bind(\"$getStyles.navPaddingBlock\") v-bind(\"$getStyles.navPaddingInline\");\n}\n\n:deep(.nav-link) {\n  white-space: nowrap;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
