<template>
  <PageWrapper
    :active-section="SECTION_SETTINGS"
    :active-page="PAGE_USER_ACCOUNT_SETTINGS"
    title="User Settings"
    :breadcrumbs="settingsBreadcrumbs"
    page-name="User Settings"
    align="left"
    width="full"
  >
    <iColumn>
      <iRow>
        <iLabel>Avatar</iLabel>
      </iRow>

      <iRow vertical-align="middle">
        <AvatarImage :src="userAvatar" size="75px" />
        <iUploader @upload-file="uploadAvatar">
          <iButton>Change Picture</iButton>
        </iUploader>
      </iRow>

      <iRow />

      <iRow width="hug">
        <iColumn v-if="!isEmailConfirmed">
          <iText>
            You still need to finalize your change of email address
          </iText>
          <iParagraph>
            inspiration will not send any emails to your new address until you complete the process.
            please check your email for a message from account@activate.social with the subject:
            "Change your Email Address" and follow the instructions.
            If you having trouble finding this email, try resending, or contact support.
          </iParagraph>

          <iText>
            Old Email: <b>{{ getCurrentEmail }}</b>
          </iText>
          <iText>
            New Email: <b>{{ getNewEmail }}</b>
          </iText>
          <iButton
            variant="tertiary"
            @click="submitCancelEmailChange"
          >
            Cancel Email Change Process
          </iButton>
        </iColumn>
        <iTextEdit
          v-else
          :value="getUserData.email_data.email_address"
          label="Email"
          :width="400"
          :is-loading="isLoading"
          variant="locked"
          @submit="saveEmailChange"
        />
        <iText v-if="changeEmailError" variant="error">
          {{ changeEmailError }}
        </iText>
      </iRow>

      <iRow width="hug">
        <iTextEdit
          value="*************"
          class="hide-cursor"
          label="Password"
          :width="400"
          @click:input="showPasswordModal"
        />
      </iRow>

      <iRow width="hug">
        <iTextEdit
          label="First Name"
          :width="400"
          :value="getUserData.first_name"
          :is-loading="loadingChangeFirstName"
          @submit="submitNewFirstName"
        />
      </iRow>

      <iRow width="hug">
        <iTextEdit
          label="Last Name"
          :width="400"
          :value="getUserData.last_name"
          :is-loading="loadingChangeLastName"
          @submit="submitNewLastName"
        />
      </iRow>
    </iColumn>
    <iModal
      v-if="changePasswordModalVisible"
      title="Change Password"
      width="hug"
      @close="closePasswordModal"
      @click:primary="savePasswordChange"
    >
      <template #body>
        <iColumn gap="large" :max-width="400" width="fill">
          <iTextInput
            v-model="currentPassword"
            label="Current Password"
            type="password"
            width="fill"
          />

          <iTextInput
            v-model="newPassword"
            label="New Password"
            type="password"
            width="fill"
          />

          <PasswordComplexity
            :password="newPassword"
            @update:password="validatePasswordComplexity"
          />
        </iColumn>
      </template>
    </iModal>
  </PageWrapper>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import PageWrapper from "@/components/PageWrapper";
import PasswordComplexity from "@/components/PasswordComplexity";
import { useUserStore } from "@/stores/user-store";
import { SECTION_SETTINGS, PAGE_USER_ACCOUNT_SETTINGS } from "@/constants/nav-constants";
import { useGeneralStore } from "%/stores/general-store";
import AvatarImage from "%/components/AvatarImage";

export default {
  name: "UserAccount",
  components: {
    AvatarImage,
    PageWrapper,
    PasswordComplexity,
  },
  props: {},
  data() {
    return {
      SECTION_SETTINGS,
      PAGE_USER_ACCOUNT_SETTINGS,
      currentPassword: "",
      newPassword: "",
      validNewPassword: false,
      changePasswordModalVisible: false,
    };
  },
  computed: {
    ...mapState(useUserStore, [
      "isLoading",
      "userDataNotEdited",
      "isEditingUserData",
      "loadingChangeFirstName",
      "loadingChangeLastName",
      "userDetailsErrorMessage",
      "changeEmailError",
    ]),
    ...mapWritableState(useUserStore, [
      "editUserDetails",
      "getUserData",
      "emailStatus",
    ]),
    ...mapState(useGeneralStore, [
      "userAvatar",
      "settingsBreadcrumbs",
    ]),
    isEmailConfirmed() {
      return this.emailStatus.isEmailConfirmed;
    },
    getCurrentEmail() {
      return this.getUserData?.email_data?.email_address;
    },
    getNewEmail() {
      return this.emailStatus.requestedNewEmail;
    },
  },
  beforeMount() {
    this.fetchEmailStatus();
  },
  methods: {
    ...mapActions(useUserStore, [
      "fetchEmailStatus",
      "cancelEmailChange",
      "submitNewEmail",
      "submitNewPassword",
      "submitNewFirstName",
      "submitNewLastName",
      "uploadAvatar",
    ]),
    saveEmailChange(email) {
      this.submitNewEmail(email);
    },
    submitCancelEmailChange() {
      this.cancelEmailChange();
    },
    savePasswordChange() {
      this.submitNewPassword(this.currentPassword, this.newPassword);
    },
    updatePassword(value) {
      this.newPassword = value;
    },
    validatePasswordComplexity(value) {
      this.validNewPassword = value;
    },
    closePasswordModal() {
      this.changePasswordModalVisible = false;
      this.currentPassword = "";
      this.newPassword = "";
    },
    showPasswordModal() {
      document.activeElement.blur();
      this.changePasswordModalVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.hide-cursor {
  :deep(.i-text-input) {
    caret-color: transparent;
  }
}
</style>
