import { defineStore } from "pinia";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";

export const usePostStore = defineStore("post-store", {
  state: () => ({
    availablePosts: [],
    isLoadingAvailablePosts: false,
  }),
  getters: {
    myAvailablePosts() {
      return this.availablePosts;
    },
    publicationId() {
      return useCreatorPublicationStore().publicationId;
    },
  },
  actions: {
    async fetchAvailablePosts () {
      this.isLoadingAvailablePosts = true;
      return this.apiGet(`/publication/${this.publicationId}/posts`, {
        last_id: 0,
        limit: Number.MAX_SAFE_INTEGER,
      }, data => {
        this.availablePosts = data.posts;
      }, () => {
      }, () => {
        this.isLoadingAvaiblePosts = false;
      });
    },
  },
});
