<template>
  <iRow>
    <iSubHeading>Digital Wallet</iSubHeading>
  </iRow>
  <iRow class="side-nav">
    <iMenu orientation="vertical" :active-item="activeItem" :items="parsedDigitalWalletNav" />
  </iRow>
</template>

<script>
import { SIDE_NAV_DIGITAL_WALLET } from "@/constants/nav-constants";
import { parseUrlVars } from "@/helpers/parseUrlVars";
import { mapState } from "pinia";
import { useGeneralStore } from "%/stores/general-store";
import { filterMenuItems } from "@/helpers/capabilities-helper";

export default {
  name: "DigitalWalletSideNav",
  props: {
    activeItem: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(useGeneralStore, [
      "orgId",
    ]),
    parsedDigitalWalletNav() {
      const filteredMenuItems = filterMenuItems(SIDE_NAV_DIGITAL_WALLET);
      return parseUrlVars(filteredMenuItems, { "{org_id}": this.orgId });
    },
  },
};
</script>
