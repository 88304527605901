<template>
  <PageWrapper
    :active-page="PAGE_PUBLICATION_CHAT_ROOM"
    :active-section="SECTION_PUBLICATIONS"
    :breadcrumbs="breadcrumbs"
    page-name="Chat Room"
    align="center"
    width="full"
  >
    <iRow align="between" gap="standard" width="fill">
      <iColumn width="hug">
        <iHeading> Manage Collaborators </iHeading>
      </iColumn>
      <iColumn width="hug">
        <iLink
          :hover-underline="false"
          :href="`/publication/${publicationId}/chatroom`"
        >
          <iButton variant="secondary">
            Back to Chatroom
          </iButton>
        </iLink>
      </iColumn>
    </iRow>
    <iRow
      align="left"
      vertical-align="middle"
      gap="standard"
      width="fill"
    >
      <iColumn width="hug">
        <iRow vertical-align="middle" gap="none">
          <iColumn width="hug">
            <iCard :style-overrides="noBorder">
              <template #body>
                <iRow vertical-align="middle" gap="large">
                  <iToggleSwitch
                    size="small"
                    :model-value="isApplicationsEnabled"
                    :true-value="true"
                    :false-value="false"
                    label="Enable Applications"
                    @update:model-value="toggleApplications"
                  />
                </iRow>
              </template>
            </iCard>
          </iColumn>
          <iColumn
            v-tooltip="{
              text: 'When applications are enabled, your publication\'s profile in the creator community will invite people to apply to join your publication',
              position: 'bottom',
            }"
            width="hug"
          >
            <iIcon icon="info-circle" />
          </iColumn>
        </iRow>
      </iColumn>
      <iColumn width="hug">
        <iButton
          size="small"
          variant="secondary"
          @click="openInviteToPublicationModel"
        >
          Invite
        </iButton>
      </iColumn>
      <iColumn width="hug">
        <iButton
          size="small"
          variant="secondary"
          @click="showAdvertisementModal"
        >
          Advertise
        </iButton>
      </iColumn>
    </iRow>
    <SmartFeed
      :pinia-store="useMyPublicationCollaborationAdFeedStore"
      default-entity-layout="cardSmall"
      default-smart-feed-layout="grid"
    />

    <SmartFeed
      :pinia-store="useMyPublicationCollaboratorsFeedStore"
      default-smart-feed-layout="table"
      default-entity-layout="tableRow"
    />
    <iColumn gap="standard">
      <InviteToPublication
        :org-name="getOrgData.name"
        :org-id="getOrgData.org_id"
      />
      <iModal
        :visible="isAdvertisementModalVisible"
        :width="600"
        title="Create Advertisement"
        @close="closeAdvertisementModal"
        @click:primary="createAdvertisement"
        @click:secondary="closeAdvertisementModal"
      >
        <template #body>
          <iColumn gap="large">
            <iParagraph font-size="small">
              Ads for collaborators will appear in a special section of the
              creator community, they will also appear on your publication’s
              profile. Ads allow you to explain exactly what you are looking for
              and invites people to apply for specific roles.
            </iParagraph>
            <iSelect
              v-model="advertiseRole"
              :items="AD_ROLE_OPTIONS"
              label="Role"
              width="fill"
            />
            <iTextArea
              v-model="advertiseDescription"
              label="Description"
              placeholder="Enter role description"
              :rows="5"
              width="fill"
            />
          </iColumn>
        </template>
      </iModal>
    </iColumn>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper.vue";
import InviteToPublication from "@/components/publication/InviteToPublication.vue";
import { AD_ROLE_OPTIONS } from "@/constants/collaborator-constants";
import {
  PAGE_PUBLICATION_CHAT_ROOM,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import { useCollaborationStore } from "@/stores/collaboration-store";
import { useOrganizationStore } from "@/stores/organization-store";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import { mapActions, mapState } from "pinia";
import SmartFeed from "SmartFeed/components/SmartFeed.vue";
import { useMyPublicationCollaboratorsFeedStore } from "SmartFeed/stores/feed/use-my-publication-collaborators-feed-store";
import {
  useMyPublicationCollaborationAdFeedStore,
} from "SmartFeed/stores/feed/my-publication-collaboration-ad-feed-store";

export default {
  name: "PublicationCollaborators",
  components: {
    SmartFeed,
    InviteToPublication,
    PageWrapper,
  },
  props: {
    publicationId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      inviteEmail: "",
      advertiseRole: "",
      advertiseDescription: "",
      AD_ROLE_OPTIONS,
      SECTION_PUBLICATIONS,
      PAGE_PUBLICATION_CHAT_ROOM,
    };
  },
  computed: {
    ...mapState(useOrganizationStore, ["getOrgData"]),
    ...mapState(useCreatorPublicationStore, [
      "publication",
      "isAdvertisementModalVisible",
      "isCreatingAdvertisement",
    ]),
    noBorder() {
      return {
        borderWidth: "none",
        paddingLeft: "none",
      };
    },
    isApplicationsEnabled() {
      return this.publication.enable_applications === "enabled";
    },
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        {
          url: `/publication/${this.publicationId}`,
          label: this.publication.name,
        },
      ];
    },
  },
  methods: {
    useMyPublicationCollaboratorsFeedStore,
    useMyPublicationCollaborationAdFeedStore,
    getPublicProfileHref(application) {
      return (
        window.location.origin + `/community/org/${application.org_id}/profile`
      );
    },
    ...mapActions(useCreatorPublicationStore, [
      "enableApplications",
      "disableApplications",
      "createPublicationCollaboratorAd",
      "showAdvertisementModal",
      "closeAdvertisementModal",
    ]),
    ...mapActions(useCollaborationStore, ["openInviteToPublicationModel"]),
    async createAdvertisement() {
      if (!this.advertiseRole || !this.advertiseDescription) {
        return;
      }

      await this.createPublicationCollaboratorAd(
        this.publicationId,
        this.advertiseRole.value,
        this.advertiseDescription,
      );
      this.advertiseRole = "";
      this.advertiseDescription = "";
    },
    toggleApplications(value) {
      if (value) {
        this.enableApplications(this.publicationId);
      } else {
        this.disableApplications(this.publicationId);
      }
    },
  },
};
</script>
