<template>
  <!-- this stuff not working yet we not getting all the user data -->
  <iColumn id="create-comment" class="new-comment-container">
    <ConversationHeader
      v-if="isLoggedIn"
      :author-name="`${userData.first_name} ${userData.last_name}`"
      :author-avatar="userData.avatar_url"
      :author-id="userData.user_id"
      :moderation-enabled="moderationEnabled"
      :is-moderator="isModerator"
    />

    <iEditor
      v-model="draftMessage"
      name="comment"
      placeholder="Write a comment..."
      class="comment-input"
      @enter="addComment"
    />
    <iColumn width="hug">
      <iButton loading-spinner-position="inside" :is-loading="isMessageLoading && isMessageLoading?.newMessage" @click="addComment">
        Send Comment
      </iButton>
    </iColumn>
  </iColumn>
</template>

<script>
import { mapState, mapActions, mapWritableState } from "pinia";
import { useConversationStore } from "%/stores/conversation-store";
import ConversationHeader from "%/components/community/chatrooms/comments/ConversationHeader";
import { useGeneralStore } from "%/stores/general-store";

export default {
  name: "NewCommentMessage",
  components: {
    ConversationHeader,
  },
  props: {
    context: {
      type: String,
      required: false,
      default: "",
    },
    entityId: {
      type: Number,
      required: false,
      default: 0,
    },
    conversationId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    ...mapWritableState(useGeneralStore, ["userData", "userId", "links", "isLoggedIn"]),
    ...mapWritableState(useConversationStore, ["draftMessage"]),
    ...mapState(useConversationStore, [
      "moderationEnabled",
      "isModerator",
      "isMessageLoading",
    ]),
  },
  mounted() {
    let commentCreationData = sessionStorage.getItem("commentCreationData");
    if (commentCreationData) {
      this.draftMessage = commentCreationData;
      sessionStorage.removeItem("commentCreationData");
    }
  },
  methods: {
    ...mapActions(useConversationStore, ["sendMessage"]),
    ...mapActions(useGeneralStore, ["redirect"]),
    addComment() {
      if (!this.isLoggedIn) {
        sessionStorage.setItem("commentCreationData", this.draftMessage);
        const encodedCurrentUri = encodeURIComponent(window.location.pathname);
        window.location.href = `${this.links.web}/login?redirect=${encodedCurrentUri}`;
        return;
      }
      if (this.draftMessage === "") {
        return;
      }
      this.sendMessage(this.conversationId, this.context, this.entityId);
      this.draftMessage = "";
    },
  },
};
</script>

<style scoped lang="scss">
.comment-input {
  width: 600px!important;
  @include phone {
    min-width: unset!important;
    width: inherit!important;
  }
}

.new-comment-container {
  padding-bottom: 16px;
  border-bottom: 1px;
  border-style: inset;
  margin-bottom: 8px;
}
</style>
