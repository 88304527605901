<template>
  <PageWrapper
    :active-section="SECTION_BILLING"
    :active-page="PAGE_ACTIVE_SUBSCRIPTIONS"
    title="Active Subscriptions"
    :breadcrumbs="breadcrumbs"
    page-name="Active Subscriptions"
    align="left"
    width="full"
  >
    <SmartFeed
      :pinia-store="useActiveSubscriptionFeedStore"
      default-smart-feed-layout="table"
      default-entity-layout="tableRow"
    >
      <template #empty-state>
        <iCard width="fill" :style-overrides="{borderWidth: 'none'}">
          <template #body>
            <iColumn gap="large" align="center">
              <iColumn gap="standard" align="center">
                <iSubHeading variant="subtle">
                  No active subscriptions yet
                </iSubHeading>
                <iParagraph
                  variant="subtle"
                  text-align="center"
                  :max-width="400"
                >
                  Your active subscriptions will appear here once.
                </iParagraph>
              </iColumn>
            </iColumn>
          </template>
        </iCard>
      </template>
    </SmartFeed>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import {
  PAGE_ACTIVE_SUBSCRIPTIONS,
  SECTION_BILLING,
} from "@/constants/nav-constants";
import SmartFeed from "SmartFeed/components/SmartFeed";
import { useActiveSubscriptionFeedStore } from "SmartFeed/stores/feed/active-subscription-feed-store";

export default {
  name: "ActiveSubscriptions",
  components: {
    SmartFeed,
    PageWrapper,
  },
  data() {
    return {
      SECTION_BILLING,
      PAGE_ACTIVE_SUBSCRIPTIONS,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: "/billing", label: "Billing" },
      ];
    },
  },
  methods: { useActiveSubscriptionFeedStore },
};
</script>
