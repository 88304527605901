<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_THEME_PALETTES"
    title="Create Theme Palette"
  >
    <iColumn>
      <ThemePaletteConfiguration v-model="innerPalette" @has-errors="formHasErrors = $event" />

      <iSpace :height="10" />
      <iText v-if="formHasErrors" variant="error">
        Please correct the errors above before saving.
      </iText>
      <iRow>
        <iColumn width="hug">
          <iButton
            :is-loading="isLoadingPostPalette"
            loading-spinner-position="inside"
            :disabled="formHasErrors"
            variant="primary"
            @click="createThemePalette(innerPalette)"
          >
            Create
          </iButton>
        </iColumn>
        <iColumn width="hug">
          <iLink :href="`/publication/org/${orgId}/theme-palettes`">
            <iButton variant="secondary">
              Cancel
            </iButton>
          </iLink>
        </iColumn>
      </iRow>
    </iColumn>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { SECTION_PUBLICATIONS, PAGE_THEME_PALETTES } from "@/constants/nav-constants";
import ThemePaletteConfiguration from "@/components/ThemePaletteConfiguration";
import { mapActions, mapState } from "pinia";
import { useGeneralStore } from "%/stores/general-store";
import { useThemesStore } from "@/stores/themes-store";

export default {
  name: "ThemePalettesCreate",
  components: { ThemePaletteConfiguration, PageWrapper },
  props: {
    palette: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      SECTION_PUBLICATIONS,
      PAGE_THEME_PALETTES,
      innerPalette: this.palette,
      formHasErrors: false,
    };
  },
  computed: {
    ...mapState(useGeneralStore, ["orgId"]),
    ...mapState(useThemesStore, ["isLoadingPostPalette"]),
  },
  methods: {
    ...mapActions(useThemesStore, [
      "createThemePalette",
    ]),
  },
};
</script>

<style scoped lang="scss">

</style>
