<template>
  <iHeading> User Posting </iHeading>
  <iSelect
    :items="validUgcTypes"
    :model-value="ugcInterface"
    label="Post Creation Type"
    return-value
    value-field="value"
    @update:model-value="updateUgcInterfaceConfiguration"
  />
  <iColumn :disabled="!loginEnabledInEitherProdOrStaging" gap="small" vertical-align="middle">
    <iSelect
      :items="validCommunityTypes"
      :model-value="freeCommunityType"
      label="Community Type"
      return-value
      value-field="value"
      @update:model-value="updateFreeCommunityConfiguration"
    />
    <iRow vertical-align="middle" width="hug">
      <iIcon disable-hover icon="info-circle" />
      <iText variant="subtle">
        {{
          loginEnabledInEitherProdOrStaging ?
            "Any user will be able to chat in this community" :
            "Enable login and signup to access this feature"
        }}
      </iText>
    </iRow>
  </iColumn>

  <iColumn :disabled="!canMarkContentAsExclusive" gap="small" vertical-align="middle">
    <iSelect
      :items="validCommunityTypes"
      :model-value="exclusiveCommunityType"
      label="Exclusive Community Type"
      return-value
      value-field="value"
      @update:model-value="updateExclusiveCommunityConfiguration"
    />
    <iRow vertical-align="middle" width="hug">
      <iIcon disable-hover icon="info-circle" />
      <iText variant="subtle">
        {{
          canMarkContentAsExclusive ?
            "Only subscribers will be able to access this community" :
            "Enable subscriptions to access this feature"
        }}
      </iText>
    </iRow>
  </iColumn>
</template>

<script>
import { useConfigurationStore } from "%/stores/configuration-store";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import { mapActions, mapState } from "pinia";

export default {
  name: "PublicationUserPosting",
  computed: {
    ...mapState(useCreatorPublicationStore, [
      "canMarkContentAsExclusive",
      "loginEnabledInEitherProdOrStaging",
    ]),
    ...mapState(useConfigurationStore, ["ugcInterface", "freeCommunityType", "exclusiveCommunityType"]),
    validUgcTypes() {
      return [
        { name: "None", value: "none" },
        { name: "Article", value: "article" },
        { name: "Multimedia", value: "multimedia" },
        { name: "Quick Post", value: "quickPost" },
      ];
    },
    validCommunityTypes() {
      return [
        { name: "None", value: "none" },
        { name: "Chat", value: "chat" },
        { name: "Forum", value: "forum" },
      ];
    },
  },
  methods: {
    ...mapActions(useConfigurationStore, ["updateConfiguration"]),
    updateUgcInterfaceConfiguration(ugcType) {
      this.updateConfiguration({ ugcInterface: ugcType });
    },
    updateFreeCommunityConfiguration(communityType) {
      this.updateConfiguration({ freeCommunityType: communityType });
    },
    updateExclusiveCommunityConfiguration(communityType) {
      this.updateConfiguration({ exclusiveCommunityType: communityType });
    },
  },
};
</script>

<style scoped lang="scss"></style>
