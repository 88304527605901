<template>
  <NewCommentMessage
    :context="context"
    :entity-id="entityId"
    :conversation-id="conversationId"
  />
  <iLoadingSpinner v-if="isMessageLoading.newMessage" />
  <CommentThread
    :context="context"
    :entity-id="entityId"
    :conversation-id="conversationId"
  />
</template>

<script>
import { mapState } from "pinia";
import NewCommentMessage from "%/components/community/chatrooms/comments/NewCommentMessage";
import CommentThread from "%/components/community/chatrooms/comments/CommentThread";
import { useConversationStore } from "%/stores/conversation-store";
import { useGeneralStore } from "%/stores/general-store";

export default {
  name: "Comments",
  components: {
    CommentThread,
    NewCommentMessage,
  },
  props: {
    context: {
      type: String,
      required: true,
    },
    entityId: {
      type: Number,
      required: true,
    },
    conversationId: {
      type: Number,
      required: false,
      default: 0,
    },
    canLikeComments: {
      type: Boolean,
      required: false,
      default: false,
    },
    canReplyToComments: {
      type: Boolean,
      required: false,
      default: false,
    },
    canVoteOnComments: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapState(useConversationStore, ["conversation", "isMessageLoading"]),
    ...mapState(useGeneralStore, ["userId", "isLoggedIn"]),
  },
};
</script>

<style scoped lang="scss"></style>
