import { defineStore } from "pinia";
import { useGeneralStore } from "%/stores/general-store";
import { links } from "%/enums/link-enums";
import { $axios } from "@bloglovin/vue-framework";
import dayjs from "dayjs";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { PUBLISHING_PLATFORM_TYPE } from "%/constants/story-constants";
import { useSocialNetworksStore } from "@/stores/social-networks-store";

export const useStoryStore = defineStore("story-store", {
  state: () => ({
    story: {},
    isLoadingCreateStory: false,
    uploadStoryImageLoading: false,
    uploadStoryVideoLoading: false,
    loadingAddHashtagToStory: false,
    errorAddHashtagToStory: false,
    updateStoryExclusivityIsLoading: false,
    updateStoryInSyndicationMarketplaceIsLoading: false,
    updateStoryRequiresSyndicationApprovalContentIsLoading: false,
    updateStoryAllowRemixContentIsLoading: false,
    updateStoryTitleIsLoading: false,
    updateStoryAssignToPlatformIsLoading: false,
    updateStoryIsLoading: false,
    isCreatingStoryVariant: false,
    updateStoryIsLoadingId: undefined,
    isLikeActionLoading: false,
    isVotingActionLoading: false,
    assignToPublishingPlatformModalOpen: false,
    isPublishingStory: false,
    forcePublishAfterAssigning: false,
  }),
  getters: {
    orgId() {
      return useGeneralStore().orgId;
    },
    userId() {
      return useGeneralStore().userId;
    },
    apiV1Url() {
      return useGeneralStore().getLink(links.api);
    },
    contentApiV1() {
      return useGeneralStore().getLink(links.contentApiV1);
    },
    storySegments() {
      const segmentArray = Object.values(this.story.segments);
      return this.story.segment_order.reduce((segmentItem, order) => {
        const segment = segmentArray.find(segmentData => segmentData.story_segment_id === segmentItem.story_segment_id);
        return [
          ...order,
          segment,
        ];
      }, []);
    },
  },
  actions: {
    triggerStoryAssignModal() {
      if (this.story.publishing_platform_id && this.story.publishing_platform_id > 0) {
        return;
      }
      this.assignToPublishingPlatformModalOpen = true;
    },
    publishingStory() {
      if (!this.story.publishing_platform_id) {
        this.triggerStoryAssignModal();
        this.forcePublishAfterAssigning = true;
        return false;
      }
      this.isPublishingStory = true;
      switch (this.story.publishing_platform_type) {
        case PUBLISHING_PLATFORM_TYPE.PUBLICATION:
        case PUBLISHING_PLATFORM_TYPE.SYNDICATION_MARKETPLACE:
          this.publishStory(this.story.story_id, () => {
            this.navigateAfterPublishing();
          });
          break;
        case PUBLISHING_PLATFORM_TYPE.SOCIAL_NETWORK:
          useSocialNetworksStore().postMessage(
            this.orgId,
            this.story.publishing_platform_id,
            this.story.story_id,
            () => {
              this.navigateAfterPublishing();
            },
          );
          break;
        case PUBLISHING_PLATFORM_TYPE.EMAIL:
          this.isPublishingStory = false;
          break;
        default:
          this.isPublishingStory = false;
          break;
      }
    },
    navigateAfterPublishing() {
      if (window.location.href.includes("publication")) {
        window.location.href = `${window.location.origin}/publication/${this.story.publishing_platform_id}/stories`;
        return;
      }
      window.location.href = `${window.location.origin}/stories`;
    },
    createStory(platform = {}, successHandler = () => {}, errorHandler = () => {}) {
      this.isLoadingCreateStory = true;
      this.apiPost(`${this.apiV1Url}/story/create`,
        {
          org_id: this.orgId,
          user_id: this.userId,
          publishing_platform_id: platform.publishing_platform_id || 0,
          publishing_platform_type: platform.publishing_platform_type ?? null,
        },
        response => {
          window.location.href = `/stories/editor?story_id=${response.story_id}`;
          successHandler(response);
        },
        () => {
          errorHandler();
        },
        () => {
          this.isLoadingCreateStory = false;
        });
    },
    updateStory(storyId, data, successHandler = () => {
    }, errorHandler = () => {
    }) {
      this.updateStoryIsLoading = true;
      this.updateStoryIsLoadingId = storyId;

      this.apiPost(`${this.apiV1Url}/story/${storyId}`, {
        "story_version_id": this.story.current_story_version_id,
        "live_segment_order": data.liveSegmentOrder,
        "segments": data.segments,
      }, result => {
        this.story = {
          ...this.story,
          ...result,
        };
        this.updateStoryIsLoading = false;
        if (successHandler) {
          successHandler();
          return;
        }
        window.location.href = "/stories";
      }, () => {
        errorHandler();
      }, () => {
        this.updateStoryIsLoading = false;
        this.updateStoryIsLoadingId = undefined;
      });
    },
    async uploadStoryImage(selectedFile) {
      this.uploadStoryImageIsLoading = true;
      const formData = new FormData();
      formData.append("org_id", this.orgId);
      formData.append("image", selectedFile);

      return await $axios
          .post(`${this.contentApiV1}/story/image/upload`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(response => {
            return response.data.imageUrl;
          })
          .finally(() => {
            this.uploadStoryImageIsLoading = false;
          });
    },
    async uploadStoryVideo(selectedFile) {
      this.uploadStoryVideoLoading = true;

      const formData = new FormData();
      formData.append("org_id", this.orgId);
      formData.append("video", selectedFile);

      return await $axios
          .post(`${this.contentApiV1}/story/video/upload`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(response => {
            return response.data.videoUrl;
          })
          .finally(() => {
            this.uploadStoryVideoLoading = false;
          });
    },
    addHashtagsToStory(hashtags, storyId, errorHandler = () => {
    }, successHandler = () => {
    }) {
      this.loadingAddHashtagToStory = true;
      return $axios.post(
        `${this.contentApiV1}/story/${storyId}/tag`,
        {
          hashtags,
        },
      ).then(response => {
        this.story.timestamp_updated = dayjs().unix();
        this.story.hashtags = response.data.hashtags;
        successHandler();
      }).catch(() => {
        errorHandler();
      }).finally(() => {
        this.loadingAddHashtagToStory = false;
      });
    },
    removeHashtagFromStory(hashtagId, storyId, errorHandler = () => {
    }, successHandler = () => {
    }) {
      this.loadingAddHashtagToStory = true;
      return $axios.post(
        `${this.contentApiV1}/story/${storyId}/untag`,
        {
          hashtag_id: hashtagId,
        },
      ).then(response => {
        this.story.timestamp_updated = dayjs().unix();
        this.story.hashtags = response.data.hashtags;
        successHandler();
      }).catch(() => {
        errorHandler();
      }).finally(() => {
        this.loadingAddHashtagToStory = false;
      });
    },
    updateStoryExclusiveContentFlag(storyId, exclusive, successHandler = () => {
    }, errorHandler) {
      this.updateStoryExclusivityIsLoading = true;
      this.apiPost(`${this.contentApiV1}/story/${storyId}/exclusive`, {
        exclusive: exclusive ? 1 : 0,
      }, () => {
        this.story.exclusive = exclusive;
        this.story.timestamp_updated = dayjs().unix();
        this.updateStoryExclusivityIsLoading = false;
        successHandler(true);
      }, () => {
        errorHandler(false);
      }, () => {
        this.updateStoryExclusivityIsLoading = false;
      });
    },
    updateStoryAllowRemixContentFlag(storyId, allowRemixContent, successHandler = () => {}, errorHandler) {
      this.updateStoryAllowRemixContentIsLoading = true;
      this.apiPost(`${this.contentApiV1}/story/${storyId}/allow-remix`, {
        allow_remix: allowRemixContent ? 1 : 0,
      }, data => {
        this.story.allow_remix = allowRemixContent;
        this.story.timestamp_updated = data.timestamp_updated;
        successHandler(true);
      }, () => {
        errorHandler(false);
      }, () => {
        this.updateStoryAllowRemixContentIsLoading = false;
      });
    },
    updateStoryRequiresSyndicationApprovalContentFlag(storyId, requiresSyndicationApproval, successHandler = () => {}, errorHandler) {
      this.updateStoryRequiresSyndicationApprovalContentIsLoading = true;
      this.apiPost(`${this.contentApiV1}/story/${storyId}/requires-syndication-approval`, {
        requires_syndication_approval: requiresSyndicationApproval ? 1 : 0,
      }, data => {
        this.story.requires_syndication_approval = requiresSyndicationApproval;
        this.story.timestamp_updated = data.timestamp_updated;
        successHandler(true);
      }, () => {
        errorHandler(false);
      }, () => {
        this.updateStoryRequiresSyndicationApprovalContentIsLoading = false;
      });
    },
    updateStoryTitle(storyId, storyTitle, successHandler = () => {
    }, errorHandler = () => {
    }) {
      this.updateStoryTitleIsLoading = true;
      this.apiPost(`${this.contentApiV1}/story/${storyId}/title`, {
        title: storyTitle,
      }, data => {
        this.story.title = data.title;
        this.story.timestamp_updated = data.timestamp_updated;
        successHandler();
      }, () => {
        errorHandler();
      }, () => {
        this.updateStoryTitleIsLoading = false;
      });
    },
    assignStoryToPublishingPlatform(storyId, platformType, platformId, successHandler = () => {
    }, errorHandler = () => {
    }) {
      this.updateStoryAssignToPlatformIsLoading = true;
      this.apiPost(`${this.contentApiV1}/story/${storyId}/platform`, {
        publishing_platform_type: platformType,
        publishing_platform_id: platformId,
      }, data => {
        this.story.publishing_platform_type = platformType;
        this.story.publishing_platform_id = platformId;
        this.story.timestamp_updated = data.timestamp_updated;
        successHandler();
      }, () => {
        errorHandler();
      }, () => {
        this.updateStoryAssignToPlatformIsLoading = false;
      });
    },
    createStoryVariant(storyId, variant, successHandler = () => {
    }, errorHandler = () => {
    }) {
      this.isCreatingStoryVariant = true;

      this.apiPost(this.contentApiV1 + `/story/${storyId}/variant`,
        {
          variant,
        },
        data => {
          this.isCreatingStoryVariant = false;
          successHandler(data);
        },
        () => {
          this.isCreatingStoryVariant = false;
          errorHandler();
        });
    },
    publishStory(storyId, successHandler = () => {
    }, errorHandler = () => {
    }) {
      this.isPublishingStory = true;
      this.apiPost(`${this.apiV1Url}/story/${storyId}/publish`, {}, response => {
        new SmartFeedEngine().addEntities(response.entities);
        successHandler();
      }, () => {
        errorHandler();
      }, () => {
        this.isPublishingStory = false;
      });
    },
    unpublishStory(storyId, successHandler = () => {
    }, errorHandler = () => {
    }) {
      this.isUnpublishingStory = true;
      this.apiPost(`${this.apiV1Url}/story/${storyId}/unpublish`, {}, response => {
        new SmartFeedEngine().addEntities(response.entities);
        successHandler();
      }, () => {
        errorHandler();
      }, () => {
        this.isUnpublishingStory = false;
      });
    },
    async likeStory(storyId) {
      this.isLikeActionLoading = true;
      await this.apiPost(
        `/story/${storyId}/like`,
        {},
        response => {
          this.isLikeActionLoading = false;
          new SmartFeedEngine().addEntities(response.entities);
          this.story.like_status = response.entities.communityPost[storyId].likeStatus;
          this.story.num_likes = response.entities.communityPost[storyId].numLikes;
        },
        () => {
        },
        () => {
          this.isLikeActionLoading = false;
        },
      );
    },
    async unlikeStory(storyId) {
      this.isLikeActionLoading = true;
      await this.apiPost(
        `/story/${storyId}/unlike`,
        {},
        response => {
          this.isLikeActionLoading = false;
          new SmartFeedEngine().addEntities(response.entities);
          // intentionally not reloading this store so the UI does not abruptly change
          // to give the user a chance to undo the action in case it was done on accident
          // useLovedPostFeedStore().reload();
          this.story.like_status = response.entities.communityPost[storyId].likeStatus;
          this.story.num_likes = response.entities.communityPost[storyId].numLikes;
        },
        () => {
          this.isLikeActionLoading = false;
        },
      );
    },
    async upVoteStory(storyId) {
      this.isVotingActionLoading = true;
      await this.apiPost(
        `/story/${storyId}/upvote`,
        {},
        response => {
          this.isVotingActionLoading = false;
          new SmartFeedEngine().addEntities(response.entities);
          this.story.vote_status = response.entities.communityPost[storyId].voteStatus;
          this.story.vote_score = response.entities.communityPost[storyId].voteScore;
        },
        () => {
        },
        () => {
          this.isVotingActionLoading = false;
        },
      );
    },
    async downVoteStory(storyId) {
      this.isVotingActionLoading = true;
      await this.apiPost(
        `/story/${storyId}/downvote`,
        {},
        response => {
          this.isVotingActionLoading = false;
          new SmartFeedEngine().addEntities(response.entities);
          this.story.vote_status = response.entities.communityPost[storyId].voteStatus;
          this.story.vote_score = response.entities.communityPost[storyId].voteScore;
        },
        () => {
        },
        () => {
          this.isVotingActionLoading = false;
        },
      );
    },
    removeVoteStory(storyId) {
      this.isVotingActionLoading = true;
      return this.apiPost(
        `/story/${storyId}/remove-vote`,
        {},
        response => {
          this.isVotingActionLoading = false;
          new SmartFeedEngine().addEntities(response.entities);
          this.story.vote_status = response.entities.communityPost[storyId].voteStatus;
          this.story.vote_score = response.entities.communityPost[storyId].voteScore;
        },
        () => {
        },
        () => {
          this.isVotingActionLoading = false;
        },
      );
    },
  },
});
