<template>
  <FeatureConfig />
  <iColumn height="fill" class="page-wrapper" gap="none">
    <iRow
      class="nav-container"
      :height="80"
      vertical-align="middle"
      wrap="nowrap"
    >
      <iIcon
        v-if="screenWidth < 768 && currentSideNav"
        class="mobile-menu-trigger"
        :icon="isSideBarOpen ? 'close' : 'hamburger'"
        size="large"
        @click="toggleSidebar"
      />
      <MainNav :active-item="activeSection" />
    </iRow>
    <iRow
      :vertical-align="verticalAlign"
      :align="myPrimaryAlignment"
      wrap="nowrap"
      class="site-content"
      height="fill"
      overflow="scroll"
    >
      <iColumn
        v-if="currentSideNav && showSideBar"
        v-click-outside="hideSideBar"
        :width="300"
        height="fill"
        class="side-navigation"
      >
        <component :is="currentSideNav" :active-item="activePage" />
      </iColumn>
      <iColumn
        :height="contentHeight"
        class="page-wrapper-content reactive-width"
        :align="myPrimaryAlignment"
        :vertical-align="verticalAlign"
        :width="myPrimaryWidth"
        wrap="nowrap"
        overflow="scroll"
      >
        <iColumn class="page-wrapper-content-header">
          <iRow v-if="breadcrumbs">
            <iBreadcrumbs
              :links="breadcrumbs"
              :current-page="pageName"
            />
          </iRow>
          <iRow v-if="title" align="between">
            <iColumn width="hug" gap="none">
              <iHeading>
                {{ title }}
              </iHeading>
              <iText v-if="subTitle && subTitleType === 'iText'" font-size="standard">
                {{ subTitle }}
              </iText>
            </iColumn>
            <iColumn
              v-if="actionButtonText"
              width="hug"
              height="fill"
              vertical-align="middle"
            >
              <iLink
                v-if="actionButtonHref"
                :href="actionButtonHref"
              >
                <iButton width="hug" variant="secondary">
                  {{ actionButtonText }}
                </iButton>
              </iLink>
              <iButton
                v-else
                width="hug"
                variant="secondary"
                @click="emitActionClick"
              >
                {{ actionButtonText }}
              </iButton>
            </iColumn>
          </iRow>
          <iRow v-if="subTitle && subTitleType === 'iSubHeading'">
            <iSubHeading>{{ subTitle }}</iSubHeading>
          </iRow>
        </iColumn>
        <iColumn
          height="fill"
          wrap="nowrap"
          class="primary-content"
          overflow="scroll"
        >
          <slot />
        </iColumn>
      </iColumn>
    </iRow>
  </iColumn>
</template>

<script>
import { useGeneralStore } from "%/stores/general-store";
import MainNav from "@/components/MainNav";
import BillingSideNav from "@/components/side-nav/BillingSideNav";
import CommunitySideNav from "@/components/side-nav/CommunitySideNav";
import DigitalWalletSideNav from "@/components/side-nav/DigitalWalletSideNav";
import MonetizationSideNav from "@/components/side-nav/MonetizationSideNav";
import PublicationSideNav from "@/components/side-nav/PublicationSideNav";
import SettingsSideNav from "@/components/side-nav/SettingsSideNav";
import {
  SECTION_BILLING,
  SECTION_COMMUNITY,
  SECTION_DIGITAL_WALLET,
  SECTION_HOME,
  SECTION_MONETIZATION,
  SECTION_PUBLICATIONS,
  SECTION_SETTINGS,
} from "@/constants/nav-constants";
import { Enum } from "@bloglovin/vue-component-library";
import { mapState } from "pinia";
import FeatureConfig from "%/components/FeatureConfig.vue";

export default {
  name: "PageWrapper",
  components: {
    FeatureConfig,
    MainNav,
    PublicationSideNav,
    MonetizationSideNav,
    DigitalWalletSideNav,
    SettingsSideNav,
    CommunitySideNav,
    BillingSideNav,
  },
  props: {
    activePage: {
      type: String,
      required: true,
    },
    activeSection: {
      type: String,
      required: false,
      default: null,
    },
    align: {
      type: Enum,
      required: false,
      default: "left",
      options: ["left", "center"],
    },
    stickySideNav: {
      type: Boolean,
      required: false,
      default: true,
    },
    verticalAlign: {
      type: String,
      required: false,
      default: "top",
      options: ["top", "middle", "bottom"],
    },
    contentHeight: {
      type: Enum,
      required: false,
      default: "fill",
      options: ["fill", "hug"],
    },
    width: {
      type: Enum,
      required: false,
      default: "full",
      options: ["full", "fixed"],
    },
    breadcrumbs: {
      type: Array,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    subTitle: {
      type: String,
      required: false,
      default: "",
    },
    subTitleType: {
      type: Enum,
      required: false,
      default: "iSubHeading",
      options: ["iSubHeading", "iText"],
    },
    pageName: {
      type: String,
      required: false,
      default: "",
    },
    actionButtonText: {
      type: String,
      required: false,
      default: "",
    },
    actionButtonHref: {
      type: String,
      required: false,
      default: "",
    },
  },
  emits: ["click:action"],
  data() {
    return {
      SECTION_HOME,
      SECTION_PUBLICATIONS,
      SECTION_MONETIZATION,
      SECTION_DIGITAL_WALLET,
      SECTION_SETTINGS,
      SECTION_COMMUNITY,
      SECTION_BILLING,
      isSideBarOpen: false,
      screenWidth: window.innerWidth,
    };
  },
  styleGuide: () => ({
    contentPaddingTop: { "size.spacing": "small" },
    contentPaddingBottom: { "size.spacing": "large" },
    contentPaddingLeft: { "size.spacing": "standard" },
    contentPaddingRight: { "size.spacing": "standard" },
    sidebarPaddingTop: { "size.spacing": "small" },
  }),
  computed: {
    ...mapState(useGeneralStore, ["getOrgId", "getUserId"]),
    myPrimaryAlignment() {
      return this.align;
    },
    myPrimaryWidth() {
      if (this.width === "fixed") {
        return 1200;
      }
      return "fill";
    },
    currentSideNav() {
      switch (this.activeSection) {
        case SECTION_COMMUNITY:
          return CommunitySideNav;
        case SECTION_DIGITAL_WALLET:
          return DigitalWalletSideNav;
        case SECTION_MONETIZATION:
          return MonetizationSideNav;
        case SECTION_PUBLICATIONS:
          return PublicationSideNav;
        case SECTION_SETTINGS:
          return SettingsSideNav;
        case SECTION_BILLING:
          return BillingSideNav;
      }
      return "";
    },
    showSideBar() {
      return this.screenWidth > 768 || this.isSideBarOpen;
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  methods: {
    toggleSidebar() {
      this.isSideBarOpen = !this.isSideBarOpen;
    },
    onResize() {
      this.screenWidth = window.innerWidth;
    },
    hideSideBar() {
      this.isSideBarOpen = false;
    },
    emitActionClick(event) {
      this.$emit("click:action", event);
    },
  },
};
</script>

<style scoped lang="scss">
	.page-wrapper {
		height: 100%;

		&-content {
			height: 100%;

			padding-top: v-bind("$getStyles.contentPaddingTop");
			padding-bottom: v-bind("$getStyles.contentPaddingBottom");
			padding-left: v-bind("$getStyles.contentPaddingLeft");
			padding-right: v-bind("$getStyles.contentPaddingRight");
		}
	}

	.nav-container {
		position: sticky;
		top: 0;
		z-index: 1;
		background-color: white;
		max-width: 1515px;
		margin-inline: auto;
	}

	.hamburger {
		@include tablet {
			display: none;
		}

		@include desktop {
			display: none;
		}
	}

	.side-navigation {
		padding-top: v-bind("$getStyles.sidebarPaddingTop");
		padding-left: 20px;

		@include phone {
			position: absolute;
			z-index: 3;
			background-color: #ffffff;
			border: #e7e5eb 1px solid;
			border-radius: 8px;
			padding-left: 10px;
		}
	}

	.site-content {
		max-height: calc(100vh - 80px); // fill all available space after nav
		max-width: 1515px;
		margin-inline: auto;

		@include phone {
			position: relative;
		}
	}

	.mobile-menu-trigger {
		padding-left: 10px;
	}

	:deep(.reactive-width.col.width-fixed) {
		min-width: unset;
	}
</style>
