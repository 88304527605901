<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_USERS"
    title="My Users"
    sub-title="Manage your users"
    :breadcrumbs="breadcrumbs"
    page-name="My Users"
    align="center"
    width="full"
  >
    <SmartFeed :pinia-store="usePublicationUsersFeedStore" default-entity-layout="tableRow" default-smart-feed-layout="table">
      <template #empty-state>
        <iRow vertical-align="middle" class="empty-state">
          <iIcon icon="person" size="large" />
          <iText>No users yet.</iText>
        </iRow>
      </template>
    </SmartFeed>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper.vue";
import { mapState } from "pinia";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import {
  PAGE_PUBLICATION_USERS,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import SmartFeed from "SmartFeed/components/SmartFeed";
import { usePublicationUsersFeedStore } from "SmartFeed/stores/feed/publication-users-feed-store";

export default {
  name: "PublicationUserManagement",
  components: { SmartFeed, PageWrapper },
  data() {
    return {
      PAGE_PUBLICATION_USERS,
      SECTION_PUBLICATIONS,
    };
  },
  computed: {
    ...mapState(useCreatorPublicationStore, ["orgId", "publication", "posts", "isLoading", "publicationId"]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
      ];
    },
  },
  methods: { usePublicationUsersFeedStore },
};
</script>

<style scoped lang="scss">

</style>
