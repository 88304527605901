import { DEFAULT_ORG_LOGO, DEFAULT_SPLASH_IMAGE } from "%/constants/publication-constants";
import { links } from "%/enums/link-enums";
import { useGeneralStore } from "%/stores/general-store";
import { usePaymentStore } from "%/stores/payments-store";
import { $axios } from "@bloglovin/vue-framework";
import { defineStore } from "pinia";
import dayjs from "dayjs";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";

export const useCreatorPublicationStore = defineStore("publication-store", {
  state: () => ({
    posts: [],
    post: undefined,
    updatePostIsLoading: false,
    updatePostIsLoadingId: undefined,
    postViewType: "",
    myPublications: [],
    communityPublications: [],
    communityPublicationsLoading: false,
    lastCommunityPublicationId: 0,
    hasMoreCommunityPublications: false,
    isCreatingStoryVariant: false,
    publicationId: 0,
    selectedTemplate: {},
    publication: {},
    publicationVersion: {},
    publicationVersionId: 0,
    isLoadingPosts: false,
    hasMorePosts: false,
    nativePost: { post: {}, loading: false },
    isLoading: false,
    isLoadingConfig: false,
    isLoadingUpdatePublicationTheme: false,
    lastPublicationId: 0,
    lastPostId: 0,
    isInviteOrgToPublicationModalVisible: false,
    isCollaborateModalVisible: false,
    lastInvitationId: 0,
    lastApplicationId: 0,
    collaboratingPublications: [],
    collaboratingPublicationLastId: 0,
    orgProfilePublications: [],
    orgProfilePublicationLastId: 0,
    loadingUpdateDomain: false,
    errorUpdateDomain: "",
    loadingUploadPublicationLogo: false,
    loadingUploadSplashImage: false,
    errorUploadPublicationLogo: "",
    uploadPostImageLoading: false,
    versionComparison: {},
    canMarkContentAsExclusive: false,
    loginEnabledInEitherProdOrStaging: false,
    updatePostExclusivityIsLoading: false,
    updatePostInSyndicationMarketplaceIsLoading: false,
    updatePostTitleIsLoading: false,
    loadingUpdateSubscriptionPlan: false,
    activeSubscriptionPlans: [],
    isUploadingLogo: false,
    isUploadingSplash: false,
    isLoadingCreatePublication: false,
    isLoadingUpdatePublicationStatus: false,
    createPostIsLoading: false,
    validBillingFrequencies: [],
    isProTier: false,
  }),
  getters: {
    getAppId() {
      return useGeneralStore().appId;
    },
    contentApiV1() {
      return useGeneralStore().getLink(links.contentApiV1);
    },
    creatorApiV1() {
      return useGeneralStore().getLink(links.api);
    },
    getNativePostContent() {
      return this.nativePost.post.content_split;
    },
    getNativePostTitle() {
      return this.nativePost.post.title;
    },
    getNativePostTags() {
      return this.nativePost.post.post_tags;
    },
    isNativePostLoading() {
      return this.nativePost.loading;
    },
    coreAppApiUrl() {
      return useGeneralStore().getLink(links.coreAppApi);
    },
    orgId() {
      return useGeneralStore().orgId;
    },
    publicationLogo() {
      return this.publication.publication_logo || DEFAULT_ORG_LOGO;
    },
    publicationSplashImage() {
      return this.publication.publication_splash_image || DEFAULT_SPLASH_IMAGE;
    },
    isSignupAndLoginEnabled() {
      return this.configuration.signup_and_login.enabled;
    },
    publicationThemeId() {
      return this.publicationVersion?.theme_id;
    },
    publicationName() {
      return this.publication.name;
    },
    publicationDescription() {
      return this.publication.description ?? "";
    },
    myActivePublications() {
      return this.myPublications.filter(publication => publication.status == "active");
    },
    myDraftPublications() {
      return this.myPublications.filter(publication => publication.status == "draft");
    },
    myCollaboratingPublications() {
      return this.collaboratingPublications;
    },
    myAvailablePublications() {
      return this.myPublications.filter(publication => publication.status !== "inactive");
    },
    myInactivePublications() {
      return this.myPublications.filter(publication => publication.status == "inactive");
    },
  },
  actions: {
    fetchMorePosts() {
      if (this.isLoadingPosts || !this.hasMorePosts) {
        return;
      }
      this.isLoadingPosts = true;
      this.apiGet(
        `/publication/org/${this.orgId}/posts`,
        {
          last_id: this.lastPostId,
          limit: 12,
        },
        response => {
          const newPosts = response.data.posts;
          if (newPosts.length > 0) {
            this.posts = [...this.posts, ...newPosts];
            this.lastPostId = newPosts[newPosts.length - 1].post_id;
            this.hasMorePosts = response.data.hasMore;
          } else {
            this.lastPostId = -1;
          }
        },
        () => {
          this.isLoadingPosts = false;
          this.hasMorePosts = false;
        },
      );
    },
    fetchPublications() {
      this.isLoading = true;
      $axios
          .get(
            `/publication/org/${this.orgId}/?last_id=${this.lastPublicationId}`,
          )
          .then(response => {
            const newPublications = response.data.publicationData;
            if (newPublications.length > 0) {
              this.myPublications = [...this.myPublications, ...newPublications];
              this.lastPublicationId =
              newPublications[newPublications.length - 1].publication_id;
            } else {
              this.lastPublicationId = -1;
            }
          })
          .catch(() => {
            this.isLoading = false;
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    fetchPublication(publicationId) {
      $axios
          .get(`/publication/${publicationId}`)
          .then(response => {
            this.publication = response.data.publicationData;
            return this.publication;
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    createPublication(name, description, publicationTemplateId, theme = 0) {
      this.isLoadingCreatePublication = true;
      this.apiPost(`/publication/org/${this.orgId}`, {
        name,
        description,
        publication_template_id: publicationTemplateId,
        theme,
      }, data => {
        if (data.publicationId) {
          window.location = `/publication/${data.publicationId}`;
        }
      }, () => {
      }, () => {
        this.isLoadingCreatePublication = false;
      });
    },
    updatePublicationSubscription(
      subscriptionPlanId,
      name,
      description,
      enabled,
    ) {
      this.loadingUpdateSubscriptionPlan = true;
      return $axios
          .put(
            `/subscription/publication/${this.publicationId}/version/${this.publicationVersionId}`,
            {
              subscription_plan_id: subscriptionPlanId,
              name,
              description,
              enabled,
            },
          )
          .then(response => {
            let data = response.data;
            this.setPublicationId(data.publicationVersionData.publication_id);
            this.setPublicationVersionId(data.publicationVersionData.publication_version_id);

            this.subscriptionPlans = data.subscriptionPlans;
          })
          .finally(() => {
            this.loadingUpdateSubscriptionPlan = false;
          });
    },
    createSubscription(
      name,
      description,
      billingFrequency,
      price,
      currencyCode,
      accessRevocationGracePeriod,
      exclusivityWindow,
      enabled = false,
    ) {
      this.isLoading = true;
      const paymentStore = usePaymentStore();
      const generalStore = useGeneralStore();

      return $axios
          .post(
            `/subscription/publication/${this.publicationId}/version/${this.publicationVersionId}`,
            {
              name,
              description,
              billing_frequency: billingFrequency,
              price,
              currency_code: currencyCode,
              access_revocation_grace_period_days: accessRevocationGracePeriod,
              exclusivity_window_days: exclusivityWindow,
              enabled,
            },
          )
          .then(async response => {
            let data = response.data;
            this.setPublicationId(data.publicationVersionData.publication_id);
            this.setPublicationVersionId(data.publicationVersionData.publication_version_id);
            this.subscriptionPlans = data.subscriptionPlans;

            const newPlan =
            data.subscriptionPlans[data.subscriptionPlans.length - 1];

            await paymentStore.createSubscriptionProductAndPrice(
              newPlan.name,
              newPlan.description,
              newPlan.price * 100,
              newPlan.currency_code,
              newPlan.billing_frequency,
              newPlan.subscription_plan_id,
              generalStore.userId,
              generalStore.orgId,
              newPlan.publication_id,
              generalStore.orgId,
              true,
            );

            return data;
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    fetchNativePostData(blogId, postId) {
      this.nativePost.loading = true;
      $axios
          .get(
            `${this.coreAppApiUrl}/v2/posts/info_from_post_ids?blog_id=${blogId}&post_id=${postId}`,
          )
          .then(response => {
            this.nativePost.post =
            response?.data?.meta?.resolved?.post[`${postId}:${blogId}`];
          })
          .finally(() => {
            this.nativePost.loading = false;
          });
    },
    setPublication(data) {
      this.setPublicationId(data.publication.publication_id);
      this.setPublicationVersionId(data.publicationVersionId);
      this.publicationVersion = data.publicationVersion;
      this.publication = data.publication;

      this.setVersionComparisonFields(data.versionComparison);
    },
    setActiveSubscriptionPlans(activeSubscriptionPlans) {
      this.activeSubscriptionPlans = activeSubscriptionPlans;
    },
    setPublicationId(publicationId) {
      this.publicationId = publicationId;
    },
    setPublicationVersionId(publicationVersionId) {
      this.publicationVersionId = publicationVersionId;
    },
    setVersionComparisonFields(versionComparison) {
      this.versionComparison = versionComparison;
      if (Object.keys(this.versionComparison).length === 0) {
        this.canMarkContentAsExclusive = false;
      } else {
        this.subscriptionsEnabled = this.versionComparison.subscriptions.staging.length ||
            this.versionComparison.subscriptions.production.length;
        this.loginEnabled = this.versionComparison.login.staging || this.versionComparison.login.production;
        this.canMarkContentAsExclusive = this.subscriptionsEnabled || this.loginEnabled;
        this.loginEnabledInEitherProdOrStaging = this.versionComparison.login.staging || this.versionComparison.login.production;
      }
    },
    setMyPublications(myPublications) {
      this.myPublications = myPublications;
    },
    setPosts(posts) {
      this.posts = posts;
    },
    async uploadPostImage(selectedFile) {
      this.uploadPostImageLoading = true;

      var formData = new FormData();
      formData.append("org_id", this.orgId);
      formData.append("publication_id", this.publicationId);
      formData.append("image", selectedFile);

      return await $axios
          .post(`${this.contentApiV1}/post/image/upload`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(response => {
            return response.data.imageUrl;
          })
          .finally(() => {
            this.uploadPostImageLoading = false;
          });
    },
    async createPublicationPost() {
      this.createPostIsLoading = true;
      const data = {
        publication_id: this.publicationId,
        org_id: this.orgId,
        post_title: "",
        post_content: "",
        post_hashtags: [],
        exclusive: 0,
      };
      await this.apiPost(
        `${this.creatorApiV1}/publication/${this.publicationId}/post`,
        {
          data,
        },
        response => {
          window.location.href = `/publication/${this.publicationId}/post/${response.post_id}?view_type=edit`;
        },
        () => {
        },
      );

      this.createPostIsLoading = false;
    },
    updatePostExclusiveContentFlag(postId, exclusive, successHandler = () => {}, errorHandler) {
      this.updatePostExclusivityIsLoading = true;
      this.updatePostIsLoading = true;
      this.apiPost(`${this.contentApiV1}/post/${postId}/update/exclusive`, {
        exclusive: exclusive ? 1 : 0,
      }, () => {
        this.post.exclusive = exclusive;
        this.post.timestamp_updated = dayjs().unix();
        this.updatePostExclusivityIsLoading = false;
        this.updatePostIsLoading = false;
        successHandler(true);
      }, () => {
        this.updatePostExclusivityIsLoading = false;
        errorHandler(false);
      });
    },
    updatePostTitle(postId, postTitle, successHandler = () => {}, errorHandler = () => {}) {
      this.updatePostTitleIsLoading = true;
      this.updatePostIsLoading = true;
      this.apiPost(`${this.contentApiV1}/post/${postId}/update/title`, {
        post_title: postTitle,
      }, data => {
        this.post.post_title = postTitle;
        this.post.timestamp_updated = data.timestamp_updated;
        this.updatePostTitleIsLoading = false;
        this.updatePostIsLoading = false;
        successHandler();
      }, () => {
        this.updatePostTitleIsLoading = false;
        this.updatePostIsLoading = false;
        errorHandler();
      });
    },
    createPost() {
      this.createPostIsLoading = true;
      const data = {
        publication_id: 0,
        org_id: this.orgId,
        post_title: "",
        post_content: "",
        post_hashtags: [],
        exclusive: 0,
      };
      this.apiPost(
        "/publication/0/post",
        {
          data,
        },
        data => {
          window.location.href = `/stories/editor?story_id=${data.post_id}`;
          this.createPostIsLoading = false;
        },
        () => {
          this.createPostIsLoading = false;
        },
      );
    },
    createStoryVariant(postId, variant, successHandler = () => {}, errorHandler = () => {}) {
      this.isCreatingStoryVariant = true;

      this.apiPost(
        `/post/${postId}/variant`,
        {
          variant,
        },
        data => {
          this.isCreatingStoryVariant = false;
          successHandler(data);
        },
        () => {
          this.isCreatingStoryVariant = false;
          errorHandler();
        },
      );
    },
    updatePost(postId, title, html, status, successHandler = () => {}, errorHandler = () => {}) {
      this.updatePostIsLoading = true;
      this.updatePostIsLoadingId = postId;

      const data = {
        post_title: title,
        post_content: html,
        status: status ?? this.post.status,
      };

      this.apiPost(`/post/${postId}/update`, {
        data,
      }, () => {
        this.post.timestamp_updated = dayjs().unix();
        this.post.post_title = data.title;
        this.post.status = data.status;
        this.updatePostIsLoading = false;
        if (successHandler) {
          successHandler();
          return;
        }
        window.location.href = "/stories";
      }, () => {
        this.updatePostIsLoading = false;
        this.updatePostIsLoadingId = undefined;
        errorHandler();
      });
    },
    assignPostToPublication(postId, publicationId, successHandler = () => {}, errorHandler = () => {}) {
      this.updatePostIsLoading = true;
      this.apiPost(`/post/${postId}/assign`, {
        publication_id: publicationId,
      }, () => {
        this.updatePostIsLoading = false;
        successHandler();
      }, () => {
        this.updatePostIsLoading = false;
        errorHandler();
      });
    },
    updatePublicationPost(postId, title, html, status, callback) {
      this.updatePostIsLoading = true;
      this.updatePostIsLoadingId = postId;

      const data = {
        publication_id: this.publicationId,
        post_title: title,
        post_content: html,
        status: status ?? this.post.status,
      };

      this.apiPost(`${this.creatorApiV1}/publication/${this.publicationId}/post/${postId}`, {
        data,
      }, response => {
        this.updatePostIsLoading = false;
        if (callback) {
          this.post = response;
          return callback();
        }
        window.location.href = `/publication/${this.publicationId}/posts`;
        callback(true);
      }, () => {
        this.updatePostIsLoading = false;
        this.updatePostIsLoadingId = undefined;
        callback(false);
      });
    },
    updatePublicationPostStatus(postId, status, callback) {
      this.updatePostIsLoading = true;
      this.updatePostIsLoadingId = postId;

      this.apiPost(`${this.creatorApiV1}/publication/${this.publicationId}/post/${postId}/status`, {
        status: status ?? this.post.status,
      }, response => {
        this.updatePostIsLoading = false;
        if (callback) {
          new SmartFeedEngine().addEntities(response.entities);
          return callback();
        }
        window.location.href = `/publication/${this.publicationId}/posts`;
        callback(true);
      }, () => {
        this.updatePostIsLoading = false;
        this.updatePostIsLoadingId = undefined;
        callback(false);
      });
    },
    setPost(post) {
      this.post = post;
    },
    fetchApplicationsForPublication(publicationId) {
      this.isLoadingCollaborators = true;
      return $axios
          .get(`/collaboration/publication/${publicationId}/applications`, {
            params: {
              last_id: this.lastApplicationId,
              limit: 10,
            },
          })
          .then(response => {
            const newApplications = response.data.applications;
            if (newApplications.length > 0) {
              this.collaboratorApplications = [
                ...this.collaboratorApplications,
                ...newApplications,
              ];
              this.lastApplicationId =
              newApplications[
                  newApplications.length - 1
              ].publication_applicant_id;
            } else {
              this.lastApplicationId = -1;
            }
            return response.data;
          })
          .finally(() => {
            this.isLoadingCollaborators = false;
          });
    },
    enableApplications(publicationId) {
      this.isLoading = true;
      return $axios
          .post(`/publication/${publicationId}/applications/enable`)
          .then(() => {
            this.publication.enable_applications = true;
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    disableApplications(publicationId) {
      this.isLoading = true;
      return $axios
          .post(`/publication/${publicationId}/applications/disable`)
          .then(() => {
            this.publication.enable_applications = false;
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    saveDomain(domain) {
      this.loadingUpdateDomain = true;

      $axios
          .put(`/publication/${this.publicationId}/domain`, {
            domain,
          })
          .then(response => {
            this.publication = response.data.publicationData;
          })
          .catch(() => {
            this.errorUpdateDomain = "Failed to update the domain";
          })
          .finally(() => {
            this.loadingUpdateDomain = false;
          });
    },
    getCommunityPublications() {
      if (
        this.communityPublicationsLoading ||
        !this.hasMoreCommunityPublications
      ) {
        return;
      }

      this.communityPublicationsLoading = true;
      return $axios
          .get("/publication/public/data", {
            params: {
              last_id: this.lastCommunityPublicationId,
            },
          })
          .then(response => {
            this.communityPublications = [
              ...this.communityPublications,
              ...response.data.publications,
            ];
            this.lastCommunityPublicationId = response.data.nextLastId;
            this.hasMoreCommunityPublications = response.data.hasMore;
          })
          .finally(() => {
            this.communityPublicationsLoading = false;
          });
    },
    updatePublicationTheme(themeId) {
      this.isLoadingUpdatePublicationTheme = true;
      return this.apiPost(`/publication/${this.publicationId}/theme`, {
        theme_id: themeId,
      }, data => {
        this.publicationVersion = data.publicationVersionData;
      }, () => {
      }, () => {
        this.isLoadingUpdatePublicationTheme = false;
      });
    },
    uploadPublicationLogo(uploadedPublicationLogo) {
      this.loadingUploadPublicationLogo = true;
      let formData = new FormData();
      formData.append("uploaded_publication_logo", uploadedPublicationLogo);
      return $axios
          .post(`/publication/${this.publicationId}/logo`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(response => {
            this.publication = {
              ...this.publication,
              publication_logo: response.data.publicationData.publication_logo,
            };
          })
          .catch(() => {
            this.errorUploadPublicationLogo =
            "There was a problem uploading the logo";
          })
          .finally(() => {
            this.loadingUploadPublicationLogo = false;
          });
    },
    uploadSplashImage(uploadedSplashImage) {
      this.loadingUploadSplashImage = true;
      let formData = new FormData();
      formData.append("uploaded_publication_splash_image", uploadedSplashImage);
      return $axios
          .post(`/publication/${this.publicationId}/splash-image`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(response => {
            this.publication = {
              ...this.publication,
              publication_splash_image: response.data.publicationData.publication_splash_image,
            };
          })
          .finally(() => {
            this.loadingUploadSplashImage = false;
          });
    },
    addHashtagsToPost(hashtags, postId, errorHandler = () => {}, successHandler = () => {}) {
      this.updatePostIsLoading = true;
      return $axios.post(
        `${this.contentApiV1}/post/${postId}/tag`,
        {
          hashtags,
        },
      ).then(response => {
        this.post.timestamp_updated = dayjs().unix();
        this.post.hashtags = response.data.hashtags;
        this.updatePostIsLoading = false;
        successHandler();
      }).catch(() => {
        errorHandler();
      });
    },
    removeHashtagFromPost(hashtagId, postId, errorHandler = () => {}, successHandler = () => {}) {
      this.updatePostIsLoading = true;
      return $axios.post(
        `${this.contentApiV1}/post/${postId}/untag`,
        {
          hashtag_id: hashtagId,
        },
      ).then(response => {
        this.post.timestamp_updated = dayjs().unix();
        this.post.hashtags = response.data.hashtags;
        this.updatePostIsLoading = false;
        successHandler();
      }).catch(() => {
        errorHandler();
      });
    },
    savePublicationName(name) {
      this.isLoadingSavePublicationName = true;
      this.apiPost(`/publication/${this.publicationId}/name`, { name }, data => {
        this.publication.name = data.publication.name;
        let myPublicationIndex = this.myPublications.findIndex(publication => publication.publication_id == data.publication.publication_id);
        this.myPublications[myPublicationIndex].name = data.publication.name;
      }, () => {}, () => {
        this.isLoadingSavePublicationName = false;
      });
    },
    savePublicationDescription(description) {
      this.isLoadingSavePublicationDescription = true;
      this.apiPost(`/publication/${this.publicationId}/description`, { description }, data => {
        this.publication.description = data.publication.description;
      }, () => {}, () => {
        this.isLoadingSavePublicationDescription = false;
      });
    },
    updatePublicationStatus(status, publicationId = this.publicationId) {
      this.isLoadingUpdatePublicationStatus = true;
      this.apiPost(`/publication/${publicationId}/status`, {
        status,
      }, data => {
        this.publication.status = data.publication.status;
        let publicationIndex = this.myPublications.findIndex(publication => publication.publication_id == data.publication.publication_id);
        this.myPublications[publicationIndex].status = data.publication.status;
        this.myPublications = this.myPublications.filter(publication => publication.status !== "inactive");
      }, () => {

      }, () => {
        this.isLoadingUpdatePublicationStatus = false;
      });
    },
    createPublicationPage(pageName, successHandler = () => {}) {
      this.apiPost(`/pages/publication/${this.publicationId}`, {
        page_name: pageName,
      }, data => {
        successHandler(data.pageData);
      }, () => {}, () => {});
    },
  },
});
