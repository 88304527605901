// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-container[data-v-62bbcef8] {
  position: sticky;
  background-color: white;
  top: 0;
  z-index: 1;
}
.nav-bar[data-v-62bbcef8] {
  width: 100%;
  padding: 0 40px;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/pages/PrivacyPolicy.vue","webpack://./PrivacyPolicy.vue"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,uBAAA;EACA,MAAA;EACA,UAAA;ACDF;ADGA;EACE,WAAA;EACA,eAAA;ACAF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.nav-container {\n  position: sticky;\n  background-color: white;\n  top: 0;\n  z-index: 1;\n}\n.nav-bar {\n  width: 100%;\n  padding: 0 40px;\n}\n",".nav-container {\n  position: sticky;\n  background-color: white;\n  top: 0;\n  z-index: 1;\n}\n\n.nav-bar {\n  width: 100%;\n  padding: 0 40px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
