<template>
  <iRow vertical-align="middle" align="between">
    <iRow width="hug" gap="large">
      <iRow
        v-if="canVoteOnComments"
        wrap="nowrap"
        width="hug"
        vertical-align="middle"
        gap="extraSmall"
      >
        <iColumn>
          <iIcon
            icon="arrow-up"
            :variant="userUpVoted ? 'error' : 'primary'"
            :disabled="!isActive"
            :is-loading="loadingUpVotedMessage"
            @click="handleUpVote"
          />
        </iColumn>
        <iText :variant="isActive? 'standard' : 'subtle'">
          {{ getVotes }}
        </iText>
        <iColumn>
          <iIcon
            icon="arrow-down"
            :variant="userDownVoted? 'error' : 'primary'"
            :disabled="!isActive"
            :is-loading="loadingDownVotedMessage"
            @click="handleDownVote"
          />
        </iColumn>
      </iRow>
      <iRow
        v-if="canLikeComments"
        width="hug"
        vertical-align="middle"
        gap="extraSmall"
        class="cursor-pointer"
        @click="handleLike"
      >
        <iIcon
          icon="heart"
          :variant="userLiked ? 'error' : 'primary'"
          :disabled="!isActive"
          :is-loading="loadingLikedMessage"
        />
        <iText :variant="isActive? 'standard' : 'subtle'">
          {{ numLikes }}
        </iText>
      </iRow>
      <iColumn width="hug">
        <iRow
          v-if="canReplyToComments"
          vertical-align="middle"
          variant="tertiary"
          gap="extraSmall"
          class="cursor-pointer"
          @click="reply"
        >
          <iIcon icon="comment" :disabled="!isActive" />
          <iText :variant="isActive? 'standard' : 'subtle'">
            {{ getReplies }}
          </iText>
        </iRow>
      </iColumn>
    </iRow>
    <ConversationMenu
      :message-id="messageId"
      :is-author="isAuthor"
      :is-loading="isConversationMenuLoading"
      :is-moderator="isModerator"
      :moderation-status="moderationStatus"
      :status="status"
      :moderation-enabled="moderationEnabled"
      @report="report"
      @edit="turnOnIsEditing"
      @block="blockComment"
      @delete="deleteComment"
      @undelete="unDeleteComment"
    />
  </iRow>
  <iRow v-if="showModerationButtons" align="between">
    <iColumn width="hug">
      <iButton
        variant="secondary"
        :is-loading="loadingApprovedMessage"
        @click="approveComment"
      >
        Approve
      </iButton>
    </iColumn>
    <iColumn width="hug">
      <iButton
        variant="secondary"
        :is-loading="loadingBlockedMessage"
        @click="blockComment"
      >
        Block
      </iButton>
    </iColumn>
    <iColumn width="hug">
      <iButton variant="secondary" :is-loading="loadingEscalatedMessage" @click="escalateComment">
        Escalate
      </iButton>
    </iColumn>
  </iRow>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useConversationStore } from "%/stores/conversation-store";
import { useGeneralStore } from "%/stores/general-store";
import ConversationMenu from "%/components/community/chatrooms/comments/ConversationMenu";
import { OFFENSE_OPTIONS } from "%/constants/conversations-constants";

export default {
  name: "ConversationFooter",
  components: {
    ConversationMenu,
  },
  props: {
    currentMessage: {
      type: String,
      required: true,
    },
    voteScore: {
      type: Number,
      required: false,
      default: "0",
    },
    numReplies: {
      type: Number,
      required: false,
      default: "0",
    },
    numLikes: {
      type: Number,
      required: false,
      default: "0",
    },
    authorId: {
      type: Number,
      required: true,
    },
    moderationStatus: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    messageId: {
      type: Number,
      required: true,
    },
    showReplying: {
      type: Boolean,
      required: false,
      default: true,
    },
    allowReplying: {
      type: Boolean,
      required: false,
      default: true,
    },
    userLiked: {
      type: Boolean,
      required: true,
    },
    userUpVoted: {
      type: Boolean,
      required: true,
    },
    userDownVoted: {
      type: Boolean,
      required: true,
    },
    canLikeComments: {
      type: Boolean,
      required: false,
      default: false,
    },
    canReplyToComments: {
      type: Boolean,
      required: false,
      default: false,
    },
    canVoteOnComments: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: [
    "upvote",
    "revokeupvote",
    "revokedownvote",
    "downvote",
    "like",
    "unlike",
    "delete",
    "undelete",
    "block",
    "reply",
    "approve",
    "report",
    "startEditing",
    "escalate",
  ],
  data() {
    return {
      OFFENSE_OPTIONS,
      isSharing: false,
      editMessage: "",
    };
  },
  computed: {
    ...mapState(useConversationStore, [
      "isLoading",
      "isModerator",
      "moderationEnabled",
      "isMessageLoading",
    ]),
    ...mapState(useGeneralStore, ["userData", "userId", "links", "isLoggedIn"]),
    isConversationMenuLoading() {
      if (!this.isMessageLoading[this.messageId]) {
        return false;
      }
      if (this.isMessageLoading[this.messageId].delete) {
        return true;
      }
      if (this.isMessageLoading[this.messageId].undelete) {
        return true;
      }
      return false;
    },
    showBlockButton() {
      return !this.isAuthor && this.isModerator && this.moderationStatus === "approved" && this.moderationEnabled;
    },
    showModerationButtons() {
      return this.moderationStatus !== "approved" && this.isModerator && this.moderationEnabled;
    },
    isAuthor() {
      return this.userData.user_id === this.authorId;
    },
    getReplies() {
      if (this.numReplies === 0) {
        return "Reply";
      }
      return this.numReplies;
    },
    getVotes() {
      if (this.voteScore === 0) {
        return "Vote";
      }
      return this.voteScore;
    },
    isActive() {
      return this.status === "active";
    },
    loadingUpVotedMessage() {
      return this.isMessageLoading[this.messageId] && (this.isMessageLoading[this.messageId]["upvote"] || this.isMessageLoading[this.messageId]["revoke-upvote"]);
    },
    loadingDownVotedMessage() {
      return this.isMessageLoading[this.messageId] && (this.isMessageLoading[this.messageId]["downvote"] || this.isMessageLoading[this.messageId]["revoke-downvote"]);
    },
    loadingLikedMessage() {
      return this.isMessageLoading[this.messageId] && (this.isMessageLoading[this.messageId]["like"] || this.isMessageLoading[this.messageId]["revoke-like"]);
    },
    loadingApprovedMessage() {
      return this.isMessageLoading[this.messageId] && this.isMessageLoading[this.messageId]["approve"];
    },
    loadingEscalatedMessage() {
      return this.isMessageLoading[this.messageId] && this.isMessageLoading[this.messageId]["escalate"];
    },
    loadingBlockedMessage() {
      return this.isMessageLoading[this.messageId] && this.isMessageLoading[this.messageId]["block"];
    },
  },
  created() {
    this.editMessage = this.message;
  },
  methods: {
    ...mapActions(useGeneralStore, ["redirect"]),
    checkAuthOrEmit(emitKey, emitData) {
      if (!this.isLoggedIn) {
        const encodedCurrentUri = encodeURIComponent(window.location.pathname);
        window.location.href = `${this.links.web}/login?redirect=${encodedCurrentUri}`;
      } else {
        this.$emit(emitKey, emitData);
      }
    },
    handleUpVote() {
      if (this.userUpVoted) {
        return this.revokeUpVote();
      }
      this.upVote();
    },
    handleDownVote() {
      if (this.userDownVoted) {
        return this.revokeDownVote();
      }
      this.downVote();
    },
    handleLike() {
      if (this.userLiked) {
        return this.unlike();
      }
      this.like();
    },
    turnOnIsEditing() {
      this.showMenuOptions = false;
      if (!this.isAuthor) {
        return;
      }
      this.checkAuthOrEmit("startEditing");
    },
    upVote() {
      this.checkAuthOrEmit("upvote");
    },
    revokeUpVote() {
      this.checkAuthOrEmit("revokeupvote");
    },
    downVote() {
      this.checkAuthOrEmit("downvote");
    },
    revokeDownVote() {
      this.checkAuthOrEmit("revokedownvote");
    },
    like() {
      this.checkAuthOrEmit("like");
    },
    unlike() {
      this.checkAuthOrEmit("unlike");
    },
    deleteComment() {
      if (!this.isAuthor) {
        return;
      }
      this.checkAuthOrEmit("delete");
    },
    unDeleteComment() {
      if (!this.isAuthor) {
        return;
      }
      this.checkAuthOrEmit("undelete");
    },
    report(reason) {
      this.checkAuthOrEmit("report", reason);
    },
    reply() {
      this.showMenuOptions = false;
      this.checkAuthOrEmit("reply");
    },
    blockComment() {
      if (!this.isModerator) {
        return;
      }
      this.checkAuthOrEmit("block", { reason: this.OFFENSE_OPTIONS[0].value, id: this.messageId });
    },
    approveComment() {
      if (!this.isModerator) {
        return;
      }
      this.checkAuthOrEmit("approve");
    },
    escalateComment() {
      if (!this.isModerator) {
        return;
      }
      this.checkAuthOrEmit("escalate");
    },
  },
};
</script>
<style scoped lang="scss">
.cursor-pointer {
  cursor: pointer;
}
</style>
