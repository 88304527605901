<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_USERS"
    title="My Users"
    sub-title="My Users"
    :breadcrumbs="breadcrumbs"
    page-name="My Users"
    align="center"
    width="full"
  />
</template>

<script>
import PageWrapper from "@/components/PageWrapper.vue";
import {
  PAGE_PUBLICATION_USERS,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import { mapState } from "pinia";

export default {
  name: "PublicationUsers",
  components: { PageWrapper },
  data() {
    return {
      PAGE_PUBLICATION_USERS,
      SECTION_PUBLICATIONS,
    };
  },
  computed: {
    ...mapState(useCreatorPublicationStore, [
      "orgId",
      "publication",
      "posts",
      "isLoading",
      "publicationId",
    ]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
        {
          url: `/publication/${this.publicationId}/user-management`,
          label: "User Management",
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss"></style>
