<template>
  <PageWrapper :active-section="SECTION_COMMUNITY" :active-page="PAGE_MARKETPLACE">
    <SmartFeed
      :pinia-store="useSyndicationMarketPlaceFeedStore"
      default-smart-feed-layout="grid"
      default-entity-layout="cardSmall"
    />
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { SECTION_COMMUNITY, PAGE_MARKETPLACE } from "@/constants/nav-constants";
import SmartFeed from "SmartFeed/components/SmartFeed.vue";
import { useSyndicationMarketPlaceFeedStore } from "SmartFeed/stores/feed/syndication-marketplace-feed-store";

export default {
  name: "SyndicationMarketplace",
  components: { SmartFeed, PageWrapper },
  data() {
    return {
      SECTION_COMMUNITY,
      PAGE_MARKETPLACE,
    };
  },
  methods: {
    useSyndicationMarketPlaceFeedStore,
  },
};
</script>

<style scoped lang="scss">

</style>
