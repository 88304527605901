<template>
  <iStyledCard
    :author-name="name"
    width="hug"
    :author-avatar="myAvatarUrl"
    :author-description="myRole"
  />
</template>

<script>
import { DEFAULT_USER_AVATAR } from "%/constants/user-constants";

export default {
  name: "OrgTeamCard",
  props: {
    name: {
      type: String,
      required: true,
    },
    avatarUrl: {
      type: String,
      required: false,
      default: "",
    },
    roleId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    myAvatarUrl() {
      if (this.avatarUrl !== "") {
        return this.avatarUrl;
      }
      return DEFAULT_USER_AVATAR;
    },
    myRole() {
      switch (this.roleId) {
        case 0:
          return "Owner";
        case 1:
          return "Admin";
        case 2:
          return "User";
      }
      return "";
    },
  },
};
</script>

