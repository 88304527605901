<template>
  <iColumn align="center" gap="large" class="subscription-failure">
    <iHeading>Subscription Failed</iHeading>
    <iText variant="error">
      {{ errorMessage }}
    </iText>
    <iSpace :height="2" />
    <iColumn width="hug">
      <iButton variant="primary" @click="goToHistory">
        Go to Subscription History
      </iButton>
    </iColumn>
  </iColumn>
</template>

<script>
import { useSubscriberStore } from "~/stores/subscriber-store";
import { useGeneralStore } from "%/stores/general-store";

export default {
  name: "SubscriptionCancel",
  props: {
    publicationId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      errorMessage: "",
    };
  },
  mounted() {
    const subscriberStore = useSubscriberStore();
    this.errorMessage =
      subscriberStore.subscriptionErrorMessage ||
      "An error occurred during the subscription process.";
  },
  methods: {
    goToHistory() {
      window.location = useGeneralStore().getRelativeHref(
        `/publication/${this.publicationId}/features/pro`,
      );
    },
  },
};
</script>

<style scoped>
.subscription-failure {
  text-align: center;
  padding: 2rem;
}
</style>
