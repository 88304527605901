// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.publication-header-wrapper[data-v-5f1bf7bb] {
  position: relative;
}
.publication-header[data-v-5f1bf7bb] {
  position: relative;
  bottom: 30px;
}
@media (max-width: 1365px) {
.publication-header[data-v-5f1bf7bb] {
    bottom: 21px;
}
}
.publication-header-logo[data-v-5f1bf7bb] {
  border: var(--5f1bf7bb-\\\$getStyles\\.logoBorderWidth) solid var(--5f1bf7bb-\\\$getStyles\\.logoBorderColor);
  background: var(--5f1bf7bb-\\\$getStyles\\.logoBackground);
}
.main-content[data-v-5f1bf7bb] {
  position: relative;
  top: -40px;
}
.splash-image-container[data-v-5f1bf7bb] {
  height: 100%;
  aspect-ratio: 12/4;
}
.profile-image-container[data-v-5f1bf7bb] {
  height: 100%;
  aspect-ratio: 4/4;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/publication/ProfileWrapper.vue","webpack://./ProfileWrapper.vue","webpack://./../../../../usr/local/lib/node_modules/@bloglovin/vue-component-library/dist/breakpoint-mixin.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;ACDF;ADIA;EACE,kBAAA;EACA,YAAA;ACDF;ACAE;AFDF;IAKI,YAAA;ACAF;AACF;ADGA;EACE,uGAAA;EACA,uDAAA;ACAF;ADGA;EACE,kBAAA;EACA,UAAA;ACAF;ADGA;EACE,YAAA;EACA,kBAAA;ACAF;ADGA;EACE,YAAA;EACA,iBAAA;ACAF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.publication-header-wrapper {\n  position: relative;\n}\n\n.publication-header {\n  position: relative;\n  bottom: 30px;\n\n  @include tablet {\n    bottom: 21px;\n  }\n}\n\n.publication-header-logo {\n  border: v-bind(\"$getStyles.logoBorderWidth\") solid v-bind(\"$getStyles.logoBorderColor\");\n  background: v-bind(\"$getStyles.logoBackground\")\n}\n\n.main-content {\n  position: relative;\n  top: -40px;\n}\n\n.splash-image-container {\n  height: 100%;\n  aspect-ratio: 12/4;\n}\n\n.profile-image-container {\n  height: 100%;\n  aspect-ratio: 4/4;\n}\n",".publication-header-wrapper {\n  position: relative;\n}\n\n.publication-header {\n  position: relative;\n  bottom: 30px;\n}\n@media (max-width: 1365px) {\n  .publication-header {\n    bottom: 21px;\n  }\n}\n\n.publication-header-logo {\n  border: v-bind(\"$getStyles.logoBorderWidth\") solid v-bind(\"$getStyles.logoBorderColor\");\n  background: v-bind(\"$getStyles.logoBackground\");\n}\n\n.main-content {\n  position: relative;\n  top: -40px;\n}\n\n.splash-image-container {\n  height: 100%;\n  aspect-ratio: 12/4;\n}\n\n.profile-image-container {\n  height: 100%;\n  aspect-ratio: 4/4;\n}","@mixin phone {\n  @media (max-width: 768px) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media (max-width: 1365px) {\n    @content;\n  }\n}\n\n@mixin desktop {\n  @media (min-width: 1366px) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
