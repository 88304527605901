import Primitives from "@/themes/basic-theme-primitives";
export default {
  font: {
    decorations: {
      none: "none",
      underline: "underline",
    },
    typeface: {
      standard: "Nunito",
      fancy: "Nunito",
      heading: "Nunito",
      accent: "Nunito",
    },
    weight: {
      standard: 400,
      dark: 700,
      light: 300,
    },
  },
  size: {
    lineHeight: {
      standard: 1.5,
    },
    font: {
      footnote: "8px",
      verySmall: "12px",
      small: "14px",
      standard: "16px",
      large: "20px",
      extraLarge: "24px",
      extraExtraLarge: "36px",
      huge: "48px",
      displayVerySmall: "32px",
      displaySmall: "48px",
      displayMedium: "64px",
      displayLarge: "88px",
    },
    borderRadius: {
      none: "0px",
      small: "2px",
      standard: "4px",
      large: "8px",
    },
    spacing: {
      none: "0px",
      extraSmall: "4px",
      small: "8px",
      standard: "12px",
      large: "16px",
      extraLarge: "24px",
      huge: "32px",
      gigantic: "48px",
    },
    border: {
      none: "0px",
      thin: "1px",
      standard: "2px",
      thick: "3px",
    },
    icon: {
      small: "15px",
      standard: "20px",
      large: "32px",
    },
    button: {
      small: "32px",
      standard: "40px",
      large: "48px",
    },
  },
  color: {
    font: {
      standard: "#111827",
      light: "#FFFEFC",
      subtle: "#969696",
      accent: "#FFC414",
      error: "#FF431A",
      warning: "#ce7c00",
      success: "#00853E",
      branded: "#004D65",
      striking: Primitives.colors.striking[400],
      disabled: "#A29CAF",
      informational: "#534B63",
    },
    border: {
      none: "transparent",
      standard: Primitives.colors.gray[400],
      light: Primitives.colors.gray[300],
      dark: Primitives.colors.gray[800],
      striking: "#FFB8A8",
      disabled: "#F4F3F6",
    },
    background: {
      paper: "#ffffff",
      pure: "#ffffff",
      standard: Primitives.colors.gray[400],
      light: Primitives.colors.gray[100],
      dark: "#1F232E",
      darker: "#111827",
      subtle: "#FAF9FB",
      informational: "#F3F3F3",
      strikingLight: Primitives.colors.striking[100],
      striking: Primitives.colors.striking[400],
      notice: "#FFE8A3",
      disabled: "#E7E5EB",
    },
    icon: {
      standard: "#534B63",
      light: "#847B95FF",
      dark: "#FFF2E0",
      informational: "#F3F3F3",
      striking: "#FFB8A8",
      notice: "#FFE8A3",
    },
  },
};
