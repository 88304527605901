// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.post-container[data-v-760d8ba1] {
  padding: 8px;
}
.alert-container[data-v-760d8ba1] {
  padding: var(--760d8ba1-\\\$getStyles\\.alertPadding);
  width: 100%;
  box-sizing: border-box;
  border-radius: var(--760d8ba1-\\\$getStyles\\.borderRadius);
}
.alert-container.info[data-v-760d8ba1] {
  background-color: var(--760d8ba1-\\\$getStyles\\.infoBackgroundColor);
  color: var(--760d8ba1-\\\$getStyles\\.infoFontColor);
}
.alert-container-title[data-v-760d8ba1] {
  font-weight: bold;
  font-size: var(--760d8ba1-\\\$getStyles\\.titleFontSize);
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/pages/syndication-marketplace/SyndicationMarketplaceStory.vue","webpack://./SyndicationMarketplaceStory.vue"],"names":[],"mappings":"AAEA;EACE,YAAA;ACDF;ADIA;EACE,kDAAA;EACA,WAAA;EACA,sBAAA;EACA,wDAAA;ACDF;ADGE;EACE,kEAAA;EACA,iDAAA;ACDJ;ADIE;EACE,iBAAA;EACA,qDAAA;EACA,SAAA;ACFJ","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.post-container {\n  padding: 8px;\n}\n\n.alert-container {\n  padding: v-bind('$getStyles.alertPadding');\n  width: 100%;\n  box-sizing: border-box;\n  border-radius: v-bind('$getStyles.borderRadius');\n\n  &.info {\n    background-color: v-bind('$getStyles.infoBackgroundColor');\n    color: v-bind('$getStyles.infoFontColor');\n  }\n\n  &-title {\n    font-weight: bold;\n    font-size: v-bind('$getStyles.titleFontSize');\n    margin: 0;\n  }\n}\n",".post-container {\n  padding: 8px;\n}\n\n.alert-container {\n  padding: v-bind(\"$getStyles.alertPadding\");\n  width: 100%;\n  box-sizing: border-box;\n  border-radius: v-bind(\"$getStyles.borderRadius\");\n}\n.alert-container.info {\n  background-color: v-bind(\"$getStyles.infoBackgroundColor\");\n  color: v-bind(\"$getStyles.infoFontColor\");\n}\n.alert-container-title {\n  font-weight: bold;\n  font-size: v-bind(\"$getStyles.titleFontSize\");\n  margin: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
