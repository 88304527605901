import { defineStore } from "pinia";
import { useGeneralStore } from "%/stores/general-store";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import { links } from "%/enums/link-enums";
import { useFeedGroupFeedStore } from "SmartFeed/stores/feed/feed-group-feed-store";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { $axios } from "@bloglovin/vue-framework";
import { useFeedGroupBlogFeedStore } from "SmartFeed/stores/feed/feed-group-blog-feed-store";

export const useFeedStore = defineStore("feed-store", {
  state: () => ({
    feedGroupId: 0,
    feedGroup: {},
    isLoadingSaveFeedGroupName: false,
    isLoadingSaveFeedGroupDescription: false,
    isLoadingUpdateFeedGroupStatus: false,
    isLoadingFetchFeedGroups: false,
    isLoadingSearchBlogs: false,
    isLoadingAddBlogToFeedGroup: false,
  }),
  getters: {
    orgId() {
      return useGeneralStore().orgId;
    },
    affiliateJsUrl() {
      return useGeneralStore().getLink(links.affiliateJs);
    },
    affiliateApiV1Url() {
      return useGeneralStore().getLink(links.affiliateApiV1);
    },
    publicationId() {
      return useCreatorPublicationStore().publicationId;
    },
  },
  actions: {
    createFeedGroup(name, description, feedGroupImage, finalHandler) {
      this.isLoadingCreateFeedGroup = true;
      let formData = new FormData();
      formData.append("name", name);
      formData.append("description", description);
      formData.append("feed_group_image", feedGroupImage);
      $axios.post(`/feeds/publication/${this.publicationId}/feed-group`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then(() => {
        useFeedGroupFeedStore().reload();
      }).catch(() => {
      }).finally(() => {
        this.isLoadingCreateFeedGroup = false;
        finalHandler();
      });
    },
    saveFeedGroupName(name) {
      this.isLoadingSaveFeedGroupName = true;

      this.apiPost(`/feeds/publication/${this.publicationId}/feed-group/${this.feedGroupId}/name`, {
        name,
      }, data => {
        new SmartFeedEngine().addEntities(data.entities);
      }, () => {
      }, () => {
        this.isLoadingSaveFeedGroupName = false;
      });
    },
    saveFeedGroupDescription(description) {
      this.isLoadingSaveFeedGroupDescription = true;
      this.apiPost(`/feeds/publication/${this.publicationId}/feed-group/${this.feedGroupId}/description`, {
        description,
      }, data => {
        new SmartFeedEngine().addEntities(data.entities);
      }, () => {
      }, () => {
        this.isLoadingSaveFeedGroupDescription = false;
      });
    },
    saveFeedGroupImage(feedGroupImage) {
      this.isLoadingSaveFeedGroupImage = true;
      let formData = new FormData();
      formData.append("feed_group_image", feedGroupImage);
      this.apiPost(`/feeds/publication/${this.publicationId}/feed-group/${this.feedGroupId}/cover-image`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then(data => {
        new SmartFeedEngine().addEntities(data.entities);
      }).catch( () => {
      }).finally(() => {
        this.isLoadingSaveFeedGroupImage = false;
      });
    },
    updateFeedGroupStatus(status, feedGroupId) {
      this.isLoadingUpdateFeedGroupStatus = true;

      this.apiPost(`/feeds/publication/${this.publicationId}/feed-group/${feedGroupId}/status`, {
        status,
      }, () => {
        useFeedGroupFeedStore().reload();
      }, () => {
      }, () => {
        this.isLoadingUpdateFeedGroupStatus = false;
      });
    },
    searchBlogs(searchTerm, successHandler) {
      this.isLoadingSearchBlogs = true;
      this.apiGet("/feeds/blogs/search", {
        query: searchTerm,
        offset: 0,
      }, data => {
        successHandler(data);
      }, () => {
      }, () => {
        this.isLoadingSearchBlogs = false;
      });
    },
    addBlogToFeedGroup(blog) {
      this.isLoadingAddBlogToFeedGroup = true;
      this.apiPost(`/feeds/publication/${this.publicationId}/feed-group/${this.feedGroupId}/blogs`, {
        bloglovin_blog_id: parseInt(blog.blog_id),
        url: blog.url,
        display_name: blog.name,
      }, () => {
        useFeedGroupBlogFeedStore().reload();
      }, () => {
      }, () => {
        this.isLoadingAddBlogToFeedGroup = false;
      });
    },
    removeBlogFromFeedGroup(feedGroupBlogId) {
      this.isLoadingAddBlogToFeedGroup = true;
      this.apiPost(`/feeds/publication/${this.publicationId}/feed-group/${this.feedGroupId}/blog/${feedGroupBlogId}/remove`, {
        feed_group_blog_id: feedGroupBlogId,
      }, () => {
        useFeedGroupBlogFeedStore().reload();
      }, () => {
      }, () => {
        this.isLoadingAddBlogToFeedGroup = false;
      });
    },
  },
});
