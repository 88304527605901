// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.password-form-wrapper[data-v-16a3dd76] {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/auth/SignUpForm.vue","webpack://./SignUpForm.vue"],"names":[],"mappings":"AAEA;EACE,WAAA;ACDF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.password-form-wrapper {\n  width: 100%;\n}\n",".password-form-wrapper {\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
