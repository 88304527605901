<template>
  <PageWrapper
    :active-section="SECTION_COMMUNITY"
    :active-page="PAGE_DESIGN_MARKETPLACE_PUBLICATION_TEMPLATES"
    align="left"
    width="fixed"
  >
    <ComingSoon />
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import {
  SECTION_COMMUNITY,
  PAGE_DESIGN_MARKETPLACE_PUBLICATION_TEMPLATES,
} from "@/constants/nav-constants";
import ComingSoon from "@/components/ComingSoon";

export default {
  name: "DesignMarketplacePublicationTemplates",
  components: {
    ComingSoon,
    PageWrapper,
  },
  data() {
    return {
      SECTION_COMMUNITY,
      PAGE_DESIGN_MARKETPLACE_PUBLICATION_TEMPLATES,
    };
  },
};
</script>

