<template>
  <PageWrapper
    :active-section="SECTION_COMMUNITY"
    :active-page="PAGE_PUBLICATION_COLLABORATION_AD_DETAILS"
    :breadcrumbs="breadcrumbs"
    page-name="Collaboration Ad Details"
    align="left"
    width="full"
  >
    <ProfileWrapper
      :splash-image="'https://cdn-alpha.inspiration.co/images/publication/publication-blog.webp'"
      :logo-image="'https://cdn-alpha.inspiration.co/images/generic_company_logo.webp'"
      :title="getRoleName(ad.role)"
    >
      <iColumn gap="large">
        <iCard>
          <template #header>
            <iSubHeading>What we need</iSubHeading>
          </template>
          <template #body>
            <iParagraph>{{ ad.advertisement_text }}</iParagraph>
          </template>
        </iCard>
      </iColumn>
    </ProfileWrapper>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper.vue";
import ProfileWrapper from "@/components/publication/ProfileWrapper.vue";
import { AD_ROLE_OPTIONS } from "@/constants/collaborator-constants";
import {
  PAGE_PUBLICATION_COLLABORATION_AD_DETAILS,
  SECTION_COMMUNITY,
} from "@/constants/nav-constants";
import { usePublicationStore } from "@/stores/publication-store";
import {
  dateFromUnixTimestamp,
  formatDateString,
} from "@bloglovin/vue-framework";
import { mapState } from "pinia";

export default {
  name: "PublicationCollaborationAdDetails",
  components: {
    PageWrapper,
    ProfileWrapper,
  },
  props: {
    ad: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      SECTION_COMMUNITY,
      PAGE_PUBLICATION_COLLABORATION_AD_DETAILS,
    };
  },
  computed: {
    ...mapState(usePublicationStore, ["publication"]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        {
          url: `/publication/org/${this.publication.org_id}`,
          label: this.publication.name,
        },
        {
          url: `/publication/org/${this.publication.org_id}/collaboration-ads`,
          label: "Collaboration Ads",
        },
        { url: "#", label: "Ad Details" },
      ];
    },
  },
  methods: {
    formatDate(timestamp) {
      const date = dateFromUnixTimestamp(timestamp);
      return formatDateString(date, {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
    getRoleName(roleId) {
      return (
        AD_ROLE_OPTIONS.find(role => role.value === roleId)?.name ||
        "Unknown Role"
      );
    },
  },
};
</script>
