<template>
  <iRow>
    <iSubHeading>Billing</iSubHeading>
  </iRow>
  <iRow class="side-nav">
    <iMenu
      orientation="vertical"
      :active-item="activeItem"
      :items="parsedBillingNav"
    />
  </iRow>
</template>

<script>
import { useGeneralStore } from "%/stores/general-store";
import { SIDE_NAV_BILLING } from "@/constants/nav-constants";
import { parseUrlVars } from "@/helpers/parseUrlVars";
import { mapState } from "pinia";

export default {
  name: "BillingSideNav",
  props: {
    activeItem: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(useGeneralStore, ["orgId"]),
    parsedBillingNav() {
      return parseUrlVars(SIDE_NAV_BILLING, {
        "{org_id}": this.orgId,
      });
    },
  },
};
</script>
