<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_THEMES"
    title="Clone Theme"
    :breadcrumbs="breadcrumbs"
    page-name="Clone Theme"
  >
    <iColumn>
      <ThemeConfiguration
        v-model="innerTheme"
        :palette="palette"
        @has-errors="formHasErrors = $event"
      />

      <iSpace :height="10" />
      <iText v-if="formHasErrors" variant="error">
        Please correct the errors above before saving.
      </iText>
      <iRow>
        <iColumn width="hug">
          <iButton
            :is-loading="isLoadingPostTheme"
            loading-spinner-position="inside"
            :disabled="formHasErrors"
            variant="primary"
            @click="cloneTheme(innerTheme)"
          >
            Clone
          </iButton>
        </iColumn>
        <iColumn width="hug">
          <iLink :href="`/publication/org/${orgId}/themes`">
            <iButton variant="secondary">
              Cancel
            </iButton>
          </iLink>
        </iColumn>
      </iRow>
    </iColumn>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import ThemeConfiguration from "@/components/ThemeConfiguration";
import { PAGE_THEMES, SECTION_PUBLICATIONS } from "@/constants/nav-constants";
import { useGeneralStore } from "%/stores/general-store";
import { useThemesStore } from "@/stores/themes-store";
import { mapActions, mapState } from "pinia";

export default {
  name: "ThemesClone",
  components: { ThemeConfiguration, PageWrapper },
  props: {
    theme: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    palette: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      SECTION_PUBLICATIONS,
      PAGE_THEMES,
      innerTheme: this.theme,
      formHasErrors: false,
    };
  },
  computed: {
    ...mapState(useGeneralStore, ["orgId"]),
    ...mapState(useThemesStore, ["isLoadingPostTheme"]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/org/${this.orgId}/themes`, label: "Themes" },
      ];
    },
  },
  methods: {
    ...mapActions(useThemesStore, ["cloneTheme"]),
  },
};
</script>

<style scoped lang="scss"></style>
