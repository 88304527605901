import { $axios } from "@bloglovin/vue-framework";
import { defineStore } from "pinia";

import { useGeneralStore } from "%/stores/general-store";

import {
  STATUS_ACTIVE,
  STATUS_CANCELLED,
  STATUS_INACTIVE,
} from "~/constants/subscription-constants";

export const useSubscriberStore = defineStore("subscriber-store", {
  state: () => ({
    isLoading: false,
    subscriber: {},
    subscriptionErrorMessage: "",
  }),
  getters: {
    getUserId() {
      return useGeneralStore().userId;
    },
    getOrgId() {
      return useGeneralStore().orgId;
    },
    isSubscribed() {
      if (!this.subscriber?.status) {
        return false;
      }
      return [STATUS_ACTIVE, STATUS_CANCELLED].includes(this.subscriber.status);
    },
    subscriptionStatus() {
      if (!this.subscriber?.status) {
        return STATUS_INACTIVE;
      }
      return this.subscriber.status;
    },
  },
  actions: {
    async setSubscriberData(data) {
      this.subscriber = data;
    },
    cancelSubscription() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      $axios
          .post(`/subscriber/${this.subscriber.subscriber_id}/cancel`, {
            userId: this.getUserId,
            orgId: this.getOrgId,
          })
          .then(response => {
            if (response.data.success) {
              window.location = useGeneralStore().getRelativeHref(
                "/subscriptions/history",
              );
            }
            throw Error("Subscription Cancellation Failed");
          })
          .catch(error => {
            if (error.response.data.message) {
              this.subscriptionErrorMessage = error.response.data.message;
              return;
            }
            this.subscriptionErrorMessage = "Subscription cancellation failed";
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    renewSubscription() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      $axios
          .post(`/subscriber/${this.subscriber.subscriber_id}/renew`, {
            userId: this.getUserId,
            orgId: this.getOrgId,
          })
          .then(response => {
            if (response.data.success) {
              window.location = useGeneralStore().getRelativeHref(
                "/subscriptions/history",
              );
            }
            throw Error("Subscription Renewal Failed");
          })
          .catch(error => {
            if (error.response.data.message) {
              this.subscriptionErrorMessage = error.response.data.message;
              return;
            }
            this.subscriptionErrorMessage = "Subscription renewal failed";
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    async subscribe(subscriptionId) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      try {
        const response = await $axios.post(
          `/subscriber/${subscriptionId}/subscribe`,
          {
            userId: this.getUserId,
            orgId: this.getOrgId,
            success_url: useGeneralStore().getRelativeHref(
              "/subscriptions/success",
            ),
            cancel_url: useGeneralStore().getRelativeHref(
              "/subscriptions/failure",
            ),
          },
        );
        if (response.data.success) {
          this.subscriber = response.data.subscriberData;
          window.location = response.data.checkoutSession.url;
        } else {
          throw new Error("Subscription Failed");
        }
      } catch (error) {
        if (error.response?.data?.error) {
          this.subscriptionErrorMessage = error.response.data.error;
        } else {
          this.subscriptionErrorMessage =
            "Subscription failed for an unknown reason";
        }
      } finally {
        this.isLoading = false;
      }
    },
    async createCheckoutSessionForExistingSubscriber(
      subscriberId,
      billingHistoryId,
    ) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      try {
        const response = await $axios.post(
          "/subscriber/create-checkout-session",
          {
            subscriber_id: subscriberId,
            billing_history_id: billingHistoryId,
            success_url: useGeneralStore().getRelativeHref(
              "/subscriptions/success",
            ),
            cancel_url: useGeneralStore().getRelativeHref(
              "/subscriptions/failure",
            ),
          },
        );
        if (response.data.success) {
          this.subscriber = response.data.subscriberData;
          window.location = response.data.checkoutSession.url;
        } else {
          throw new Error("Checkout session creation failed");
        }
      } catch (error) {
        if (error.response?.data?.error) {
          this.subscriptionErrorMessage = error.response.data.error;
        } else {
          this.subscriptionErrorMessage =
            "Checkout session creation failed for an unknown reason";
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
});

export default useSubscriberStore;
