// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-40dad453] .infinite-scroll-container {
  width: 100%;
}
[data-v-40dad453] .i-card-header.flex-box {
  padding-inline: unset;
}
.tag-style[data-v-40dad453] {
  border-radius: 5px;
  padding: 4px 8px;
  border: 1px solid #e0e0e0;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/pages/publication/Themes.vue","webpack://./Themes.vue"],"names":[],"mappings":"AAEA;EACE,WAAA;ACDF;ADIA;EACE,qBAAA;ACDF;ADIA;EACE,kBAAA;EACA,gBAAA;EACA,yBAAA;ACDF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n:deep(.infinite-scroll-container) {\n  width: 100%;\n}\n\n:deep(.i-card-header.flex-box) {\n  padding-inline: unset;\n}\n\n.tag-style {\n  border-radius: 5px;\n  padding: 4px 8px;\n  border: 1px solid #e0e0e0;\n}\n",":deep(.infinite-scroll-container) {\n  width: 100%;\n}\n\n:deep(.i-card-header.flex-box) {\n  padding-inline: unset;\n}\n\n.tag-style {\n  border-radius: 5px;\n  padding: 4px 8px;\n  border: 1px solid #e0e0e0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
