<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_MY_INACTIVE_PUBLICATIONS"
    title="Closed Publications"
    :breadcrumbs="breadcrumbs"
    page-name="Closed Publications"
    align="left"
    width="full"
    action-button-text="Create New Publication"
    @click:action="navigateToCreatePublication"
  >
    <SmartFeed
      :pinia-store="useOfflinePublicationFeedStore"
      default-smart-feed-layout="grid"
      default-entity-layout="cardSmall"
    />
  </PageWrapper>
</template>

<script>
import { mapState } from "pinia";
import PageWrapper from "@/components/PageWrapper.vue";
import SmartFeed from "SmartFeed/components/SmartFeed.vue";
import { PAGE_MY_INACTIVE_PUBLICATIONS, SECTION_PUBLICATIONS } from "@/constants/nav-constants";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import { useOfflinePublicationFeedStore } from "SmartFeed/stores/feed/offline-publication-feed-store";

export default {
  name: "MyInactivePublications",
  components: {
    SmartFeed,
    PageWrapper,
  },
  data() {
    return {
      SECTION_PUBLICATIONS,
      PAGE_MY_INACTIVE_PUBLICATIONS,
    };
  },
  computed: {
    ...mapState(useCreatorPublicationStore, [
      "orgId",
    ]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
      ];
    },
  },
  methods: {
    useOfflinePublicationFeedStore,
    navigateToCreatePublication() {
      window.location = `/publication/org/${this.orgId}/create`;
    },
  },
};
</script>
