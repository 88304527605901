<template>
  <PageWrapper
    :active-section="SECTION_COMMUNITY"
    :active-page="PAGE_COMMUNITY_ADVERTISERS"
    title="Advertisers"
    sub-title=""
    :breadcrumbs="breadcrumbs"
    page-name="Advertisers"
    align="center"
    width="full"
  >
    <SmartFeed
      :pinia-store="useAdvertiserDirectoryFeedStore"
      default-smart-feed-layout="grid"
      default-entity-layout="cardSmall"
    />
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import SmartFeed from "SmartFeed/components/SmartFeed";
import {
  PAGE_COMMUNITY_ADVERTISERS,
  SECTION_COMMUNITY,
} from "@/constants/nav-constants";
import { useAdvertiserDirectoryFeedStore } from "SmartFeed/stores/feed/advertiser-directory-feed-store";

export default {
  name: "AdvertiserDirectory",
  components: {
    PageWrapper,
    SmartFeed,
  },
  data() {
    return {
      SECTION_COMMUNITY,
      PAGE_COMMUNITY_ADVERTISERS,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: "/community", label: "Community" },
      ];
    },
  },
  methods: {
    useAdvertiserDirectoryFeedStore,
  },
};
</script>
