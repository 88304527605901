<template>
  <PageWrapper
    :active-section="SECTION_BILLING"
    :active-page="PAGE_CANCELLED_SUBSCRIPTIONS"
    title="Cancelled Subscriptions"
    :breadcrumbs="breadcrumbs"
    page-name="Cancelled Subscriptions"
    align="left"
    width="full"
  >
    <SmartFeed
      :pinia-store="useCancelledSubscriptionFeedStore"
      default-smart-feed-layout="table"
      default-entity-layout="tableRow"
    >
      <template #empty-state>
        <iCard width="fill" :style-overrides="{borderWidth: 'none'}">
          <template #body>
            <iColumn gap="large" align="center">
              <iColumn gap="standard" align="center">
                <iSubHeading variant="subtle">
                  No cancelled subscriptions yet
                </iSubHeading>
                <iParagraph
                  variant="subtle"
                  text-align="center"
                  :max-width="400"
                >
                  Your cancelled subscriptions will appear here once.
                </iParagraph>
              </iColumn>
            </iColumn>
          </template>
        </iCard>
      </template>
    </SmartFeed>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import {
  PAGE_CANCELLED_SUBSCRIPTIONS,
  SECTION_BILLING,
} from "@/constants/nav-constants";
import SmartFeed from "SmartFeed/components/SmartFeed";
import { useCancelledSubscriptionFeedStore } from "SmartFeed/stores/feed/cancelled-subscription-feed-store";

export default {
  name: "CancelledSubscriptions",
  components: {
    SmartFeed,
    PageWrapper,
  },
  data() {
    return {
      SECTION_BILLING,
      PAGE_CANCELLED_SUBSCRIPTIONS,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: "/billing", label: "Billing" },
      ];
    },
  },
  methods: { useCancelledSubscriptionFeedStore },
};
</script>
