export const LAYOUT_CARD_LARGE = "Large Card";
export const LAYOUT_CARD_SMALL = "Small Card";
export const LAYOUT_LIST = "List";
export const LAYOUT_TABLE = "Table";

export const ALLOWED_LAYOUT_TYPES = [
  LAYOUT_CARD_LARGE,
  LAYOUT_CARD_SMALL,
  LAYOUT_LIST,
  LAYOUT_TABLE,
];
