<template>
  <iStyledCard
    class="card"
    :width="280"
    :description-length="150"
    pointer-cursor-on-hover
    :focused="focused"
    single-column-metrics
    :metrics="metrics"
    @click="$emit('select')"
  >
    <template #title>
      <iSubHeading>{{ title }}</iSubHeading>
    </template>
    <template #description>
      <iText font-size="small">
        {{ description }}
      </iText>
      <iSpace :height="1" />
    </template>
  </iStyledCard>
</template>

<script>
export default {
  name: "PublicationSettingsCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    features: {
      type: Object,
      required: true,
    },
    focused: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["select"],
  data() {
    return {
      canSignUp: "Allow Signups",
      isPrivatePublication: "Private Publication",
      requireAccountApproval: "Require Approval",
    };
  },
  computed: {
    metrics() {

      return Object.entries(this.features).map(feature => ({
        name: this[feature[0]],
        icon: feature[1] ? "check-circle" : "close",
        iconVariant: feature[1] ? "branded" : "error",
      }));
    },
  },
};
</script>

<style scoped>
.card {
  padding: 8px;
}
</style>
