<template>
  <PageWrapper
    :active-section="SECTION_MONETIZATION"
    :active-page="PAGE_LINKS_REPORT"
    title="My Links"
    page-name="My Links"
    :breadcrumbs="breadcrumbs"
    :action-button-href="`/monetization/org/${orgId}/links/collections`"
    action-button-text="View Collections"
  >
    <iRow :width="2700" overflow="hidden">
      <SmartFeed :pinia-store="useAffiliateLinkFeedStore" default-smart-feed-layout="table" default-entity-layout="tableRow" />
    </iRow>

    <iModal
      :show-footer="false"
      :visible="isAddToCollectionModalOpen"
      :width="550"
      title="Add Link to Collection"
      @close="closeAddToCollectionModal"
    >
      <template #body>
        <SelectAffiliateCollection :affiliate-link-id="affiliateLink.publisherAffiliateLinkId" />
      </template>
    </iModal>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { useAffiliateLinkStore } from "@/stores/affiliate-link-store";
import { mapActions, mapState, mapWritableState } from "pinia";
import SmartFeed from "SmartFeed/components/SmartFeed";
import { SECTION_MONETIZATION, PAGE_LINKS_REPORT } from "@/constants/nav-constants";
import { useAffiliateLinkFeedStore } from "SmartFeed/stores/feed/affiliate-link-feed-store";
import SelectAffiliateCollection from "@/components/SelectAffiliateCollection.vue";

export default {
  name: "LinksReport",
  components: {
    SelectAffiliateCollection,
    SmartFeed,
    PageWrapper,
  },
  data() {
    return {
      SECTION_MONETIZATION,
      PAGE_LINKS_REPORT,
    };
  },
  computed: {
    ...mapWritableState(useAffiliateLinkStore, [
      "affiliateLink",
      "isAddToCollectionModalOpen",
      "affiliateDropdownId",
    ]),
    ...mapState(useAffiliateLinkStore, [
      "orgId",
      "affiliateLinks",
      "loadingAffiliateLinks",
      "hasMoreAffiliateLinks",
    ]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/monetization/org/${this.orgId}`, label: "Monetization" },
      ];
    },
  },
  async created() {
    this.fetchAffiliateLinksByOrgId();
  },
  methods: {
    useAffiliateLinkFeedStore,
    ...mapActions(useAffiliateLinkStore, [
      "editAffiliateLinkProductDetails",
      "fetchAffiliateLinksByOrgId",
    ]),
    closeAddToCollectionModal() {
      this.affiliateLink = {};
      this.isAddToCollectionModalOpen = false;
    },
  },
};
</script>
