export const ROLE_CONTRIBUTOR = "contributor";
export const ROLE_MARKETER = "marketer";
export const ROLE_EDITOR = "editor";
export const ROLE_PRODUCER = "producer";

export const COLLABORATOR_TYPE_COLLABORATOR = "collaborator";
export const COLLABORATOR_TYPE_APPLICANT = "applicant";
export const COLLABORATOR_TYPE_INVITEE = "invitee";

// Collaborator Statuses (matching backend)
export const COLLABORATOR_STATUS_ACTIVE = "active";
export const COLLABORATOR_STATUS_INACTIVE = "inactive";
export const COLLABORATOR_STATUS_APPLIED = "applied";
export const COLLABORATOR_STATUS_APPLICATION_DECLINED = "application declined";
export const COLLABORATOR_STATUS_APPLICATION_REVOKED = "application revoked";
export const COLLABORATOR_STATUS_INVITED = "invited";
export const COLLABORATOR_STATUS_INVITATION_DECLINED = "invitation declined";
export const COLLABORATOR_STATUS_INVITATION_REVOKED = "invitation revoked";
export const COLLABORATOR_STATUS_OWNER_INVITED = "owner invited";
export const COLLABORATOR_STATUS_REJECTED = "rejected";

// Collaborator Action Types
export const COLLABORATOR_ACTION_REVOKE_INVITATION = "revoke_invitation";
export const COLLABORATOR_ACTION_COPY_INVITE_LINK = "copy_invite_link";
export const COLLABORATOR_ACTION_RESEND_INVITATION = "resend_invitation";
export const COLLABORATOR_ACTION_DISABLE_USER = "disable_user";
export const COLLABORATOR_ACTION_CHANGE_ROLE = "change_role";
export const COLLABORATOR_ACTION_ENABLE_USER = "enable_user";
export const COLLABORATOR_ACTION_REACTIVATE_INVITATION = "reactivate_invitation";
export const COLLABORATOR_ACTION_APPROVE_APPLICANT = "approve_applicant";
export const COLLABORATOR_ACTION_REJECT_APPLICANT = "reject_applicant";
export const COLLABORATOR_ACTION_REACTIVATE_APPLICATION = "reactivate_application";

// Action Labels
export const COLLABORATOR_ACTION_LABELS = {
  [COLLABORATOR_ACTION_REVOKE_INVITATION]: "Revoke Invitation",
  [COLLABORATOR_ACTION_COPY_INVITE_LINK]: "Copy Invite Link",
  [COLLABORATOR_ACTION_RESEND_INVITATION]: "Resend Invitation Email",
  [COLLABORATOR_ACTION_DISABLE_USER]: "Disable User",
  [COLLABORATOR_ACTION_CHANGE_ROLE]: "Change Role",
  [COLLABORATOR_ACTION_ENABLE_USER]: "Enable User",
  [COLLABORATOR_ACTION_REACTIVATE_INVITATION]: "Reactivate Invitation",
  [COLLABORATOR_ACTION_APPROVE_APPLICANT]: "Approve",
  [COLLABORATOR_ACTION_REJECT_APPLICANT]: "Reject",
  [COLLABORATOR_ACTION_REACTIVATE_APPLICATION]: "Reactivate Application",
};

export const COLLABORATOR_STATUS_ACTIONS = {
  [COLLABORATOR_STATUS_INVITED]: [
    COLLABORATOR_ACTION_REVOKE_INVITATION,
    COLLABORATOR_ACTION_COPY_INVITE_LINK,
    COLLABORATOR_ACTION_RESEND_INVITATION,
  ],
  [COLLABORATOR_STATUS_ACTIVE]: [
    COLLABORATOR_ACTION_DISABLE_USER,
    COLLABORATOR_ACTION_CHANGE_ROLE,
  ],
  [COLLABORATOR_STATUS_INACTIVE]: [
    COLLABORATOR_ACTION_ENABLE_USER,
  ],
  [COLLABORATOR_STATUS_APPLICATION_DECLINED]: [
    COLLABORATOR_ACTION_REACTIVATE_APPLICATION,
  ],
  [COLLABORATOR_STATUS_APPLICATION_REVOKED]: [
    COLLABORATOR_ACTION_REACTIVATE_APPLICATION,
  ],
  [COLLABORATOR_STATUS_INVITATION_DECLINED]: [
    COLLABORATOR_ACTION_REACTIVATE_INVITATION,
  ],
  [COLLABORATOR_STATUS_INVITATION_REVOKED]: [
    COLLABORATOR_ACTION_REACTIVATE_INVITATION,
  ],
  [COLLABORATOR_STATUS_APPLIED]: [
    COLLABORATOR_ACTION_APPROVE_APPLICANT,
    COLLABORATOR_ACTION_REJECT_APPLICANT,
  ],
  [COLLABORATOR_STATUS_REJECTED]: [
    COLLABORATOR_ACTION_APPROVE_APPLICANT,
  ],
};

export const COLLABORATOR_ACTIVE_STATUSES = [
  "accepted",
  "application_accepted",
  "invitation_accepted",
];

export const ROLE_OPTIONS = [
  { name: "Contributor", value: "contributor" },
  { name: "Editor", value: "editor" },
  { name: "Marketer", value: "marketer" },
  { name: "Producer", value: "producer" },
];

export const AD_ROLE_OPTIONS = [
  { name: "Contributor", value: 1 },
  { name: "Editor", value: 2 },
  { name: "Marketer", value: 3 },
  { name: "Producer", value: 4 },
];

export const STATUS_MAP = {
  applied: "Applied",
  invited: "Invited",
  accepted: "Accepted",
  invitation_accepted: "Invitation Accepted",
  invitation_revoked: "Invitation Revoked",
  invitation_declined: "Invitation Declined",
  application_accepted: "Application Accepted",
  application_revoked: "Application Revoked",
  application_declined: "Application Declined",
  denied: "Denied",
  removed: "Removed",
  active: "Active",
};

export const HIDE_ACTION_STATUSES = [
  "accepted",
  "application_revoked",
  "application_declined",
  "invitation_accepted",
  "invitation_declined",
  "application_accepted",
  "invitation_revoked",
];
