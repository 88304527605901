import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";

export const useCreatorDirectoryFeedStore = defineSmartFeedPiniaStore(
  "creator-directory-feed-store",
  {
    state: () => ({
      smartFeedEngine: new SmartFeedEngine(),
      feed: [],
      metadata: {
        hasMore: true,
        lastId: 0,
      },
      isLoading: false,
    }),
    getters: {
      hasMore() {
        return this.metadata.hasMore;
      },
    },
    actions: {
      getSmartFeed() {
        this.apiGet(
          "/org/creators/smart-feed",
          {
            last_id: this.metadata.lastId,
          },
          data => this.handleSmartFeedResponse(data),
        );
      },
    },
  },
);
