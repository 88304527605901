<template>
  <iRow class="splash-min-width">
    <iColumn align="center">
      <iRow class="nav-container">
        <iNav class="nav-bar">
          <iRow
            width="fill"
            :height="80"
            vertical-align="middle"
            align="between"
          >
            <iColumn width="hug" vertical-align="middle" class="navigation-section-primary">
              <Logo />
            </iColumn>
            <iColumn
              v-if="!hideButtons"
              vertical-align="middle"
              width="hug"
            >
              <iRow
                wrap="nowrap"
                height="hug"
                gap="large"
                vertical-align="middle"
                align="right"
              >
                <iColumn width="hug">
                  <iButton :width="75" variant="tertiary" @click="onButtonClick">
                    Sign in
                  </iButton>
                </iColumn>
                <iColumn width="hug">
                  <iButton width="hug" variant="striking" @click="onButtonClick">
                    Join Waitlist
                  </iButton>
                </iColumn>
              </iRow>
            </iColumn>
          </iRow>
        </iNav>
      </iRow>
      <iRow v-if="title" class="title-section" :width="narrowPage ? 400 : 'fill'">
        <iHeading font-size="extraExtraLarge">
          {{ title }}
        </iHeading>
        <iSpace :height="10" />
      </iRow>
      <iColumn class="primary-content" :width="narrowPage ? 400 : 'fill'">
        <slot />
      </iColumn>
    </iColumn>
  </iRow>
</template>

<script>
import Logo from "@/components/Logo";

export default {
  name: "SplashWrapper",
  components: { Logo },
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
    narrowPage: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideButtons: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onButtonClick() {
      window.location.href = "/login";
    },
  },
};
</script>

<style scoped lang="scss">
.splash-min-width {
  min-width: 505px;
}
.nav-container {
  position: sticky;
  background-color: white;
  top: 0;
  z-index: 1;
}
.nav-bar {
  width: 100%;
  padding: 0 20px;
  overflow: auto;
}

@include phone {
  .nav-bar {
    padding: 0 20px;

    .nav-fill {
      display: none;
    }

    .button-container {
      gap: 0;
    }

    :deep(.i-button) {
      width: min-content;
      white-space: nowrap;
    }
  }
}
</style>
