// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* latin */
@font-face {
    font-family: 'Alegreya Sans';
    font-weight: 400;
    src: url(https://cdn-alpha.inspiration.co/fonts/AlegreyaSansNormalLatin400.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
`, "",{"version":3,"sources":["webpack://./src/Web/scripts/styles/fonts/alegreya-sans-logo.css"],"names":[],"mappings":"AAAA,UAAU;AACV;IACI,4BAA4B;IAC5B,gBAAgB;IAChB,iGAAiG;IACjG,yLAAyL;AAC7L","sourcesContent":["/* latin */\n@font-face {\n    font-family: 'Alegreya Sans';\n    font-weight: 400;\n    src: url(https://cdn-alpha.inspiration.co/fonts/AlegreyaSansNormalLatin400.woff2) format('woff2');\n    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
