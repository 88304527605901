// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subscription-failure[data-v-7f40903d] {
  text-align: center;
  padding: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/pages/SubscriptionCancel.vue","webpack://./SubscriptionCancel.vue"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,aAAA;ACDF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.subscription-failure {\n  text-align: center;\n  padding: 2rem;\n}\n",".subscription-failure {\n  text-align: center;\n  padding: 2rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
