<template>
  <iModal
    v-if="visible"
    :title="`${formAction} Website`"
    :width="600"
    show-footer
    primary-action-button-label="Save"
    :primary-action-button-disabled="!isFormValid"
    @close="closeModal"
    @click:primary="submit"
  >
    <template #body>
      <iColumn gap="large" overflow="scroll">
        <iTextInput
          v-model="domain"
          label="Website Domain"
          width="fill"
          focus
          @enter="submit"
        />
      </iColumn>
    </template>
  </iModal>
</template>

<script>
export default {
  name: "AddPublisherWebsiteModal",
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: true,
    },
    currentWebsite: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  emits: ["close", "submit", "update"],
  data() {
    return {
      domain: "",
      ignoreQueryParams: true,
    };
  },
  computed: {
    currentWebsiteIsEmpty() {
      return !this.currentWebsite || Object.keys(this.currentWebsite).length === 0;
    },
    formAction() {
      if (this.currentWebsiteIsEmpty) {
        return "Add";
      }
      return "Edit";
    },
    isFormValid() {
      return this.domain.length > 0;
    },
  },
  watch: {
    visible(value) {
      if (value) {
        this.domain = this.currentWebsite?.website_domain || "";
        this.ignoreQueryParams = this.currentWebsite?.ignore_query_params === "1";
      }
    },
  },
  methods: {
    closeModal() {
      this.domain = "";
      this.ignoreQueryParams = true;
      this.$emit("close");
    },
    submit() {
      const ignoreQueryParams = this.ignoreQueryParams ? 1 : 0;

      if (this.currentWebsite) {
        const websiteId = this.currentWebsite.publisher_website_id;
        const claimStatus = this.currentWebsite.claim_status;
        const status = this.currentWebsite.status;
        this.$emit("update", websiteId, this.domain, ignoreQueryParams, claimStatus, status);
      } else {
        this.$emit("submit", this.domain, ignoreQueryParams);
      }

      this.closeModal();
    },
  },
};
</script>
