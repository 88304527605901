// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo[data-v-384bd40e] {
  height: var(--384bd40e-height);
  cursor: pointer;
  display: flex;
  align-items: center;
}
.logo-image[data-v-384bd40e] {
  width: 75px;
}
.logo-text[data-v-384bd40e] {
  font-size: 30px;
  font-family: Alegreya Sans;
  margin-bottom: 8px;
}
.logo-text-accent[data-v-384bd40e] {
  color: #0f8b00;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/Logo.vue","webpack://./Logo.vue"],"names":[],"mappings":"AAGA;EACE,8BAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;ACFF;ADKA;EACE,WAAA;ACFF;ADKA;EACE,eAAA;EACA,0BAAA;EACA,kBAAA;ACFF;ADKA;EACE,cAAA;ACFF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n\n.logo {\n  height: v-bind(height);\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n}\n\n.logo-image {\n  width: 75px;\n}\n\n.logo-text {\n  font-size: 30px;\n  font-family: Alegreya Sans;\n  margin-bottom: 8px;\n}\n\n.logo-text-accent {\n  color: #0f8b00;\n}\n",".logo {\n  height: v-bind(height);\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n}\n\n.logo-image {\n  width: 75px;\n}\n\n.logo-text {\n  font-size: 30px;\n  font-family: Alegreya Sans;\n  margin-bottom: 8px;\n}\n\n.logo-text-accent {\n  color: #0f8b00;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
