import { $axios } from "@bloglovin/vue-framework";
import { defineStore } from "pinia";
import { ToastBuilder } from "@bloglovin/vue-component-library";
import { useGeneralStore } from "%/stores/general-store";
import { links } from "%/enums/link-enums";

export const usePendingEarningsStore = defineStore("pending-earnings-store", {
  state: () => ({
    pendingEarnings: [],
    pendingEarningsLastBrandId: 0,
    pendingEarningsPageSize: 20,
    pendingEarningsIsLoading: false,
  }),
  getters: {
    hasMorePendingEarnings() {
      return this.pendingEarningsLastBrandId !== -1;
    },
    affiliateApiV1Url() {
      return useGeneralStore().getLink(links.affiliateApiV1);
    },
  },
  actions: {
    resetPendingEarnings() {
      this.pendingEarnings = [];
      this.pendingEarningsLastBrandId = 0;
      this.fetchMorePendingEarnings();
    },
    fetchMorePendingEarnings() {
      if (
        this.pendingEarningsLastBrandId === -1 ||
          this.pendingEarningsIsLoading
      ) {
        return;
      }
      this.pendingEarningsIsLoading = true;
      $axios
          .get(`${this.affiliateApiV1Url}/actions/pending-earnings`, {
            params: {
              lastId: this.pendingEarningsLastBrandId,
              limit: this.pendingEarningsPageSize,
            },
          })
          .then(response => {
            this.pendingEarnings = [
              ...this.pendingEarnings,
              response.data.pendingEarnings,
            ];
            if (this.pendingEarnings.length < this.pendingEarningsPageSize) {
              this.pendingEarningsLastBrandId = -1;
            } else {
              this.pendingEarningsLastBrandId = this.pendingEarnings[-1].brand_id;
            }
          })
          .catch(error => {
            new ToastBuilder()
                .setTitle("Fetch Pending Earnings Error")
                .setText(error.response.data.message)
                .setToastType(ToastBuilder.TOAST_TYPE_ERROR)
                .setTimeout(5000)
                .setClosable(true)
                .build();
          })
          .finally(() => {
            this.pendingEarningsIsLoading = false;
          });
    },
  },
});
