<template>
  <iImageContainer
    class="avatar"
    :src="myAvatarUrl"
    alt="avatar"
    object-fit="cover"
  />
</template>

<script>
import { DEFAULT_USER_AVATAR } from "%/constants/user-constants";

export default {
  name: "AvatarImage",
  props: {
    size: {
      type: String,
      required: true,
    },
    src: {
      type: String,
      required: true,
    },
  },
  computed: {
    myAvatarUrl() {
      if (this.src === "") {
        return DEFAULT_USER_AVATAR;
      }
      return this.src;
    },
  },
};
</script>

<style scoped lang="scss">
.avatar {
  border-radius: 100%;
  height: v-bind(size);
  width: v-bind(size);
}
</style>
