// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-3cf64de4] .i-select-dropdown {
  bottom: 0px;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/pages/publication/PublicationBranding.vue","webpack://./PublicationBranding.vue"],"names":[],"mappings":"AAEA;EACE,WAAA;ACDF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n:deep(.i-select-dropdown){\n  bottom:0px;\n}\n",":deep(.i-select-dropdown) {\n  bottom: 0px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
