<template>
  <Chatroom
    v-if="isChatroomLayout"
    :context="context"
    :entity-id="entityId"
    :conversation-id="conversationId"
    :channel-name="channelName"
    :description="description"
    :channel-href="channelHref"
  />
  <Comments
    v-else
    :context="context"
    :entity-id="entityId"
    :can-comment="canComment"
    :conversation-id="conversationId"
  />
</template>

<script>

import { mapState } from "pinia";
import { Enum } from "@bloglovin/vue-component-library";
import Comments from "%/components/community/chatrooms/comments/Comments";
import Chatroom from "%/components/community/chatrooms/slack-style/Chatroom";
import { useConversationStore } from "%/stores/conversation-store";

export default {
  name: "Conversation",
  components: {
    Chatroom,
    Comments,
  },
  props: {
    layout: {
      type: Enum,
      required: true,
      options: ["chatroom", "comments"],
    },
    context: {
      type: String,
      required: true,
    },
    entityId: {
      type: Number,
      required: true,
    },
    conversationId: {
      type: Number,
      required: false,
      default: 0,
    },
    channelName: {
      type: String,
      required: false,
      default: "",
    },
    description: {
      type: String,
      required: false,
      default: "",
    },
    channelHref: {
      type: String,
      required: false,
      default: "#",
    },
    canComment: {
      type: Boolean,
      required: false,
      default: false,
    },
    canLikeComments: {
      type: Boolean,
      required: false,
      default: false,
    },
    canReplyToComments: {
      type: Boolean,
      required: false,
      default: false,
    },
    canVoteOnComments: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapState(useConversationStore, ["conversation"]),
    isChatroomLayout() {
      return this.layout === "chatroom";
    },
  },
};
</script>
