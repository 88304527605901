<template>
  <iRow
    align="between"
    class="navbar"
    vertical-align="middle"
    wrap="nowrap"
  >
    <iRow
      width="hug"
      height="fill"
      class="nav-item-padding"
      vertical-align="middle"
      gap="none"
    >
      <Logo ref="logo" />
    </iRow>
    <iColumn class="nav-items" vertical-align="middle">
      <iMenu
        v-if="orgData.waitlist_status === waitlistStatus.approved"
        orientation="horizontal"
        :items="parsedPrimaryNavData"
        :active-item="activeItem"
        is-collapsable
        hide-collapsed-items
        :collapse-threshold="menuCollapseThreshold"
        @collapsed="updateDropdown"
      />
    </iColumn>

    <iRow width="hug" wrap="nowrap" vertical-align="middle">
      <iLink v-if="showDigitalWalletBalance" :href="`/digital-wallet/org/${orgId}`">
        {{ digitalWalletBalanceString }}
      </iLink>
      <iDropdownMenu
        v-click-outside="closeDropdown"
        :items="mobileMenu"
        :show-dropdown="showAvatarDropdown"
      >
        <template #trigger>
          <iColumn gap="extraSmall" vertical-align="middle">
            <iRow
              class="my-account-btn"
              gap="small"
              height="fill"
              vertical-align="middle"
              width="hug"
              wrap="nowrap"
              @click="toggleDropdown"
            >
              <iColumn vertical-align="middle">
                <AvatarImage :src="userData.avatar_url || ''" size="32px" />
              </iColumn>
              <iColumn gap="extraSmall">
                <iSpace />
                <iText>
                  {{ userData.first_name }}
                </iText>
              </iColumn>
            </iRow>
            <iSpace />
          </iColumn>
        </template>
      </iDropdownMenu>
    </iRow>
  </iRow>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useFanPublicationStore } from "%/stores/fan-publication-store";
import { useUserStore } from "~/stores/user-store";
import { useConfigurationStore } from "%/stores/configuration-store";
import AvatarImage from "%/components/AvatarImage";
import { useGeneralStore } from "%/stores/general-store";
import { useWindowStore } from "@bloglovin/vue-component-library";
import Logo from "@/components/Logo";
import { usePaymentStore } from "%/stores/payments-store";
import { filterMenuItems } from "@/helpers/capabilities-helper";
import { MAIN_NAV_PRIMARY, MAIN_NAV_SECONDARY } from "@/constants/nav-constants";
import { parseUrlVars } from "@/helpers/parseUrlVars";
import { waitlistStatus } from "@/enums/waitlist-enum";
import { DimensionsMixin } from "@bloglovin/vue-component-library";
export default {
  name: "MainNav",
  components: {
    Logo,
    AvatarImage,
  },
  mixins: [
    DimensionsMixin,
  ],
  props: {
    activeItem: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      showAvatarDropdown: false,
      waitlistStatus,
      collapsedItems: [],
    };
  },
  computed: {
    ...mapState(useConfigurationStore, [
      "primaryNavigation",
      "isSignupAndLoginEnabled",
      "areSubscriptionsAvailable",
    ]),
    ...mapState(useFanPublicationStore, [
      "publicationName",
      "canConfigurePublication",
    ]),
    ...mapState(useGeneralStore, [
      "userData",
      "userId",
      "orgData",
      "orgId",
      "isStaging",
    ]),
    ...mapState(useWindowStore, [
      "$vssMobile",
      "$vssTablet",
      "$vssDesktop",
    ]),
    ...mapState(usePaymentStore, [
      "digitalWalletBalanceString",
      "showDigitalWalletBalance",
    ]),
    menuCollapseThreshold() {
      return this.$getReferenceElementDimensions("logo").width;
    },
    parsedPrimaryNavData() {
      const filteredNavItems = filterMenuItems(MAIN_NAV_PRIMARY);
      return parseUrlVars(filteredNavItems, { "{org_id}": this.orgId, "{user_id}": this.userId });
    },
    parsedSecondaryNavData() {
      const filteredNavItems = filterMenuItems(MAIN_NAV_SECONDARY);
      return parseUrlVars(filteredNavItems, { "{org_id}": this.orgId, "{user_id}": this.userId });
    },
    mobileMenu() {
      const menu = [];
      if (this.orgData.waitlist_status === waitlistStatus.approved) {
        if (!this.$vssMobile) {
          menu.push(...this.collapsedItems);
        } else {
          menu.push(...this.parsedPrimaryNavData);
        }
      }
      menu.push(...this.parsedSecondaryNavData);

      return menu;
    },
    isUserLoggedIn() {
      return this.userId > 0;
    },

  },
  watch: {
  },
  methods: {
    toggleDropdown() {
      this.showAvatarDropdown = !this.showAvatarDropdown;
    },
    closeDropdown() {
      this.showAvatarDropdown = false;
    },
    updateDropdown(collapsedItems) {
      this.collapsedItems = collapsedItems;
    },
    ...mapActions(useGeneralStore, [
      "getRelativeHref",
      "redirectTo",
    ]),
    ...mapActions(useUserStore, [
      "logoutUser",
    ]),
  },
  styleGuide: () => ({
    navPaddingBlock: { "size.spacing": "extraSmall" },
    navPaddingInline: { "size.spacing": "large" },
    backgroundColor: { "color.background": "light" },
  }),
};
</script>

<style lang="scss" scoped>
.nav-items{
  overflow: hidden;
}
.navbar {
  padding: v-bind('$getStyles.navPaddingBlock') v-bind('$getStyles.navPaddingInline');
  //box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

:deep(.nav-link) {
  white-space: nowrap;
}
</style>
