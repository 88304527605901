// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./fonts/alegreya-sans-logo.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://cdn-alpha.inspiration.co/fonts/nunito.css);"]);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  height: 100vh;
  margin: 0;
  font-family: Nunito;
}
body > div {
  height: 100vh;
}

::selection {
  background: #ebff5d; /* WebKit/Blink Browsers */
  color: black;
}

::-moz-selection {
  background: #ebff5d; /* Gecko Browsers */
  color: black;
}

html {
  scrollbar-width: none;
}
html .col, html .row {
  scrollbar-width: none;
}
html .infinite-scroll-container {
  scrollbar-width: none;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/styles/style.scss"],"names":[],"mappings":"AAIA;EACE,aAAA;EACA,SAAA;EACA,mBAAA;AADF;AAKE;EACE,aAAA;AAHJ;;AAOA;EACE,mBAAA,EAAA,0BAAA;EACA,YAAA;AAJF;;AAMA;EACE,mBAAA,EAAA,mBAAA;EACA,YAAA;AAHF;;AAMA;EACE,qBAAA;AAHF;AAKE;EACE,qBAAA;AAHJ;AAME;EACE,qBAAA;AAJJ","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n@import url('https://cdn-alpha.inspiration.co/fonts/nunito.css');\n@import '@/styles/fonts/alegreya-sans-logo.css';\n\nbody{\n  height: 100vh;\n  margin: 0;\n  font-family: Nunito;\n\n  // this takes the first child of the body and sets it to 100vh (first mounted microfrontend)\n  // might need to change when we have multiple microfrontends\n  > div {\n    height: 100vh;\n  }\n}\n\n::selection {\n  background: #ebff5d; /* WebKit/Blink Browsers */\n  color: black;\n}\n::-moz-selection {\n  background: #ebff5d; /* Gecko Browsers */\n  color: black;\n}\n\nhtml {\n  scrollbar-width: none;\n\n  .col, .row {\n    scrollbar-width: none;\n  }\n\n  .infinite-scroll-container {\n    scrollbar-width: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
