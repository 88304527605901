<template>
  <PageWrapper
    :active-section="SECTION_SETTINGS"
    :active-page="PAGE_SOCIAL_NETWORK_SETTINGS"
    title="Social Networks"
    :breadcrumbs="settingsBreadcrumbs"
    page-name="Social Networks"
    align="left"
    width="full"
  >
    <iColumn gap="extraLarge">
      <iRow>
        <iColumn>
          <iRow>
            <iSubHeading>My Connections</iSubHeading>
          </iRow>

          <iRow>
            <iDataTable
              :items="connectedSocialNetworks"
              :fields="socialNetworksTableFields"
              primary-key="social_network_account_id"
              show-empty-state
              hide-actions
              empty-string="No connections found."
            >
              <template #td(social_network_name)="slotProps">
                <iRow vertical-align="middle">
                  <iIcon :icon="slotProps.data.social_network_name.toLowerCase()" />
                  <iText>{{ slotProps.data.social_network_name }}</iText>
                </iRow>
              </template>
              <template #td(status)="slotProps">
                <iRow vertical-align="middle">
                  <div
                    :class="{
                      dot: true,
                      '--color-success': [socialNetworkConnectionStatus.CONNECTED, socialNetworkConnectionStatus.MANUAL_CONNECTION].includes(slotProps.data.status),
                      '--color-warning': [socialNetworkConnectionStatus.BROKEN_CONNECTION].includes(slotProps.data.status),
                      '--color-error': [socialNetworkConnectionStatus.DISCONNECTED, socialNetworkConnectionStatus.NEVER_CONNECTED].includes(slotProps.data.status),
                    }"
                  />
                  <iText>{{ getConnectionStatus(slotProps.data.status) }}</iText>
                </iRow>
              </template>
              <template #td(actions)="slotProps">
                <iButton
                  variant="secondary"
                  size="small"
                  @click="manualDisconnect(slotProps.data.social_network_account_id, slotProps.data.social_network_name, orgId)"
                >
                  Disconnect
                </iButton>
              </template>
            </iDataTable>
          </iRow>
        </iColumn>
      </iRow>

      <iRow>
        <iColumn>
          <iRow>
            <iSubHeading>Add Connections</iSubHeading>
          </iRow>

          <iRow>
            <SocialNetworkCard
              v-for="socialNetwork in socialNetworkCards.slice(0, 4)"
              :key="socialNetwork['social_network_account_id']"
              :social-network="socialNetwork['social_network_name']"
              :social-network-id="socialNetwork['social_network_account_id']"
              :account-name="socialNetwork['account_name']"
              :status="socialNetwork['status']"
              @connect="connectSocialNetwork(socialNetwork['social_network_name'])"
            />
            <template
              v-if="viewAllSocialNetworks"
            >
              <SocialNetworkCard
                v-for="socialNetwork in socialNetworkCards.slice(4)"
                :key="socialNetwork['social_network_account_id']"
                :social-network="socialNetwork['social_network_name']"
                :social-network-id="socialNetwork['social_network_account_id']"
                :account-name="socialNetwork['account_name']"
                :status="socialNetwork['status']"
                @connect="connectSocialNetwork(socialNetwork['social_network_name'])"
                @disconnect="disconnect(socialNetwork['social_network_account_id'], socialNetwork['social_network_name'], orgId)"
              />
            </template>
            <iButton
              variant="tertiary"
              @click="toggleViewAllSocialNetworks"
            >
              {{ viewAllSocialNetworks ? "View less" : "View all" }}
            </iButton>
          </iRow>

          <iPromptModal
            v-if="isAddSocialNetworkModalVisible"
            :title="`Add ${currentSocialNetowork} Connection`"
            message="Simply enter your account name and we will save this to your account."
            button-text="Connect"
            label="Account Handle"
            @close="closeAndResetAddConnectionModal"
            @submit="manualConnectSocialNetwork"
          />
        </iColumn>
      </iRow>
    </iColumn>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import SocialNetworkCard from "@/components/social-networks/SocialNetworkCard";
import { SECTION_SETTINGS, PAGE_SOCIAL_NETWORK_SETTINGS } from "@/constants/nav-constants";
import { mapActions, mapState } from "pinia";
import { useGeneralStore } from "%/stores/general-store";
import { useSocialNetworksStore } from "@/stores/social-networks-store";
import { socialNetworkConnectionStatus } from "@/enums/social-network-enums";

export default {
  name: "SocialNetworks",
  components: {
    PageWrapper,
    SocialNetworkCard,
  },
  props: {},
  data() {
    return {
      SECTION_SETTINGS,
      PAGE_SOCIAL_NETWORK_SETTINGS,
      socialNetworksTableFields: [
        { key: "social_network_name", label: "Connection", width: "40%" },
        { key: "account_name", label: "Handle", width: "40%" },
        { key: "status", label: "Status", width: "20%" },
        { key: "actions", label: "", width: "140px" },
      ],
      socialNetworkConnectionStatus,
      viewAllSocialNetworks: false,
      isAddSocialNetworkModalVisible: false,
      currentSocialNetowork: "",
    };
  },
  computed: {
    ...mapState(useGeneralStore, [
      "orgId",
      "settingsBreadcrumbs",
    ]),
    ...mapState(useSocialNetworksStore, ["socialNetworks"]),
    socialNetworkCards() {
      let socialNetworkCards = this.socialNetworks.reduce((acc, socialNetwork) => {
        const containsSocialNetwork = acc.some(network => network.social_network_name === socialNetwork.social_network_name);
        if (!containsSocialNetwork) {
          acc.push(socialNetwork);
        }

        return acc;
      }, []);

      socialNetworkCards = socialNetworkCards.sort((a, b) => a.social_network_name.localeCompare(b.social_network_name));

      return socialNetworkCards;
    },
    connectedSocialNetworks() {
      const connectedStatuses = [socialNetworkConnectionStatus.CONNECTED, socialNetworkConnectionStatus.MANUAL_CONNECTION];
      return this.socialNetworks.filter(socialNetwork => connectedStatuses.includes(socialNetwork.status));
    },
  },
  methods: {
    ...mapActions(useSocialNetworksStore, ["connect", "disconnect", "manualConnect", "manualDisconnect"]),
    getConnectionStatus(status) {
      switch (status) {
        case socialNetworkConnectionStatus.CONNECTED:
        case socialNetworkConnectionStatus.MANUAL_CONNECTION:
          return "Connected";
        case socialNetworkConnectionStatus.BROKEN_CONNECTION:
          return "Broken Connection";
        case socialNetworkConnectionStatus.DISCONNECTED:
        case socialNetworkConnectionStatus.NEVER_CONNECTED:
          return "Disconnected";
        default:
          return "Unknown";
      }
    },
    toggleViewAllSocialNetworks() {
      this.viewAllSocialNetworks = !this.viewAllSocialNetworks;
    },
    openModal(socialNetwork) {
      this.isAddSocialNetworkModalVisible = true;
      this.currentSocialNetowork = socialNetwork;
    },
    connectSocialNetwork(socialNetwork) {
      switch (socialNetwork) {
        case "Twitter":
        case "LinkedIn":
          this.connect(socialNetwork, this.orgId);
          break;
        default:
          this.openModal(socialNetwork);
          break;
      }
    },
    manualConnectSocialNetwork(handle) {
      this.manualConnect(this.currentSocialNetowork, this.orgId, handle);
      this.closeAndResetAddConnectionModal();
    },
    closeAndResetAddConnectionModal() {
      this.isAddSocialNetworkModalVisible = false;
      this.currentSocialNetowork = "";
    },
  },
};
</script>

<style scoped lang="scss">
.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;

  &.--color {
    &-success {
      background-color: v-bind('$getStyleValuePropertiesFromTheme("color.font")["success"]');
    }

    &-warning {
      background-color: v-bind('$getStyleValuePropertiesFromTheme("color.font")["accent"]');
    }

    &-error {
      background-color: v-bind('$getStyleValuePropertiesFromTheme("color.font")["error"]');
    }
  }
}
</style>
