<template>
  <iColumn class="subscriptions">
    <iRow vertical-align="middle">
      <iSubHeading>Subscription Plans</iSubHeading>
      <iColumn width="hug">
        <iButton
          v-if="!isAddSubscriptionPlanModalOpen"
          variant="secondary"
          size="small"
          @click="openAddSubscriptionPlanModal"
        >
          Add Plan
        </iButton>
      </iColumn>
    </iRow>
    <iColumn :height="500">
      <SmartFeed
        :pinia-store="useMySubscriptionPlanFeedStore"
        default-smart-feed-layout="grid"
        default-entity-layout="cardSmall"
      />
    </iColumn>
    <CreateSubscriptionModal
      :visible="isCreateSubscriptionModalOpen"
    />
  </iColumn>
</template>

<script>
import CreateSubscriptionModal from "@/components/publication/CreateSubscriptionModal.vue";
import { useConfigurationStore } from "%/stores/configuration-store";
import { MAPPED_CURRENCY_CODES } from "@/constants/currency-constants";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import { usePublicationDigitalProductsStore } from "@/stores/publication-digital-products-store";
import { mapActions, mapState } from "pinia";
import SmartFeed from "SmartFeed/components/SmartFeed.vue";
import { useMySubscriptionPlanFeedStore } from "SmartFeed/stores/feed/my-subscription-plan-feed-store";
import { useSubscriptionStore } from "@/stores/subscription-store";

export default {
  name: "MyPublicationSubscriptions",
  components: {
    SmartFeed,
    CreateSubscriptionModal,
  },
  data() {
    return {
      isCreateSubscriptionModalOpen: false,
    };
  },
  computed: {
    ...mapState(useConfigurationStore, ["availableSubscriptionPlans"]),
    ...mapState(useCreatorPublicationStore, [
      "activeSubscriptionPlans",
      "validBillingFrequencies",
    ]),
    ...mapState(usePublicationDigitalProductsStore, [
      "publicationDigitalProducts",
    ]),
    ...mapState(useSubscriptionStore, [
      "isAddSubscriptionPlanModalOpen",
    ]),
    currencyCodes() {
      return MAPPED_CURRENCY_CODES;
    },
  },
  methods: {
    useMySubscriptionPlanFeedStore,
    ...mapActions(useConfigurationStore, [
      "updateConfiguration",
      "updateSubscriptionPlan",
    ]),
    ...mapActions(useSubscriptionStore, [
      "openAddSubscriptionPlanModal",
    ]),
  },
};
</script>

<style scoped></style>
