<template>
  <iSpace :width="30" />
  <iCard class="card">
    <template #body>
      <NewReply
        context="reply"
        :entity-id="messageId"
        :conversation-id="conversationId"
      />
      <iColumn
        v-for="(reply, index) in replies"
        :key="index"
        :class="{deleted: reply.status === 'inactive'}"
        width="fill"
        gap="extraSmall"
      >
        <ConversationHeader
          :date="reply.timestampCreated"
          :author-name="reply.authorName"
          :author-avatar="reply.avatarUrl"
          :author-id="reply.userId"
        />
        <MessageFlags
          :status="reply.status"
          :moderation-status="reply.moderationStatus"
          :timestamp-updated="reply.timestampUpdated"
        />
        <iRow>
          <iColumn v-if="isEditing && editingId === reply.messageId">
            <iEditor
              v-model="newCommentReply"
              name="editComment"
              @enter="saveEdit"
            />
            <iRow>
              <iColumn width="hug">
                <iButton @click="saveEdit">
                  Save
                </iButton>
              </iColumn>
              <iColumn width="hug">
                <iButton @click="cancelEditing">
                  Cancel
                </iButton>
              </iColumn>
            </iRow>
          </iColumn>
          <iColumn v-else>
            <iText>
              {{ reply.message }}
            </iText>
          </iColumn>
        </iRow>
        <iColumn>
          <iText v-if="reply.userId == userId && reply.moderationStatus == 'unknown'" variant="subtle">
            Not visible, pending moderation.
          </iText>
          <iText v-if="reply.userId == userId && reply.moderationStatus == 'blocked'" variant="subtle">
            This message has been blocked.
          </iText>
          <iText v-if="errorMessage" variant="subtle">
            {{ errorMessage }}
          </iText>
          <iRow v-if="isModerator && moderationEnabled" align="between">
            <iText variant="accent">
              Report Count: {{ reply.numReports }}
            </iText>
            <iText variant="accent">
              moderation score: {{ reply.moderationScore }}
            </iText>
          </iRow>
        </iColumn>
        <ConversationFooter
          :current-message="reply.message"
          :author-id="reply.userId"
          :message-id="reply.messageId"
          :vote-score="reply.voteScore"
          :num-replies="reply.numApprovedReplies"
          :num-likes="reply.numLikes"
          :moderation-status="reply.moderationStatus"
          :status="reply.status"
          :user-liked="reply.userLiked"
          :user-up-voted="reply.userUpVoted"
          :user-down-voted="reply.userDownVoted"
          :allow-replying="false"
          @start-editing="turnOnIsEditing(reply.messageId, reply.message)"
          @report="report(reply)"
          @approve="approve(reply.messageId)"
          @escalate="escalate(reply.messageId)"
          @block="block(reply.messageId)"
          @delete="deleteComment(reply.messageId)"
          @undelete="unDeleteComment(reply.messageId)"
          @like="like(reply.messageId)"
          @unlike="unlike(reply.messageId)"
          @upvote="upVote(reply.messageId)"
          @revokeupvote="revokeUpVote(reply.messageId)"
          @downvote="downVote(reply.messageId)"
          @revokedownvote="revokeDownVote(reply.messageId)"
        />
        <iSpace :height="10" />
      </iColumn>
    </template>
  </iCard>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useConversationStore } from "%/stores/conversation-store";
import { useGeneralStore } from "%/stores/general-store";
import ConversationFooter from "%/components/community/chatrooms/comments/ConversationFooter";
import ConversationHeader from "%/components/community/chatrooms/comments/ConversationHeader";
import MessageFlags from "%/components/community/chatrooms/comments/MessageFlags";
import NewReply from "%/components/community/chatrooms/comments/NewReply";

export default {
  name: "ReplyWidget",
  components: {
    ConversationHeader,
    ConversationFooter,
    MessageFlags,
    NewReply,
  },
  props: {
    message: {
      type: String,
      required: true,
    },
    messageId: {
      type: Number,
      required: true,
    },
    conversationId: {
      type: Number,
      required: true,
    },
    replies: {
      type: Array,
      required: true,
    },
    canLikeComments: {
      type: Boolean,
      required: false,
      default: false,
    },
    canReplyToComments: {
      type: Boolean,
      required: false,
      default: false,
    },
    canVoteOnComments: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isEditing: false,
      editingId: 0,
      isReplying: false,
      newCommentReply: "",
      showMenuOptions: false,
      showReportOptions: false,
    };
  },
  computed: {
    ...mapState(useGeneralStore, ["userId"]),
    ...mapState(useConversationStore, [
      "errorMessage",
      "isModerator",
      "moderationEnabled",
    ]),
    isAuthor() {
      return this.userId === this.userId;
    },
  },
  methods: {
    ...mapActions(useConversationStore, {
      editMessage: "editMessage",
      likeMessage: "likeMessage",
      deleteMessage: "deleteMessage",
      undeleteMessage: "undeleteMessage",
      reportMessage: "reportMessage",
      submitSorting: "submitSorting",
      upVoteMessage: "upVoteMessage",
      replyToMessage: "replyToMessage",
      approveMessage: "approveMessage",
      downVoteMessage: "downVoteMessage",
      blockMessage: "blockMessage",
      escalateMessage: "escalateMessage",
      unLikeMessage: "unLikeMessage",
      revokeUpVoteMessage: "revokeUpVoteMessage",
      revokeDownVoteMessage: "revokeDownVoteMessage",
    }),
    turnOnIsEditing(messageId, replyMessage) {
      this.newCommentReply = replyMessage;
      this.editingId = messageId;
      this.isEditing = true;
    },
    turnOnReply() {
      this.fetchRepliesToMessage(this.messageId);
      this.isReplying = true;
    },
    toggleCommentMenu() {
      this.showMenuOptions = true;
    },
    showReportsMenu() {
      this.showMenuOptions = false;
      this.showReportOptions = true;
    },
    saveEdit() {
      if (this.newCommentReply === "") {
        return;
      }
      this.isEditing = false;
      this.editMessage(this.editingId, this.newCommentReply);
    },
    cancelEditing() {
      this.isEditing = false;
    },
    deleteComment(id) {
      this.deleteMessage(id);
      this.showMenuOptions = false;
    },
    unDeleteComment(id) {
      this.undeleteMessage(id);
      this.showMenuOptions = false;
    },
    report(reportData) {
      this.showReportOptions = false;
      this.reportMessage(reportData.id, reportData.reason);
    },
    messageReply(id, newMessage) {
      this.replyToMessage(id, newMessage);
    },
    like(id) {
      this.likeMessage(id);
    },
    downVote(id) {
      this.downVoteMessage(id);
    },
    upVote(id) {
      this.upVoteMessage(id);
    },
    block(blockData) {
      this.blockMessage(blockData.id, blockData.reason);
    },
    approve(id) {
      this.approveMessage(id);
    },
    escalate(id) {
      this.escalateMessage(id);
    },
    unlike(id) {
      this.unLikeMessage(id);
    },
    revokeUpVote(id) {
      this.revokeUpVoteMessage(id);
    },
    revokeDownVote(id) {
      this.revokeDownVoteMessage(id);
    },
  },
};
</script>

<style scoped lang="scss">
.card {
  overflow: visible!important;
}
.deleted {
  background-color: #bdbdbd;
}
</style>
