import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";

export const useFeedGroupFeedStore = defineSmartFeedPiniaStore("feed-group-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    feed: [],
    metadata: {
      hasMore: true,
      lastId: Number.MAX_SAFE_INTEGER,
      publicationId: useCreatorPublicationStore().publicationId,
    },
    isLoading: false,
  }),
  getters: {
    hasMore() {
      return this.metadata.hasMore;
    },
  },
  actions: {
    reload() {
      this.$reset();
      this.getSmartFeed();
    },
    getSmartFeed() {
      this.apiGet(
        `/feeds/publication/${this.metadata.publicationId}/feed-groups/smart-feed`,
        {
          last_id: this.metadata.lastId,
        },
        data => this.handleSmartFeedResponse(data),
        () => {
          this.hasMore = false;
        },
      );
    },
  },
});
