<template>
  <iColumn class="new-comment-container">
    <ConversationHeader
      :author-name="userData.first_name"
      :author-avatar="userAvatar"
      :show-date-sent="false"
      :author-id="userData.user_id"
    />
    <iEditor
      v-model="replyMessage"
      name="comment"
      placeholder="Write a reply..."
      class="comment-input"
      @enter="addComment"
    />
    <iColumn width="hug">
      <iButton loading-spinner-position="inside" :is-loading="replyToMessageIsLoading" @click="addComment">
        Send Reply
      </iButton>
    </iColumn>
  </iColumn>
</template>

<script>
import { mapState, mapActions, mapWritableState } from "pinia";
import { useGeneralStore } from "%/stores/general-store";
import { useConversationStore } from "%/stores/conversation-store";
import ConversationHeader from "%/components/community/chatrooms/comments/ConversationHeader";

export default {
  name: "NewReply",
  components: {
    ConversationHeader,
  },
  props: {
    context: {
      type: String,
      required: false,
      default: "",
    },
    entityId: {
      type: Number,
      required: false,
      default: 0,
    },
    conversationId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      replyMessage: "",
    };
  },
  computed: {
    ...mapState(useGeneralStore, ["userAvatar", "userData"]),
    ...mapWritableState(useConversationStore, ["draftMessage"]),
    ...mapState(useConversationStore, ["isLoading", "isMessageLoading"]),
    replyToMessageIsLoading() {
      if (this.isMessageLoading && this.isMessageLoading[this.entityId]?.reply) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions(useConversationStore, ["replyToMessage"]),
    addComment() {
      if (this.replyMessage === "") {
        return;
      }
      this.replyToMessage(this.entityId, this.replyMessage);
      this.replyMessage = "";
    },
  },
};
</script>

<style scoped lang="scss">
.comment-input {
  width: 600px!important;
  @include phone {
    min-width: unset!important;
    width: inherit!important;
  }
}

.new-comment-container {
  padding-bottom: 16px;
  border-bottom: 1px;
  border-style: inset;
  margin-bottom: 8px;
}
</style>
