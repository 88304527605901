// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reply-btn[data-v-5b8003d2] {
  cursor: pointer;
}
[data-v-5b8003d2] .user-avatar img {
  border-radius: var(--5b8003d2-\\\$getStyles\\.borderRadius);
}
.reply-time[data-v-5b8003d2] {
  position: relative;
  top: 1px;
}`, "",{"version":3,"sources":["webpack://./src/SharedWeb/scripts/components/community/chatrooms/slack-style/ConversationFooter.vue","webpack://./ConversationFooter.vue"],"names":[],"mappings":"AAEA;EACE,eAAA;ACDF;ADIA;EACE,wDAAA;ACDF;ADIA;EACE,kBAAA;EACA,QAAA;ACDF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.reply-btn {\n  cursor: pointer;\n}\n\n:deep(.user-avatar img) {\n  border-radius: v-bind(\"$getStyles.borderRadius\");\n}\n\n.reply-time {\n  position: relative;\n  top: 1px;\n}\n",".reply-btn {\n  cursor: pointer;\n}\n\n:deep(.user-avatar img) {\n  border-radius: v-bind(\"$getStyles.borderRadius\");\n}\n\n.reply-time {\n  position: relative;\n  top: 1px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
