<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_COLLABORATING_PUBLICATIONS"
    title="Collaborating Publications"
    :breadcrumbs="breadcrumbs"
    page-name="Collaborating Publications"
    align="left"
    width="full"
  >
    <iRow class="collab-pubs-wrapper">
      <PublicationCard
        v-for="(publication, index) in publications"
        :key="index"
        use-public-domain
        :publication="publication"
      />
    </iRow>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import PublicationCard from "@/components/PublicationCard";
import {
  PAGE_COLLABORATING_PUBLICATIONS,
  PAGE_MY_PUBLICATIONS,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import { mapState } from "pinia";

import { useCreatorPublicationStore } from "%/stores/creator-publication-store";

export default {
  name: "CollaboratingPublications",
  components: { PublicationCard, PageWrapper },
  data() {
    return {
      PAGE_MY_PUBLICATIONS,
      SECTION_PUBLICATIONS,
      PAGE_COLLABORATING_PUBLICATIONS,
    };
  },
  computed: {
    ...mapState(useCreatorPublicationStore, ["orgId"]),
    ...mapState(useCreatorPublicationStore, {
      publications: "collaboratingPublications",
    }),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
      ];
    },
  },
};
</script>
