import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import { useGeneralStore } from "%/stores/general-store";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
export const useMySubscriptionPlanFeedStore = defineSmartFeedPiniaStore("my-subscription-plan-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    feed: [],
    metadata: {
      hasMore: true,
      lastId: 0,
      publicationId: useCreatorPublicationStore().publicationId,
      orgId: useGeneralStore().orgId,
    },
    isLoading: false,
  }),
  getters: {
    hasMore() {
      return this.metadata.hasMore;
    },
  },
  actions: {
    getSmartFeed() {
      this.apiGet(
        `/subscription/${this.metadata.publicationId}/my-subscription-plans/smart-feed`,
        {
          last_id: this.metadata.lastId,
        },
        data => this.handleSmartFeedResponse(data),
        () => {
          this.metadata.hasMore = false;
        },
      );
    },
  },
});
