<template>
  <iColumn>
    <iRow>
      <iRow>
        <iTextEdit
          clear-value-on-submit
          clear-value-on-cancel
          :value="createCollectionName"
          placeholder="Create A New Collection"
          @submit="createNewCollection"
        />
      </iRow>
    </iRow>
    <iRow
      v-for="(collection, collectionIndex) in collectionsWithSelectedStatusForLink"
      :key="collectionIndex"
      wrap="nowrap"
      :height="100"
    >
      <iColumn width="hug" :height="100">
        <iImageContainer
          :src="collection.cover_image === '' ? DEFAULT_COLLECTION_COVER_IMAGE : collection.cover_image"
          class="pointer"
          :height="100"
          :width="100"
          object-fit="contain"
          @click="handleClickToggle(collectionIndex)"
        />
      </iColumn>
      <iColumn :height="100" vertical-align="middle" gap="none">
        <iRow>
          <iSubHeading class="pointer" @click="handleClickToggle(collectionIndex)">
            {{ collection.name }}
          </iSubHeading>
        </iRow>
        <iRow>
          <iText variant="subtle">
            {{ collection.category }}
          </iText>
        </iRow>
      </iColumn>
      <iColumn :height="100" width="hug" vertical-align="middle">
        <iCheckbox
          v-model="collection.selected"
          @change="handleCollectionSelect(collection)"
        />
      </iColumn>
    </iRow>
    <iRow align="center">
      <iLoadingSpinner v-if="isLoading" />
    </iRow>
  </iColumn>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { useAffiliateLinkStore } from "@/stores/affiliate-link-store";
import { DEFAULT_COLLECTION_COVER_IMAGE } from "@/constants/affiliate-constants";

export default {
  name: "SelectAffiliateCollection",
  props: {
    affiliateLinkId: {
      type: Number,
      required: true,
    },
  },
  emits: ["select"],
  data() {
    return {
      DEFAULT_COLLECTION_COVER_IMAGE,
      addingCollection: false,
      createCollectionName: "",
    };
  },
  computed: {
    ...mapState(useAffiliateLinkStore, [
      "loadingFetchCollectionsContainingLink",
      "loadingFetchAffiliateLinkCollections",
      "loadingAffiliateLinkCollection",
    ]),
    ...mapWritableState(useAffiliateLinkStore, [
      "collectionsWithSelectedStatusForLink",
    ]),
    isLoading() {
      return this.loadingFetchCollectionsContainingLink || this.loadingFetchAffiliateLinkCollections;
    },
  },
  created() {
    this.fetchCollectionsWithSelectedStatusForLink(this.affiliateLinkId);
  },
  methods: {
    ...mapActions(useAffiliateLinkStore, [
      "addToAffiliateLinkCollection",
      "removeFromAffiliateLinkCollection",
      "fetchCollectionsWithSelectedStatusForLink",
      "createAffiliateLinkCollection",
    ]),
    createNewCollection(name) {
      this.addingCollection = true;
      this.createAffiliateLinkCollection(name, this.affiliateLinkId).then(() => {
        this.addingCollection = false;
        this.createCollectionName = "";
      });
    },
    handleClickToggle(collectionIndex) {
      this.collectionsWithSelectedStatusForLink[collectionIndex].selected = !this.collectionsWithSelectedStatusForLink[collectionIndex].selected;
      this.handleCollectionSelect(this.collectionsWithSelectedStatusForLink[collectionIndex]);
    },
    handleCollectionSelect(collection) {
      if (collection.selected) {
        this.addToAffiliateLinkCollection(collection.affiliate_link_collection_id, this.affiliateLinkId);
      } else {
        this.removeFromAffiliateLinkCollection(collection.affiliate_link_collection_id, this.affiliateLinkId);
      }
      this.$emit("select", collection);
    },
  },
};
</script>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
</style>
