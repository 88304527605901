import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";

export const useMyDigitalProductPurchaseFeedStore =
  defineSmartFeedPiniaStore("my-digital-product-purchase-feed-store", {
    state: () => ({
      smartFeedEngine: new SmartFeedEngine(),
      feed: [],
      metadata: {
        hasMore: true,
        lastId: 0,
      },
      isLoading: false,
      tableFields: [
        {
          key: "publicationName",
          label: "Publication Name",
        },
        {
          key: "productName",
          label: "Product Name",
        },
        {
          key: "purchaseDate",
          label: "Purchase Date",
        },
        {
          key: "pricePaid",
          label: "Price Paid",
        },
        {
          key: "fileDownloadUrl",
          label: "Download URL",
        },
      ],
    }),
    getters: {
      hasMore() {
        return this.metadata.hasMore;
      },
    },
    actions: {
      resetFeed() {
        this.metadata.lastId = 0;
        this.metadata.hasMore = true;
        this.feed = [];
      },
      getSmartFeed() {
        this.isLoading = true;
        this.apiGet(
          "/digital-product-purchase/smart-feed",
          {
            last_id: this.metadata.lastId,
          },
          data => {
            this.handleSmartFeedResponse(data);
            this.isLoading = false;
          },
          () => {
            this.metadata.hasMore = false;
            this.isLoading = false;
          },
        );
      },
    },
  });
