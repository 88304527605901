<template>
  <iRow gap="huge" wrap="wrap">
    <PublicationSettingsCard
      v-for="(settings, key) in proPublicationsMap"
      :key="key"
      :description="settings.description"
      :title="settings.title"
      :features="settings.features"
      :focused="isFocused(settings)"
      @select="selectSetting(key)"
    />
  </iRow>
</template>

<script>
import PublicationSettingsCard from "@/components/publication/PublicationLoginSettingsCard.vue";
import { useConfigurationStore } from "%/stores/configuration-store";

import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import { mapActions, mapState } from "pinia";

export default {
  name: "PublicationProPlans",
  components: {
    PublicationSettingsCard,
  },
  computed: {
    ...mapState(useConfigurationStore, [
      "canSignUp",
      "isPrivatePublication",
      "requireAccountApproval",
    ]),
    ...mapState(useCreatorPublicationStore, ["isProTier"]),
    selectedSetting() {
      return Object.values(this.proPublicationsMap).find(setting => {
        let canSignUp = this.canSignUp === setting.features.canSignUp;
        let isPrivatePublication =
          this.isPrivatePublication === setting.features.isPrivatePublication;
        let requireAccountApproval =
          this.requireAccountApproval ===
          setting.features.requireAccountApproval;

        return canSignUp && isPrivatePublication && requireAccountApproval;
      });
    },
    proPublicationsMap() {
      return {
        readOnlySite: {
          title: "Basic",
          description: "No signup no users everything visible to the public",
          features: {
            isPrivatePublication: false,
            canSignUp: false,
            requireAccountApproval: false,
          },
        },
        signupOptional: {
          title: "Inspiration Pro",
          description:
            "All content visible to the public some features, like commenting, require signup",
          features: {
            isPrivatePublication: false,
            canSignUp: true,
            requireAccountApproval: false,
          },
        },
        signupRequired: {
          title: "Inspiration Pro Plus",
          description:
            "Anyone can signup but all content is hidden from the public",
          features: {
            isPrivatePublication: true,
            canSignUp: true,
            requireAccountApproval: false,
          },
        },
      };
    },
    // TODO: this a hack for now until we have the proper pro features
    isFocused() {
      return settings => {
        const isProPlan = settings.title === "Inspiration Pro";
        const isBasicPlan = settings.title === "Basic";

        if (this.isProTier) {
          return isProPlan;
        }

        return isBasicPlan;
      };
    },
  },
  methods: {
    ...mapActions(useConfigurationStore, ["updateConfiguration"]),
    selectSetting(settingKey) {
      let selectedSignupConfiguration = this.proPublicationsMap[settingKey];
      this.updateConfiguration(selectedSignupConfiguration.features);
    },
  },
};
</script>
