// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feature-config-row[data-v-3a264cbf] {
  padding: 10px;
}
.toggled[data-v-3a264cbf] {
  background-color: rgb(149, 255, 0);
}`, "",{"version":3,"sources":["webpack://./src/SharedWeb/scripts/components/FeatureConfig.vue","webpack://./FeatureConfig.vue"],"names":[],"mappings":"AAEA;EACE,aAAA;ACDF;ADIA;EACE,kCAAA;ACDF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.feature-config-row {\n  padding: 10px;\n}\n\n.toggled {\n  background-color: rgb(149,255,0);\n}\n",".feature-config-row {\n  padding: 10px;\n}\n\n.toggled {\n  background-color: rgb(149, 255, 0);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
