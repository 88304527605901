import { defineStore } from "pinia";
import { useGeneralStore } from "%/stores/general-store";
import { links } from "%/enums/link-enums";

export const useThemesStore = defineStore("themes-store", {
  state: () => ({
    themes: [],
    isLoadingThemes: false,
    hasMoreThemes: false,
    lastThemeId: 0,
    isLoadingPostTheme: false,
    themeId: null,
    deletingThemeWithId: null,

    themePalettes: [],
    isLoadingThemePalettes: false,
    hasMorePalettes: false,
    lastPaletteId: 0,
    isLoadingPostPalette: false,
    themePaletteId: null,
    deletingPaletteWithId: null,
  }),
  getters: {
    apiV1UrlForThemes() {
      return useGeneralStore().getLink(links.api) + `/themes/org/${useGeneralStore().orgId}`;
    },
    appId() {
      return useGeneralStore().appId;
    },
    mappedThemes() {
      const mappedThemes = this.themes.map(theme => {
        return {
          name: theme.name,
          value: theme.theme_id.toString(),
        };
      });

      return [
        {
          name: "Default Theme",
          value: "0",
        },
        ...mappedThemes,
      ];
    },
  },
  actions: {
    fetchThemes() {
      this.isLoadingThemes = true;
      this.apiGet(`${this.apiV1UrlForThemes}/page`, {
        last_id: this.lastThemeId,
      }, data => {
        this.themes = [...this.themes, ...data.themes];
        this.hasMoreThemes = data.hasMore;
        this.lastThemeId = data.themes.at(-1)?.theme_id;
        this.isLoadingThemes = false;
      }, () => {
        this.isLoadingThemes = false;
      });
    },
    fetchThemePalettes() {
      this.isLoadingThemePalettes = true;
      this.apiGet(`${this.apiV1UrlForThemes}/palettes/page`, {
        last_id: this.lastPaletteId,
      }, data => {
        this.themePalettes = [...this.themePalettes, ...data.palettes];
        this.hasMorePalettes = data.hasMore;
        this.lastPaletteId = data.palettes.at(-1)?.palette_id;
        this.isLoadingThemePalettes = false;
      }, () => {
        this.isLoadingThemePalettes = false;
      });
    },
    createThemePalette(palette) {
      this.isLoadingPostPalette = true;
      return this.apiPost(`${this.apiV1UrlForThemes}/palettes`, {
        palette,
      }, () => {
        document.location.href = `/publication/org/${useGeneralStore().orgId}/theme-palettes`;
        this.isLoadingPostPalette = false;
      }, () => {
        this.isLoadingPostPalette = false;
      });
    },
    deployToStaging(palette) {
      this.isLoadingPostPalette = true;
      return this.apiPost(`${this.apiV1UrlForThemes}/palettes/${this.themePaletteId}/deploy/staging`, {
        palette,
      }, () => {
        // reload the page
        window.location.reload();
        this.isLoadingPostPalette = false;
      }, () => {
        this.isLoadingPostPalette = false;
      });
    },
    deployToProduction(palette) {
      this.isLoadingPostPalette = true;
      return this.apiPost(`${this.apiV1UrlForThemes}/palettes/${this.themePaletteId}/deploy/production`, {
        palette,
      }, () => {
        document.location.href = `/publication/org/${useGeneralStore().orgId}/theme-palettes`;
        this.isLoadingPostPalette = false;
      }, () => {
        this.isLoadingPostPalette = false;
      });
    },
    cloneThemePalette(palette) {
      this.isLoadingPostPalette = true;
      return this.apiPost(`${this.apiV1UrlForThemes}/palettes/${this.themePaletteId}/clone`, {
        palette,
      }, () => {
        document.location.href = `/publication/org/${useGeneralStore().orgId}/theme-palettes`;
        this.isLoadingPostPalette = false;
      }, () => {
        this.isLoadingPostPalette = false;
      });
    },
    createTheme(theme) {
      this.isLoadingPostTheme = true;
      return this.apiPost(`${this.apiV1UrlForThemes}`, {
        theme,
      }, () => {
        document.location.href = `/publication/org/${useGeneralStore().orgId}/themes`;
        this.isLoadingPostTheme = false;
      }, () => {
        this.isLoadingPostTheme = false;
      });
    },
    deployThemeToStaging(theme) {
      this.isLoadingPostTheme = true;
      return this.apiPost(`${this.apiV1UrlForThemes}/${this.themeId}/deploy/staging`, {
        theme,
      }, () => {
        // reload the page
        window.location.reload();
        this.isLoadingPostTheme = false;
      }, () => {
        this.isLoadingPostTheme = false;
      });
    },
    deployThemeToProduction(theme) {
      this.isLoadingPostTheme = true;
      return this.apiPost(`${this.apiV1UrlForThemes}/${this.themeId}/deploy/production`, {
        theme,
      }, () => {
        document.location.href = `/publication/org/${useGeneralStore().orgId}/themes`;
        this.isLoadingPostTheme = false;
      }, () => {
        this.isLoadingPostTheme = false;
      });
    },
    cloneTheme(theme) {
      this.isLoadingPostTheme = true;
      return this.apiPost(`${this.apiV1UrlForThemes}/${this.themeId}/clone`, {
        theme,
      }, () => {
        document.location.href = `/publication/org/${useGeneralStore().orgId}/themes`;
        this.isLoadingPostTheme = false;
      }, () => {
        this.isLoadingPostTheme = false;
      });
    },
  },
});
