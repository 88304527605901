<template>
  <PublicationFeatureWrapper heading="Pages">
    <iButton @click="openCreateNewPublicationPageModal">
      Create New Page
    </iButton>
    <SmartFeed :pinia-store="usePublicationPageFeedStore" default-entity-layout="tableRow" default-smart-feed-layout="table" />
    <iModal
      :visible="isCreateNewPublicationPageModalOpen"
      title="Create New Publication Page"
      @close="closeCreateNewPublicationPageModal"
      @click:primary="handleCreateNewPublicationPage"
    >
      <template #body>
        <iTextInput v-model="pageName" placeholder="Page Name" />
      </template>
    </iModal>
  </PublicationFeatureWrapper>
</template>

<script>
import SmartFeed from "SmartFeed/components/SmartFeed";
import { usePublicationPageFeedStore } from "SmartFeed/stores/feed/publication-page-feed-store";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import { mapActions, mapState } from "pinia";
import PublicationFeatureWrapper from "@/components/publication/features/PublicationFeatureWrapper";

export default {
  name: "PublicationPages",
  components: {
    SmartFeed,
    PublicationFeatureWrapper,
  },
  data() {
    return {
      isCreateNewPublicationPageModalOpen: false,
      pageName: "",
    };
  },
  computed: {
    ...mapState(useCreatorPublicationStore, ["publicationId"]),
    internalStagingDomain() {
      return `${window.location.origin}/my-publication/staging/${this.publicationId}`;
    },
  },
  methods: {
    ...mapActions(useCreatorPublicationStore, ["createPublicationPage"]),
    usePublicationPageFeedStore,
    handleCreateNewPublicationPage() {
      this.createPublicationPage(this.pageName, page => {
        window.location.href = `${this.internalStagingDomain}/dynamic-components/${page.dynamic_component_id}`;
      });
    },
    openCreateNewPublicationPageModal() {
      this.isCreateNewPublicationPageModalOpen = true;
    },
    closeCreateNewPublicationPageModal() {
      this.isCreateNewPublicationPageModalOpen = false;
    },
  },
};
</script>
