<template>
  <PublicationFeatureWrapper
    heading="Engagement"
    description="You can control how users interact with your content. Toggle the settings below to enable or disable the features you want"
  >
    <iTitle v-if="!isSignupAndLoginEnabled">
      Login and Signup must be enabled to access these features
    </iTitle>

    <iToggleSwitch
      :model-value="canLikePosts"
      label="Liking posts"
      description="Allow users to like your posts"
      :disabled="!isSignupAndLoginEnabled"
      @change="updateEngagementConfiguration('canLikePosts', $event)"
    />

    <iToggleSwitch
      :model-value="canVoteOnPosts"
      label="Voting On Posts"
      description="Allow users to vote on your posts"
      :disabled="!isSignupAndLoginEnabled"
      @change="updateEngagementConfiguration('canVoteOnPosts', $event)"
    />

    <iColumn>
      <iToggleSwitch
        :model-value="canCommentOnPosts"
        label="Commenting on posts"
        description="Allow users to comment on your posts"
        :disabled="!isSignupAndLoginEnabled"
        @change="updateEngagementConfiguration('canCommentOnPosts', $event)"
      />
      <iText>
        Your publications' posts can have comments, and those can have different
        interactions. Choose which ones of the interactions you want your fans
        and collaborators to use on the posts.
      </iText>
      <iColumn gap="small">
        <iRow vertical-align="middle">
          <iCheckbox
            :model-value="canLikeComments"
            label="Allow users to like comments"
            :disabled="!canCommentOnPosts || !isSignupAndLoginEnabled"
            @change="updateEngagementConfiguration('canLikeComments', $event)"
          />
        </iRow>
        <iRow vertical-align="middle">
          <iCheckbox
            :model-value="canVoteOnComments"
            label="Allow users to vote on comments"
            :disabled="!canCommentOnPosts || !isSignupAndLoginEnabled"
            @change="updateEngagementConfiguration('canVoteOnComments', $event)"
          />
        </iRow>
        <iRow vertical-align="middle">
          <iCheckbox
            :model-value="canReplyToComments"
            label="Allow users to reply to comments"
            :disabled="!canCommentOnPosts || !isSignupAndLoginEnabled"
            @change="updateEngagementConfiguration('canReplyToComments', $event)"
          />
        </iRow>
      </iColumn>
    </iColumn>
  </PublicationFeatureWrapper>
</template>

<script>
import { PUBLICATION_COMMENTS_DEFAULT_CONFIG } from "%/constants/publication-constants";
import { useConfigurationStore } from "%/stores/configuration-store";
import { useCreatorPublicationStore } from "%/stores/creator-publication-store";
import { mapActions, mapState } from "pinia";
import PublicationFeatureWrapper from "@/components/publication/features/PublicationFeatureWrapper";

export default {
  name: "PublicationEngagement",
  components: {
    PublicationFeatureWrapper,
  },
  data() {
    return {
      PUBLICATION_COMMENTS_DEFAULT_CONFIG,
    };
  },
  computed: {
    ...mapState(useCreatorPublicationStore, [
      "orgId",
      "publication",
      "posts",
      "isLoading",
    ]),
    ...mapState(useConfigurationStore, [
      "isSignupAndLoginEnabled",
      "canLikePosts",
      "canVoteOnPosts",
      "canCommentOnPosts",
      "canLikeComments",
      "canVoteOnComments",
      "canReplyToComments",
    ]),
  },
  methods: {
    ...mapActions(useConfigurationStore, ["updateConfiguration"]),
    async updateEngagementConfiguration(key, value) {
      await this.updateConfiguration({ [key]: value });
    },
  },
};
</script>

<style scoped lang="scss"></style>
