<template>
  <component :is="component" v-if="readyToRender" :entity="entity" />
</template>

<script>
import { Enum } from "@bloglovin/vue-component-library";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { markRaw } from "vue";

export default {
  name: "SmartFeedItem",
  props: {
    entityType: {
      type: String,
      required: true,
    },
    entityId: {
      type: [Number, String],
      required: true,
    },
    layout: {
      type: Enum,
      required: true,
      options: [...SmartFeedEngine.VALID_ENTITY_LAYOUTS],
    },
    // this is currently non-functional after refactor - @TODO - fix
    showUnknownEntitiesAsPlaceholder: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      component: null,
      entityStore: null,
      readyToRender: false,
    };
  },
  computed: {
    entity() {
      if (this.entityStore == null) {
        return null;
      }
      return this.entityStore.getEntity(this.entityId);
    },
  },
  created() {
    this.readyToRender = false;
    const smartFeedEngine = new SmartFeedEngine();
    const layoutPromise = smartFeedEngine.getLayoutComponentForEntity(this.entityType, this.layout).then(layout => {
      this.component = markRaw(layout);
    });
    const entityStorePromise = smartFeedEngine.getEntityStore(this.entityType).then(entityStore => {
      this.entityStore = entityStore;
    });
    Promise.all([layoutPromise, entityStorePromise]).then(() => {
      this.readyToRender = true;
    });
  },
};

</script>

<style scoped lang="scss">

</style>
