<template>
  <iModal
    :visible="isCreateDigitalProductModalOpen"
    width="hug"
    title="New Digital Product"
    primary-action-button-label="Create Digital Product"
    :primary-action-button-loading="isCreatingDigitalProduct"
    :primary-action-button-disabled="formHasError"
    @close="closeModal"
    @click:primary="addDigitalProduct"
    @click:secondary="closeModal"
  >
    <template #body>
      <iColumn width="fill">
        <iTextInput
          v-model="name"
          :required="true"
          label="Name"
          name="name"
          placeholder="Digital product name"
          type="text"
          width="fill"
        />

        <iTextArea
          v-model="description"
          :required="true"
          label="Description"
          name="description"
          placeholder="Describe your digital product..."
          width="fill"
          @blur="validateFormString"
        />

        <iSelect
          v-model="selectedType"
          :items="productTypes"
          label="Product Type"
          value-field="value"
          text-field="name"
          :required="true"
          :return-value="true"
          empty-string="Choose a product type"
        />

        <iColumn v-if="selectedType" gap="small">
          <template v-if="selectedType === 'file'">
            <iColumn gap="extraSmall">
              <iLabel>Upload Digital Product</iLabel>
              <iUploader @upload-file="uploadDigitalProduct">
                <iRow vertical-align="middle">
                  <iColumn width="hug">
                    <iButton variant="secondary">
                      Add File
                    </iButton>
                  </iColumn>
                  <iText v-if="file" variant="subtle">
                    {{ file.name }}
                  </iText>
                </iRow>
              </iUploader>
            </iColumn>
          </template>

          <template v-if="selectedType === 'access'">
            <iColumn gap="small">
              <iText :font-weight="'dark'" :font-size="'small'">
                Access Type
              </iText>
              <iRow gap="extraLarge" width="hug">
                <iRadioButton
                  v-model="accessType"
                  value="exclusive_community"
                  label="Exclusive Community"
                  :style-overrides="{
                    labelFontSize: 'small',
                  }"
                />
                <iRadioButton
                  v-model="accessType"
                  value="exclusive_content"
                  label="Exclusive Content"
                  :style-overrides="{
                    labelFontSize: 'small',
                  }"
                />
              </iRow>
            </iColumn>
          </template>
        </iColumn>

        <iDatePicker
          v-model="startDateAvailable"
          label="Available Starting"
          :required="true"
        />

        <iRow vertical-align="middle" gap="small">
          <iCheckbox
            :model-value="hasEndDate"
            label="Make unavailable on specific date"
            @update:model-value="hasEndDate = $event"
          />
        </iRow>

        <iDatePicker
          v-if="hasEndDate"
          v-model="endDateAvailable"
          label="Available Until"
          :required="true"
        />
      </iColumn>
    </template>
  </iModal>
</template>

<script>
import { MAPPED_CURRENCY_CODES } from "@/constants/currency-constants";
import { usePublicationDigitalProductsStore } from "@/stores/publication-digital-products-store";
import { validateStringInput } from "@bloglovin/vue-component-library";
import { mapActions, mapState } from "pinia";

export default {
  name: "CreateDigitalProductModal",
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["close"],
  data() {
    return {
      formError: "",
      name: "",
      description: "",
      selectedType: "",
      currencyCode: MAPPED_CURRENCY_CODES[0],
      startDateAvailable: Date.now(),
      hasEndDate: false,
      endDateAvailable: Date.now(),
      file: null,
      accessType: "exclusive_community",
      productTypes: [
        { name: "File", value: "file" },
        { name: "Event", value: "event" },
        { name: "Meeting", value: "meeting" },
      ],
    };
  },
  computed: {
    ...mapState(usePublicationDigitalProductsStore, [
      "isCreatingDigitalProduct",
      "isCreateDigitalProductModalOpen",
    ]),
    formHasError() {
      return !!this.formError;
    },
  },
  watch: {
    price(price) {
      this.validatePrice(price);
    },
  },
  created() {
    this.startDateAvailable = Date.now();
  },
  methods: {
    ...mapActions(usePublicationDigitalProductsStore, ["createDigitalProduct"]),
    closeModal(event) {
      this.$emit("close", event);
    },
    uploadDigitalProduct(e) {
      const file = e;

      const maxSize = 100 * 1024 * 1024;
      if (file.size > maxSize) {
        this.formError = "File size exceeds maximum allowed (100MB)";
        return;
      }

      const allowedTypes = [
        "application/pdf",
        "image/jpeg",
        "image/png",
        "image/gif",
        "video/mp4",
        "audio/mpeg",
        "application/zip",
        "application/x-zip-compressed",
      ];

      if (!allowedTypes.includes(file.type)) {
        this.formError = "File type not supported";
        return;
      }

      this.file = file;
      this.formError = "";
    },
    validateFormString(e) {
      this.formError = validateStringInput(e.target.value);
    },
    validatePrice(price) {
      const regex = /^[0-9]*\.?[0-9]*$/;
      if (!regex.test(price)) {
        this.formError = "Price must be a number";
      } else {
        this.formError = validateStringInput(price);
      }
    },
    addDigitalProduct() {
      if (this.formHasError) {
        return;
      }

      if (this.selectedType === "file" && !this.file) {
        this.formError = "Please select a file to upload";
        return;
      }

      const productData = {
        name: this.name,
        description: this.description,
        type: this.selectedType,
        startAvailableForPurchaseTimestamp: this.startDateAvailable,
        endAvailableForPurchaseTimestamp: this.hasEndDate
          ? this.endDateAvailable
          : 0,
        accessType: this.selectedType === "access" ? this.accessType : null,
        file: this.selectedType === "file" && this.file ? this.file : null,
      };
      this.createDigitalProduct(productData);
    },
  },
};
</script>
