<template>
  <SplashWrapper hide-buttons>
    <iRow align="center">
      <iColumn :width="1200">
        <iHeading>INSPIRATION.CO PRIVACY POLICY</iHeading>
        <iSpace />

        <iSubHeading>1. Introduction</iSubHeading>
        <iText>
          Inspiration Starts Here (“we, “us”) is committed to protecting and respecting
          your privacy. This Privacy Policy and any other
          documents referred to in it) applies to inspiration.co and inspiration.co
          mobile application(s) (the “Service”) and sets out the basis on which
          any personal data we collect from you, or that you provide to us, will
          be handled by us. In this Privacy Policy, the term "personal data" means
          information that relates to an identified or identifiable natural person.
        </iText>
        <iText>
          We know that you, the user of the Service, are concerned about how we
          use and disclose personal data. We are dedicated to openness regarding
          the ways in which we use and disclose personal data, and we are committed
          to complying with data protection and privacy laws that apply to us.
          Importantly, we want to give you control over personal data that we
          process about you when you use the Service.
        </iText>
        <iText>
          We wish to remind you that this Privacy Policy applies to personal data
          that we process when you use the Service. It does not apply to any links
          to third parties' websites and/or services that you may encounter when
          you use the Service (including, without limitation, any blogs which you
          may access when you the Service and any user feedback services that the
          Service may provide links to). We encourage you to carefully familiarize
          yourself with privacy policies applicable to any websites and/or services
          operated by third parties. Please be aware that we are not responsible for
          the privacy practices of any third parties, such as the UserVoice-provided
          “Contact Support” functionality accessible through the Service.
        </iText>
        <iText>
          Please be aware that other users of the Service may be able to see certain
          personal data you have submitted to the Service. Therefore, please be careful
          when sharing personal data about you generally in the Service and with other
          users of the Service. If you register with the Service, you will create a
          publicly visible user profile under your username. The profile allows other
          users of the Service to see for instance your name, location, your
          picture/avatar and profile text (if such data is provided to us by you),
          blogs and users you follow in the Service and users that are following you,
          and the posts and other items that you “like” in the Service. Such data may
          be shown on your profile page, in connection with the posts you like in the
          Service, on the profile page for the blogs you follow and in social features
          of the Service. If you wish, you may flag blogs you are following as "private"
          on the “Manage the blogs you follow” page in the Service, in which case your
          profile information is removed from the blog profile and the blog is removed
          from your user profile. However, other items specified above will still be
          visible for other users of the Service.
        </iText>
        <iText>
          The social features of the Service also allow you to follow other users
          and other users to follow you. Your profile will be visible on the
          profile page of the users who follow you, and the users following you
          will be able to see when you start following other users. You can also
          connect your user profile with a blog, if you prove that you own that
          blog. In this case the blogs you own and blog profile texts for such
          blogs, if any, will be visible to other users in connection with your
          profile and in blog listings. You can manage the settings relating to
          your own blog on the “My blog” page in the Service.
        </iText>
        <iText>
          BY USING THE SERVICE, YOU ACCEPT THE PRIVACY PRACTICES DESCRIBED IN THIS
          PRIVACY POLICY.
        </iText>

        <iSpace />

        <iSubHeading>2. The Data We Collect</iSubHeading>

        <iText>
          In this Section 2 we describe the personal data we collect.
        </iText>

        <iText>
          <strong>
            (i) Personal data you provide to the Service
          </strong>
        </iText>
        <iText>
          We may collect the following information from our users that may, in
          certain circumstances, alone or in connection with other data,
          constitute personal data:
        </iText>

        <ul>
          <li>Full name</li>
          <li>Username</li>
          <li>E-mail address</li>
          <li>Password</li>
          <li>Location</li>
          <li>Blogs that you follow in the Service</li>
          <li>User picture or avatar (provided that you give this information)</li>
          <li>User profile text (provided that you give this information)</li>
          <li>
            Blogs owned by you and the related blog profile text (provided that
            you give this information)
          </li>
          <li>
            Information on your interactions with other users of the Service,
            such as information on which users you follow in the Service
          </li>
          <li>
            Information on your use of the Service, such as information
            regarding the posts that you “like” in the Service
          </li>
        </ul>

        <iText>
          If you use a third party service, such as Facebook Connect, for signing
          into the Service, we may collect personal data, such as your user ID,
          name, e-mail address, profile picture/avatar that are available in your
          public profile with the third party service you use for signing into
          the Service. We may also share the information that you “liked” an item
          or started following a blog on your Facebook timeline; if you do not
          wish us to share this information, you may inactivate it on your
          Settings page in the Service. The Service also includes a “Find friends
          from Facebook” feature which enables you to find other users of the
          Service that you have as Facebook friends.
        </iText>

        <iText>
          <strong>
            (ii) Data Collected Automatically
          </strong>
        </iText>

        <iText>
          When you use the Service, we automatically collect data that may in
          certain circumstances constitute personal data:
        </iText>


        <ul>
          <li>
            We may place a "cookie" on the hard drive of the device that you
            use to access the Service. Cookies are text files that are saved on
            the hard drive of your device by means of your browser, enabling us
            to recognize your browser for purposes such as automatically
            authenticating you and logging you in to the Service, saving your
            preferences and directing relevant content to you. Most of the
            currently available browsers give you the option of managing
            cookies by, for example, disabling them entirely, accepting them
            individually, and deleting saved cookies from your hard drive. We
            would like to remind you that if you completely disable cookies on
            your browser, you might not be able to use some features of the
            Service.
          </li>
          <li>
            We may also place tracking pixels in the Service. A tracking pixel
            is a transparent graphic image that is placed on a web page and, in
            combination with a cookie, allows for the collection of information
            regarding the use of the web page that contains the tracking pixel.
          </li>
          <li>
            We use API keys of third-party software to authenticate the user to us.
          </li>
          <li>
            Google Analytics is an element of the Service. By using cookies,
            Google Analytics collects and stores data such as time of visit,
            pages visited, time spent on each page of the website, the Internet
            Protocol address, and the type of operating system used in the
            devices used to access the Service. By using a browser plugin
            http://www.google.com/ads/preferences/plugin/ provided by Google,
            you can opt out of Google Analytics.
          </li>
          <li>
            We also use the analytics software service providers to provide us
            with statistical information related to your use of the Service,
            including, but not limited to, Mixpanel, Quantcast and comScore.
          </li>
        </ul>


        <iSpace />

        <iSubHeading>3. The Purposes for Which We Use the Data</iSubHeading>
        <iText>
          We use the data we collect for the following purposes:
        </iText>
        <iText>
          <strong>
            a) Personal data you provide to the Service:
          </strong>
          <iText>
            We may use the personal data you provide to the Service for the
            following purposes:
          </iText>
        </iText>

        <ul>
          <li>To set up and maintain your registration with the Service;</li>
          <li>
            To communicate with you, including sending you an optional digest of
            new blog posts. You can manage your preferences regarding the digests
            on the “Settings” page of the Service;
          </li>
          <li>To provide features available in the Service;</li>
          <li>To develop, improve, and protect the Service;</li>
          <li>
            For direct marketing and market research, including for example
            reading recommendations and targeted marketing, in accordance with
            applicable law;
          </li>
          <li>To prevent and investigate fraud and other misuses; and</li>
          <li>To protect our rights and/or our property.</li>
        </ul>
        <iText>
          <strong>
            b) Data collected automatically:
          </strong>
          <span>
            We may use the data collected automatically for the following purposes:
          </span>
        </iText>

        <ul>
          <li>To manage the Service;</li>
          <li>To provide features available in the Service;</li>
          <li>
            To personalize the Service. In particular, cookies may be used for
            providing us information on the blogs that you looked at before
            registering to the Service. We use this information to provide you
            with blog recommendations;
          </li>
          <li>To develop, improve, and protect the Service;</li>
          <li>
            For market research and data analysis, including for example
            reading recommendations and behavioral targeting and targeted
            marketing, in accordance with applicable law;
          </li>
          <li>To audit and analyze the Service; and</li>
          <li>To ensure the technical functionality and security of the Service.</li>
        </ul>


        <iSpace />

        <iSubHeading>4. How We Disclose Data</iSubHeading>

        <iText>
          The data collected in the Service may be disclosed in the following manner:
        </iText>
        <iText>
          <strong>
            a) Personal data you provide to the Service:
          </strong>
          <span>
            We may disclose personal data you provide to the Service with the
            following categories of third parties:
          </span>
        </iText>


        <ul>
          <li>To other users of the Service in accordance with this Privacy Policy;</li>
          <li>To our advertising partners in compliance with applicable laws;</li>
          <li>
            To service providers, such as email communications providers,
            customer support tools and feedback service providers;
          </li>
          <li>
            To public authorities, such as law enforcement, if we are legally
            required to so or if we need to protect our rights or the rights of
            third parties; and
          </li>
          <li>
            To our subsidiaries and affiliates; or a subsequent owner, co-owner
            or operator of the Service and their advisors in connection with a
            corporate merger, consolidation, restructuring, or the sale of
            substantially all of our stock and/or assets or other corporate
            reorganization, in accordance with this Privacy Policy.
          </li>
        </ul>
        <iText>
          <strong>
            b) Data collected automatically:
          </strong>
          <span>
            The data collected automatically in the Service may be disclosed to the
            following categories of third parties:
          </span>
        </iText>
        <ul>
          <li>To service providers, such as data analysis companies;</li>
          <li>To our advertising partners in compliance with applicable laws;</li>
          <li>
            To public authorities, such as law enforcement, if we are legally
            required to do so or if we need to protect our rights or the rights
            of third parties; and
          </li>
          <li>
            To our subsidiaries and affiliates; or a subsequent owner,
            co-owner or operator of the Service and their advisors in
            connection with a corporate merger, consolidation, restructuring,
            or the sale of substantially all of our stock and/or assets or
            other corporate reorganization, in accordance with this Privacy Policy.
          </li>
        </ul>
        <iText>
          Moreover, we may disclose information to third parties in an aggregate
          format that does not constitute personal data and does not allow the
          identification of individual users.
        </iText>

        <iSpace />

        <iSubHeading>5. Your Rights</iSubHeading>

        <iText>
          You have the following rights with respect to the personal data we hold about you:
        </iText>
        <ul>
          <li>
            <strong>The right to know what data we hold about you:</strong> If you have
            registered with the Service, the easiest way to review the
            personal data you have provided to the Service is to access your
            profile and account settings on the “Settings” page of the Service.
            On your settings page you can also make changes to the personal
            data we hold about you. If you have additional questions regarding
            the personal data we hold about you, please contact us at
            <a href="mailto:support@inspiration.co">support@inspiration.co</a>.
            We seek to swiftly respond to your inquiry. We may charge a small
            processing fee if less than twelve (12) months
            has passed since your last inquiry relating to personal data
            we hold about you.
          </li>
          <li>
            <strong>The right to have incomplete, incorrect, outdated, or unnecessary
              personal data corrected, deleted, or updated.</strong> The easiest way to
            correct, delete, or update the personal data you have provided to
            the Service is to access your profile settings and enter the
            necessary changes there. If you have additional questions regarding
            the correction, deletion, or updating of the personal data we hold
            about you, please contact us at
            <a href="mailto:support@inspiration.co">support@inspiration.co</a>.
          </li>
          <li>
            <strong>The right to opt out of receiving electronic direct marketing
              communications from us:</strong> All electronic direct marketing
            communications that you may receive from us, such as e-mail
            messages, give you an option of not receiving such communications
            from us in the future. If you have any additional questions about
            electronic direct marketing received from us, please contact us at
            <a href="mailto:support@inspiration.co">support@inspiration.co</a>.
          </li>
        </ul>

        <iSpace />

        <iSubHeading>6. Data Security</iSubHeading>

        <iText>
          The security of personal data is important to us. For example, your
          user account is protected by a password, the servers we use are secured
          and protected by a firewall, and we restrict access to personal data to
          employees, contractors and agents who need to know such personal data
          in order to operate, develop or improve the Service.
        </iText>
        <iText>
          Even so, we cannot guarantee that your personal data are always secure
          because data security measures in use from time to time may be
          vulnerable. Consequently, please be mindful of the risks that personal
          data about you may be exposed to when you use the Service.
        </iText>

        <iSpace />

        <iSubHeading>7. International Transfers of Personal Data</iSubHeading>

        <iText>
          If you are located in the European Union, please note that some of the
          servers where the Service is hosted may be located in countries outside
          the European Union. The laws applicable to the protection of personal
          data in such countries may be different from your home country. By
          registering with the Service, you consent to personal data about you
          being transferred outside the European Union.
        </iText>

        <iSpace />

        <iSubHeading>8. Children’s Privacy</iSubHeading>

        <iText>
          The Service is not intended for users younger than thirteen (13) years
          of age. We do not intend to collect any personal data from children
          under thirteen (13) years of age.
        </iText>


        <iSpace />

        <iSubHeading>9. Changes to the Privacy Policy</iSubHeading>

        <iText>
          From time to time we may change this Privacy Policy. You can tell when
          changes have been made to the Privacy Policy by referring to the "Last
          Updated" legend on top of this page. If we materially change the ways
          in which we use and disclose personal data, we will post a notice in
          the Service and send an e-mail to our registered users. Your continued
          use of the Service following any changes to this Privacy Policy
          constitutes your acceptance of any such changes made.
        </iText>

        <iSpace />

        <iSubHeading>10. Questions or Concerns?</iSubHeading>

        <iText>
          Should you have any questions regarding this Privacy Policy, your
          privacy as it relates to the use of the Service, or the protection of
          the personal data we hold about you, please contact us at
          <a href="mailto:support@inspiration.co">support@inspiration.co</a>. We
          seek to swiftly resolve any concerns you may have.
        </iText>
      </iColumn>
    </iRow>
    <iSpace :height="20" />
  </SplashWrapper>
</template>

<script>
import SplashWrapper from "@/components/splash/SplashWrapper.vue";

export default {
  name: "PrivacyPolicy",
  components: { SplashWrapper },
};
</script>

<style scoped lang="scss">
.nav-container {
  position: sticky;
  background-color: white;
  top: 0;
  z-index: 1;
}
.nav-bar {
  width: 100%;
  padding: 0 40px;
}
</style>
