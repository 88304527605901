import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";

export const useCommunityPublicationFeedStore = defineSmartFeedPiniaStore("community-publication-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    feed: [],
    metadata: {
      hasMore: true,
      lastId: 0,
    },
    isLoading: false,
  }),
  getters: {
    hasMore() {
      return this.metadata.hasMore;
    },
  },
  actions: {
    getSmartFeed() {
      this.apiGet(
        "/publication/community/smart-feed",
        {
          last_id: this.metadata.lastId,
        },
        data => this.handleSmartFeedResponse(data),
        () => this.metadata.hasMore = false,
      );
    },
  },
});
