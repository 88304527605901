import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";

export const useCommunityPublicationCollaborationAdFeedStore =
  defineSmartFeedPiniaStore(
    "community-publication-collaboration-ad-feed-store",
    {
      state: () => ({
        smartFeedEngine: new SmartFeedEngine(),
        feed: [],
        metadata: {
          hasMore: true,
          lastId: 0,
        },
        isLoading: false,
      }),
      getters: {
        hasMore() {
          return this.metadata.hasMore;
        },
      },
      actions: {
        reload() {
          this.$reset();
          this.getSmartFeed();
        },
        getSmartFeed() {
          this.apiGet(
            "/publication-ads/community/smart-feed",
            {
              last_id: this.metadata.lastId,
              status: "active",
            },
            data => this.handleSmartFeedResponse(data),
            () => {
              this.metadata.hasMore = false;
            },
          );
        },
      },
    },
  );
