<template>
  <table
    :class="{
      'sticky': stickyHeaders,
    }"
    class="smart-feed-table"
  >
    <tr>
      <th v-for="field in fields" :key="field.key" :style="{width: field.width}">
        {{ field.label }}
      </th>
    </tr>
    <slot />
  </table>
</template>

<script>
import { Enum } from "@bloglovin/vue-component-library";

export default {
  name: "SmartFeedTable",
  props: {
    fields: {
      type: Array,
      required: true,
    },
    stickyHeaders: {
      type: Boolean,
      required: false,
      default: false,
    },
    verticalAlign: {
      type: Enum,
      required: false,
      options: ["top", "middle"],
      default: "top",
    },
  },
  styleGuide: () => ({
    tableFontFamily: { "font.typeface": "standard" },
    tableBorderWidth: { "size.border": "thin" },
    tableBorderColor: { "color.border": "dark" },
    tableBorderRadius: { "size.borderRadius": "large" },

    paddingTop: { "size.spacing": "none" },
    paddingBottom: { "size.spacing": "none" },
    paddingLeft: { "size.spacing": "none" },
    paddingRight: { "size.spacing": "none" },

    marginTop: { "size.spacing": "none" },
    marginBottom: { "size.spacing": "none" },
    marginLeft: { "size.spacing": "none" },
    marginRight: { "size.spacing": "none" },

    tableHeaderPaddingTop: { "size.spacing": "small" },
    tableHeaderPaddingBottom: { "size.spacing": "none" },
    tableHeaderPaddingLeft: { "size.spacing": "none" },
    tableHeaderPaddingRight: { "size.spacing": "large" },

    headerBackgroundColor: { "color.background": "standard" },
    headerFontSize: { "color.border": "standard" },
    headerFontWeight: { "font.weight": "standard" },
    headerBorderWidth: { "size.border": "none" },
    headerBorderColor: { "color.border": "none" },
    headerFontColor: { "color.font": "standard" },
    headerPaddingTop: { "size.spacing": "standard" },
    headerPaddingBottom: { "size.spacing": "standard" },
    headerPaddingLeft: { "size.spacing": "standard" },
    headerPaddingRight: { "size.spacing": "standard" },

    cellFontSize: { "size.font": "small" },
    cellFontWeight: { "font.weight": "light" },
    cellPaddingTop: { "size.spacing": "large" },
    cellPaddingBottom: { "size.spacing": "large" },
    cellPaddingLeft: { "size.spacing": "standard" },
    cellPaddingRight: { "size.spacing": "standard" },
  }),
};

</script>
<!--eslint-disable-next-line -->
<style lang="scss">

.smart-feed-table {
  width: 100%;

  color: v-bind('$getStyles.tableFontColor');
  font-family: v-bind('$getStyles.tableFontFamily');

  border-style: solid;
  border-color: v-bind('$getStyles.tableBorderColor');
  border-width: v-bind('$getStyles.tableBorderWidth');
  border-radius: v-bind('$getStyles.tableBorderRadius');

  border-spacing: 0;
  border-collapse: separate;
  overflow: auto;

  margin-top: v-bind('$getStyles.marginTop');
  margin-bottom: v-bind('$getStyles.marginBottom');
  margin-left: v-bind('$getStyles.marginLeft');
  margin-right: v-bind('$getStyles.marginRight');

  padding-top: v-bind('$getStyles.paddingTop');
  padding-bottom: v-bind('$getStyles.paddingBottom');
  padding-left: v-bind('$getStyles.paddingLeft');
  padding-right: v-bind('$getStyles.paddingRight');
}

th, td {
  text-align: left;
  font-size: v-bind('$getStyles.tableRowFontSize');
}

th {
  height: 35px;

  background-color: v-bind('$getStyles.headerBackgroundColor');
  font-size: v-bind('$getStyles.headerFontSize');
  font-weight: v-bind('$getStyles.headerFontWeight');
  color: v-bind('$getStyles.headerFontColor');

  border-color: v-bind('$getStyles.headerBorderColor');
  border-width: v-bind('$getStyles.headerBorderWidth');
  border-style: solid;

  padding-top: v-bind('$getStyles.headerPaddingTop');
  padding-bottom: v-bind('$getStyles.headerPaddingBottom');
  padding-left: v-bind('$getStyles.headerPaddingLeft');
  padding-right: v-bind('$getStyles.headerPaddingRight');
}

td {
  font-weight: v-bind('$getStyles.cellFontWeight');

  padding-top: v-bind('$getStyles.cellPaddingTop');
  padding-bottom: v-bind('$getStyles.cellPaddingBottom');
  padding-left: v-bind('$getStyles.cellPaddingLeft');
  padding-right: v-bind('$getStyles.cellPaddingRight');

  overflow-wrap: break-word;
  vertical-align: v-bind(verticalAlign);
}

.smart-feed-table.sticky tr:first-child {
  position: sticky;
  top: 0;
  z-index: 2;
}

.smart-feed-table.sticky tr:not(:first-child) {
  z-index: 1;
  position: relative;
}
</style>
